import { BACKEND } from '../variables.js';
import axios from 'axios'
const GET_LOCATIONS = BACKEND + '/location/getLocations';
const CREATE_LOCATION = BACKEND + '/location/createLocation';
const UPDATE_LOCATION = BACKEND + '/location/updateLocation';
const DELETE_LOCATION = BACKEND + '/location/deleteLocation';

export default {
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        ['GET_LOCATIONS'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_LOCATIONS + `?page=${payload.data.page}`,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['CREATE_LOCATION'](state, payload) {
            return axios({
                method: 'POST',
                url: CREATE_LOCATION,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_LOCATION'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_LOCATION,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['DELETE_LOCATION'](state, payload) {
            return axios({
                method: 'POST',
                url: DELETE_LOCATION,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        }

    },
};
