<template>
  <router-link
    :to="{
      name: 'ViewCar',
      params: { id: item.space_id, name: $url(item.name) },
    }"
    class="listing-item"
    v-for="item in filteredItems"
    :key="item.id"
  >
    <div class="image">
      <img :src="$upload(item.image)" :alt="item.name" />
      <p class="price">
        <small>From </small>{{ $comma(item.price) }} {{ item.currency }}
      </p>
    </div>
    <div class="details">
      <h3>{{ item.name }}</h3>
      <p>
        <i class="fa-solid fa-location-dot"></i>
        <span>{{ item.location.name }}</span>
      </p>
      <p>
        <i class="fa-solid fa-layer-group"></i>
        <span>{{ item.car_type }}</span>
      </p>
      <p>
        <i class="fa-solid fa-list"></i>
        <span>{{ item.car_brand }}</span>
      </p>
    </div>
    <div class="like">
      <i class="fa fa-heart"></i>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    filteredItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rating: 4,
    };
  },
};
</script>

<style>
</style>