<template>
  <div id="Footer">
    <div class="top">
      <div class="left">
        <img src="/img/logow.png" alt="logo" />
        <div class="footer-list">
          <ul>
            <li>{{ $site().site_phone }}</li>
            <li>{{ $site().site_email }}</li>
            <li>{{ $site().site_address }}</li>
          </ul>
        </div>
      </div>
      <div class="middle">
        <h3 class="title">Quick Links</h3>
        <div class="footer-list">
          <ul>
            <li><router-link :to="{ name: 'Home' }">Home</router-link></li>
            <li>
              <router-link :to="{ name: 'Destinations' }"
                >Destinations</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Stays' }">Stays</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'About' }">About Cumbinn</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="middle-2">
        <h3 class="title">About Us</h3>
        <div class="footer-list">
          <ul>
            <li>
              <router-link :to="{ name: 'Contact' }">Contact Us</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Login' }">Login</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Register' }">Register</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="right">
        <h3 class="title">Newsletter</h3>
        <div class="footer-list">
          <ul>
            <li>
              Stay up to date with the latest news and offers anytime from
              Cumbinn1
            </li>
            <li>
              <form action="">
                <input type="text" placeholder="Enter your email" />
                <button>Subscribe</button>
              </form>
            </li>
            <li>
              <div class="socials">
                <a :href="$site().site_facebook" v-if="$site().site_facebook">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a :href="$site().site_twitter" v-if="$site().site_twitter">
                  <i class="fa-brands fa-x-twitter"></i>
                </a>
                <a :href="$site().site_instagram" v-if="$site().site_instagram">
                  <i class="fab fa-instagram"></i>
                </a>
                <a :href="$site().site_linkedin" v-if="$site().site_linkedin">
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="left">
        <p>&copy; 2024 Cumbinn. All rights reserved</p>
      </div>
      <div class="right">
        <ul>
          <li>
            <router-link :to="{ name: 'Home' }">Terms & Conditions</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Home' }">Privacy Policy</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$primary_color: #0c3b7c;
$secondary_color: #00bafc;
#Footer {
  width: 100%;
  background: rgb(13, 15, 50);
  background: rgb(8, 48, 121);
  background: linear-gradient(
    142deg,
    rgba(8, 48, 121, 1) 0%,
    rgba(15, 39, 75, 1) 100%
  );
  color: #fff;
  padding: 20px 0;
  .top {
    display: flex;
    justify-content: space-between;
    padding: 0 10%;
    margin-bottom: 3rem;
    @media (max-width: 1000px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
    @media (max-width: 768px) {
      display: block;
    }
    ul {
      list-style: none;
      li {
        margin: 10px 0;
        color: #ffffff88;
      }
    }
    .left {
      img {
        width: 100px;
      }
      .footer-list {
      }
    }
    .middle,
    .middle-2,
    .right {
      .footer-list {
        a {
          color: #ffffff88;
          text-decoration: none;
          &:hover {
            color: #fff;
          }
        }
      }
    }
    .right {
      form {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;
        input {
          padding: 10px;
          border: none;
          border-radius: 5px 0px 0px 5px;
          border: 1px solid $secondary_color;
        }
        button {
          padding: 10px 20px;
          border: none;
          border-radius: 0px 5px 5px 0px;
          background-color: $secondary_color;
          color: #fff;
          cursor: pointer;
        }
      }
      .socials {
        display: flex;
        gap: 10px;
        i {
          margin: 0 5px;
          font-size: 20px;
          color: #eee;
          cursor: pointer;
          opacity: 0.8;
          &:hover {
            opacity: 1;
          }
          &.fa-x-twitter {
            &:hover {
              color: #1da1f2;
            }
          }
          &.fa-linkedin-in {
            &:hover {
              color: #0a66c2;
            }
          }
          &.fa-instagram {
            &:hover {
              color: #e1306c;
            }
          }
          &.fa-facebook-f {
            &:hover {
              color: #3b5998;
            }
          }
        }
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    padding: 0 10%;
    width: 100%;
    .left {
      p {
        font-size: 12px;
        color: #fff;
      }
    }
    .right {
      ul {
        list-style: none;
        li {
          display: inline;
          margin: 0 10px;
          a {
            color: #fff;
            text-decoration: none;
            font-size: 12px;
          }
        }
      }
    }
  }
}
h3 {
  color: #fff;
}
</style>