import { BACKEND } from '../variables.js';
import axios from 'axios'
const GET_ALL_ACCOMODATIONS = BACKEND + '/accomodation/getAllAccomodations';
const GET_FILTERED_ACCOMODATIONS = BACKEND + '/accomodation/getFilteredAccomodations';
const CREATE_ACCOMODATION = BACKEND + '/accomodation/createAccomodation';
const UPDATE_ACCOMODATION = BACKEND + '/accomodation/updateAccomodation';
const DELETE_ACCOMODATION = BACKEND + '/accomodation/deleteAccomodation';
const GET_ACCOMODATION = BACKEND + '/accomodation/getAccomodation';


export default {
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        ['GET_ALL_ACCOMODATIONS'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_ALL_ACCOMODATIONS + `?page=${payload.data.page}`,
                data: payload.data
            })
        },
        ['GET_FILTERED_ACCOMODATIONS'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_FILTERED_ACCOMODATIONS + `?page=${payload.data.page}`,
                data: payload.data
            })
        },
        ['CREATE_ACCOMODATION'](state, payload) {
            return axios({
                method: 'POST',
                url: CREATE_ACCOMODATION,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_ACCOMODATION'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_ACCOMODATION,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['DELETE_ACCOMODATION'](state, payload) {
            return axios({
                method: 'POST',
                url: DELETE_ACCOMODATION,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['GET_ACCOMODATION'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_ACCOMODATION,
                data: payload.data
            })
        },

    },
};
