<template>
  <div class="Properties">
    <NavBar />
    <div class="container" v-if="selectedItem">
      <div class="item-view">
        <div class="reserve-success" v-if="reserved">
          <div class="success">
            <div class="icon">
              <i class="fa fa-check"></i>
            </div>
            <h2>Tour Reserved Successfully</h2>
            <p>
              Sit back and relax, our team will contact you to confirm your
              reservation.
            </p>
            <a href="#" class="my-btn cw bp" @click.prevent="reserved = false"
              >Close</a
            >
          </div>
        </div>
        <div class="item-header">
          <div class="left">
            <h2>{{ selectedItem.name }}</h2>
          </div>
          <div class="right">
            <p>
              <i class="fa-solid fa-location-dot"></i>
              <span>&nbsp;{{ selectedItem.location.name }}</span>
            </p>
            <!-- <a href="#" class="my-btn cw bp" @click.prevent="startBook = true"
              >Reserve Now</a
            > -->
          </div>
        </div>
        <div class="images">
          <div class="main-image image-left">
            <img :src="$upload(selectedItem.image)" alt="main-image" />
            <!-- <div class="bottom-left">
              <button class="my-btn wd cw bp">View all photos</button>
            </div> -->
          </div>
          <div class="image-right">
            <carousel
              :items-to-show="2"
              :items-to-scroll="1"
              :wrapAround="false"
              snapAlign="left"
              :breakpoints="{
                3000: {
                  itemsToShow: 15,
                  itemsToScroll: 1,
                },
                2000: {
                  itemsToShow: 12,
                  itemsToScroll: 1,
                },
                1700: {
                  itemsToShow: 10,
                  itemsToScroll: 1,
                },
                1500: {
                  itemsToShow: 8,
                  itemsToScroll: 1,
                },
                1000: {
                  itemsToShow: 6,
                  itemsToScroll: 1,
                },
                768: {
                  itemsToShow: 4,
                  itemsToScroll: 1,
                },
                500: {
                  itemsToShow: 2,
                  itemsToScroll: 1,
                },
              }"
              class="sub-images"
            >
              <slide v-for="image in selectedItem.images" :key="image">
                <img
                  :src="$upload(image)"
                  alt="sub-image"
                  @click="selectedItem.image = image"
                />
              </slide>

              <template #addons>
                <navigation />
                <pagination />
              </template>
            </carousel>
          </div>
        </div>
        <div class="item-details">
          <div class="left-details">
            <div class="item-grids">
              <div class="item-grid-item">
                <div class="item-grid-item-title">Price</div>
                <div class="item-grid-item-value">
                  {{ $comma(selectedItem.price) }} {{ selectedItem.currency }}
                </div>
              </div>
              <div class="item-grid-item">
                <div class="item-grid-item-title">Attraction</div>
                <div class="item-grid-item-value">
                  {{ selectedItem.attraction }}
                </div>
              </div>
              <div class="item-grid-item">
                <div class="item-grid-item-title">Max people</div>
                <div class="item-grid-item-value">
                  <span> {{ selectedItem.maxPeople }}</span>
                </div>
              </div>
              <div class="item-grid-item">
                <div class="item-grid-item-title">Support</div>
                <div class="item-grid-item-value">
                  <span> {{ selectedItem.support_email }}</span>
                </div>
              </div>
            </div>
            <div class="Amenities">
              <h3>About {{ selectedItem.name }}</h3>
              <div class="amenities-lists">
                <div>
                  <div class="title">Features</div>
                  <ul>
                    <span
                      v-for="feature in selectedItem.inclusives"
                      :key="feature"
                      ><i class="fa fa-check"></i>{{ feature }}</span
                    >
                  </ul>
                </div>
              </div>
            </div>
            <div class="description">
              <h3>Description</h3>
              <div>
                <p v-html="selectedItem.description"></p>
              </div>
            </div>
            <div class="map">
              <h3>Map</h3>
              <div>
                <iframe
                  :src="
                    'https://www.google.com/maps?q=' +
                    selectedItem.address +
                    '&output=embed'
                  "
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
          <div class="right-details">
            <!-- book form and notices -->
            <h2>Reserve you space now</h2>
            <div class="form">
              <div class="form-group">
                <label for="name">Names</label>
                <a-input
                  type="text"
                  id="name"
                  class="form-control"
                  placeholder="Your names"
                  v-model:value="payload.names"
                />
              </div>
              <div class="form-group">
                <label for="email">Email</label>
                <a-input
                  type="email"
                  id="email"
                  class="form-control"
                  placeholder="Your email"
                  v-model:value="payload.email"
                />
              </div>
              <div class="form-group">
                <label for="phone">Phone</label>
                <a-input
                  type="text"
                  id="phone"
                  class="form-control"
                  placeholder="Your phone"
                  v-model:value="payload.phone"
                />
              </div>
              <div class="form-group">
                <label for="checkin">Start Date</label>
                <a-date-picker
                  v-model:value="payload.check_in"
                  :format="dateFormatList"
                  placeholder="Start date"
                  :bordered="true"
                  style="width: 100%"
                />
              </div>
              <div class="form-group">
                <label for="Number of people">Number of people</label>
                <a-input-number
                  v-model:value="payload.people"
                  :min="1"
                  :max="selectedItem.maxPeople"
                  :step="1"
                  style="width: 100%"
                  placeholder="Number of people"
                />
              </div>
              <div class="form-group">
                <label for="message">Message</label>
                <a-textarea
                  id="message"
                  class="form-control"
                  placeholder="Anything you want us to know"
                  v-model:value="payload.message"
                >
                </a-textarea>
              </div>
              <div class="notice">
                <p>
                  <i class="fa fa-info"></i>
                  <span>
                    Please note that the reservation price my vary depending on
                    the number of days you want to stay, the number of people
                    and the season. Our team will contact you to confirm the
                    best reservation cost.
                  </span>
                </p>
              </div>
              <div class="form-group">
                <button class="my-btn cw bp" @click.prevent="ReserveNow()">
                  Reserve Now
                </button>
              </div>
              <div class="btns">
                <a
                  :href="`tel:${selectedItem.support_number}`"
                  class="my-btn cw bp"
                >
                  <i class="fa fa-phone"></i>
                  Call Now</a
                >
                <a
                  :href="`mailto:${selectedItem.support_email}`"
                  class="my-btn cw bp"
                >
                  <i class="fa fa-envelope"></i>
                  Email Now</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filtered-area" v-if="selectedItem.related.length > 0">
        <div class="category-list">
          <div class="tab-header">
            <h2>Related Stays</h2>
          </div>
        </div>
        <div class="items-listing">
          <SpaceCard :filteredItems="selectedItem.related" />
        </div>
      </div>
      <a-modal
        v-model:visible="startReserve"
        title="Reserve Now"
        class="reserve-modal"
        ><template #footer>
          <a-button key="back" @click="startReserve = false">Cancel</a-button>
          <a-button key="submit" type="primary" @click="ReserveNow"
            >Reserve Now</a-button
          >
        </template>
        <div class="form">
          <div class="form-group">
            <label for="name">Names</label>
            <a-input
              type="text"
              id="name"
              class="form-control"
              placeholder="Your names"
              v-model:value="payload.names"
            />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <a-input
              type="email"
              id="email"
              class="form-control"
              placeholder="Your email"
              v-model:value="payload.email"
            />
          </div>
          <div class="form-group">
            <label for="phone">Phone</label>
            <a-input
              type="text"
              id="phone"
              class="form-control"
              placeholder="Your phone"
              v-model:value="payload.phone"
            />
          </div>
          <div class="form-group">
            <label for="checkin">Start date</label>
            <a-date-picker
              v-model:value="payload.check_in"
              :format="dateFormatList"
              placeholder="Start date"
              :bordered="true"
              style="width: 100%"
            />
          </div>
          <div class="form-group">
            <label for="Number of people">Number of people</label>
            <a-input-number
              v-model:value="payload.people"
              :min="1"
              :max="selectedItem.maxPeople"
              :step="1"
              style="width: 100%"
              placeholder="Number of people"
            />
          </div>
          <div class="form-group">
            <label for="message">Message</label>
            <a-textarea
              id="message"
              class="form-control"
              placeholder="Anything you want us to know"
              v-model:value="payload.message"
            >
            </a-textarea>
          </div>
          <a-alert
            message="Note"
            description="Please note that the reservation price my vary depending on the
                number of days you want to stay, the number of people and the
                season. Our team will contact you to confirm the best
                reservation cost."
            type="info"
            show-icon
            class="mb-10"
          />
          <div class="btns">
            <a-button
              :href="`tel:${selectedItem.support_number}`"
              class="my-btn cw bp"
            >
              <i class="fa fa-phone"></i>
              &nbsp;Call Now</a-button
            >
            <a-button
              :href="`mailto:${selectedItem.support_email}`"
              class="my-btn cw bp"
            >
              <i class="fa fa-envelope"></i>
              &nbsp;Email Now</a-button
            >
          </div>
        </div>
      </a-modal>
      <div class="footer-button">
        <div class="down">
          <p>
            <span class="name">
              {{ selectedItem.name }}
            </span>
            <span class="price">
              <small>From</small> {{ $comma(selectedItem.price) }}
              <small>{{ selectedItem.payment_frequency }}</small>
            </span>
          </p>
          <button
            type="primary"
            @click="startReserve = true"
            class="my-btn cw bp"
          >
            Reserve Now
          </button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { toast } from "vue3-toastify";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      allItems: [],
      filteredItems: [],
      selectedId: null,
      selectedItem: null,
      payload: {
        check_in: "",
        check_out: "",
        people: 1,
        names: "",
        email: "",
        phone: "",
        message: "",
        guest: 1,
        user_id: null,
      },
      reserved: false,
      startReserve: false,
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SPACE_BY_ID", {
          data: {
            space_id: this.$route.params.id,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.selectedItem = res.data.space;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    ReserveNow() {
      if (!this.payload.names || !this.payload.email || !this.payload.phone) {
        toast.error("Please fill in all the required fields.");
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("RESERVE_SPACE", {
          data: {
            space_id: this.$route.params.id,
            ...this.payload,
            type: "Tours",
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              window.scrollTo(0, 0);
              this.startReserve = false;
              this.reserved = true;
              this.payload = {
                check_in: "",
                check_out: "",
                people: "",
                names: "",
                email: "",
                phone: "",
                message: "",
              };
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();

    this.selectedId = this.$route.params.id;
    if (this.$getSiteUser()) {
      this.payload.user_id = this.$getSiteUser().id;
      this.payload.names = this.$getSiteUser().name;
      this.payload.email = this.$getSiteUser().email;
      this.payload.phone = this.$getSiteUser().phone;
      this.payload.guest = false;
    }
  },
  watch: {
    $route(to, from) {
      this.selectedId = to.params.id;
      this.getItems();
      // scroll slowly to top
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.Properties {
  width: 100vw;
  overflow-x: hidden;
  .filtered-area {
    margin-bottom: 3rem;
  }
  .item-view {
    margin-top: 150px;
    @media screen and (max-width: 1700px) {
      margin-top: 90px;
    }
    @media screen and (max-width: 1300px) {
      margin-top: 100px;
    }
    @media screen and (max-width: 900px) {
    }
    @media screen and (max-width: 600px) {
    }
    .reserve-success {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 9999;
      .success {
        width: 50%;
        background: #fff;
        border-radius: 10px;
        padding: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 1700px) {
          width: 60%;
        }
        @media screen and (max-width: 1300px) {
          width: 70%;
        }
        @media screen and (max-width: 900px) {
          width: 80%;
        }
        @media screen and (max-width: 600px) {
          width: 90%;
        }
        .icon {
          font-size: 3rem;
          color: white;
          background: $primary_color;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        h2 {
          font-size: 2.5rem;
          color: $primary_color;
          margin-top: 1rem;
        }
        p {
          font-size: 1.25rem;
          color: #3d3d3d;
          margin-top: 1rem;
        }
        a {
          padding: 15px 40px;
          background: $primary_color;
          color: #fff;
          border: none;
          border-radius: 5px;
          text-decoration: none;
          font-size: 1.2rem;
          margin-top: 2rem;
          display: block;
          &:hover {
            background: darken($primary_color, 10%);
          }
        }
      }
    }
    .item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 1200px) {
        flex-flow: column;
        align-items: flex-start;
        margin-bottom: 0rem;
      }
      .left {
        h2 {
          font-size: 2rem;
          color: $primary_color;
          @media screen and (max-width: 1700px) {
            font-size: 1.5rem;
            line-height: 1.5;
          }
        }
        p {
          margin-bottom: 01rem;
          font-size: 1.35rem;
          color: #5a5a5a;
          font-weight: 400;
          @media screen and (max-width: 1700px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 1300px) {
            font-size: 0.9rem;
          }
          @media screen and (max-width: 900px) {
            font-size: 0.8rem;
          }
          @media screen and (max-width: 600px) {
            font-size: 0.7rem;
          }
          i {
            color: #5a5a5a;
            margin-right: 0.5rem;
          }
        }
      }
      .right {
        @media (max-width: 500px) {
          width: 100%;
        }
        a {
          padding: 15px 40px;
          background: $primary_color;
          color: #fff;
          border: none;
          border-radius: 5px;
          text-decoration: none;
          font-size: 1.2rem;
          &:hover {
            background: darken($primary_color, 10%);
          }
          @media screen and (max-width: 1700px) {
            font-size: 1rem;
            padding: 0.5rem 1rem;
          }
          @media (max-width: 500px) {
            font-size: 0.8rem;
            padding: 0.5rem 1rem;
            width: 100%;
            display: block;
          }
        }
      }
    }
    .images {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 0.51rem;
      .main-image {
        width: 100%;
        position: relative;
        height: 600px;
        @media screen and (max-width: 1700px) {
          height: 500px;
        }
        @media screen and (max-width: 1500px) {
          height: 350px;
        }
        @media (max-width: 768px) {
          height: 300px;
        }
        @media (max-width: 576px) {
          height: 40vh;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
          cursor: pointer;
        }
        .bottom-left {
          position: absolute;
          bottom: 2rem;
          left: 2rem;
          button {
            padding: 10px 40px;
          }
        }
      }
      .sub-images {
        margin-top: 1rem;
        width: 100%;
        img {
          object-fit: cover;
          border-radius: 10px;
          cursor: pointer;
          height: 100px;
          padding: 0 5px;
          width: 100%;
          @media screen and (max-width: 1700px) {
            height: 80px;
          }
          @media (max-width: 1300px) {
            height: 70px;
          }
          @media (max-width: 900px) {
            height: 70px;
          }
        }
      }
    }
    .item-details {
      display: flex;
      column-gap: 1rem;
      margin-top: 2rem;
      align-items: flex-start;
      @media (max-width: 1200px) {
        flex-flow: column-reverse;
      }
      .left-details {
        width: calc(100% - 250px);
        @media (max-width: 1200px) {
          width: 100%;
        }
        @media (max-width: 1200px) {
          width: 100%;
        }
        .Amenities {
          h3 {
            font-size: 1.8rem;
            color: #000;
            font-weight: 700;
            @media screen and (max-width: 1700px) {
              font-size: 1.5rem;
            }
            @media screen and (max-width: 1300px) {
              font-size: 1.3rem;
            }
            @media screen and (max-width: 900px) {
              font-size: 1.2rem;
            }
            @media screen and (max-width: 600px) {
              font-size: 1.1rem;
            }
          }
          .ratings {
            margin-bottom: 1.5rem;
            @media screen and (max-width: 1700px) {
              font-size: 0.9rem;
            }
            @media screen and (max-width: 1300px) {
              font-size: 0.8rem;
            }
            @media screen and (max-width: 900px) {
              font-size: 0.7rem;
            }
            @media screen and (max-width: 600px) {
              font-size: 0.6rem;
            }
          }
          .amenities-lists {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;
            @media (max-width: 1200px) {
              grid-template-columns: 1fr 1fr 1fr;
            }
            @media (max-width: 768px) {
              grid-template-columns: 1fr 1fr;
            }
            @media (max-width: 576px) {
              grid-template-columns: 1fr;
            }
            div {
              margin-bottom: 2rem;
              .title {
                font-size: 1.25rem;
                color: $primary_color;
                font-weight: 700;
                margin-bottom: 1rem;
                @media screen and (max-width: 1700px) {
                  font-size: 1rem;
                }
              }
              ul {
                li {
                  font-size: 1rem;
                  color: #3d3d3d;
                  font-weight: 400;
                  margin-bottom: 0.5rem;
                  margin-left: 15px;
                  @media screen and (max-width: 1700px) {
                    font-size: 0.7rem;
                  }
                  @media screen and (max-width: 1300px) {
                    font-size: 0.65rem;
                  }
                  @media screen and (max-width: 900px) {
                    font-size: 0.6rem;
                  }
                  @media screen and (max-width: 600px) {
                    font-size: 0.5rem;
                  }
                }
                span {
                  font-size: 1rem;
                  color: #3d3d3d;
                  font-weight: 400;
                  margin-bottom: 0.5rem;
                  margin-left: 15px;
                  display: inline-block;
                  @media screen and (max-width: 1700px) {
                    font-size: 0.7rem;
                    line-height: 1;
                    margin-bottom: 0rem;
                  }
                  i {
                    color: $primary_color;
                    margin-right: 0.3rem;
                  }
                }
              }
            }
          }
        }
        .description {
          h3 {
            font-size: 2rem;
            color: #000;
            font-weight: 700;
            margin-bottom: 1.5rem;
            @media screen and (max-width: 1700px) {
              font-size: 1.5rem;
            }
            @media screen and (max-width: 1300px) {
              font-size: 1.3rem;
            }
            @media screen and (max-width: 900px) {
              font-size: 1.2rem;
            }
            @media screen and (max-width: 600px) {
              font-size: 1.1rem;
            }
          }
          p {
            font-size: 1rem;
            color: #3d3d3d;
            margin-bottom: 2rem;
            @media screen and (max-width: 1700px) {
              font-size: 0.8rem;
            }
            @media screen and (max-width: 1300px) {
              font-size: 0.7rem;
            }
            @media screen and (max-width: 900px) {
              font-size: 0.6rem;
            }
            @media screen and (max-width: 600px) {
              font-size: 0.5rem;
            }
          }
        }
        .map {
          margin-top: 2rem;
          h3 {
            font-size: 2rem;
            margin-bottom: 1.5rem;
            color: #000;
            font-weight: 700;
            @media screen and (max-width: 1700px) {
              font-size: 1.5rem;
            }
            @media screen and (max-width: 1300px) {
              font-size: 1.3rem;
            }
            @media screen and (max-width: 900px) {
              font-size: 1.2rem;
            }
            @media screen and (max-width: 600px) {
              font-size: 1.1rem;
            }
          }
          iframe {
            width: 100%;
            height: 450px;
            border: 0;
            border-radius: 10px;
            @media screen and (max-width: 1700px) {
              height: 300px;
            }
            @media screen and (max-width: 1300px) {
              height: 300px;
            }
            @media screen and (max-width: 900px) {
              height: 300px;
            }
            @media screen and (max-width: 600px) {
              height: 250px;
            }
          }
        }
      }
      .right-details {
        width: 250px;
        @media (max-width: 1200px) {
          width: 100%;
          display: none;
        }
        border: 1px solid #e0e0e0;
        padding: 1rem;
        h2 {
          font-size: 1.25rem;
          color: #000;
          font-weight: 300;
          margin-bottom: 1.5rem;
          @media screen and (max-width: 1700px) {
            font-size: 1.25rem;
          }
          @media screen and (max-width: 1300px) {
            font-size: 1.1rem;
          }
          @media screen and (max-width: 900px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 600px) {
            font-size: 1rem;
          }
        }
      }
    }
    .item-grids {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      border-bottom: 1px solid #e0e0e0;
      border-top: 1px solid #e0e0e0;
      padding: 1rem;
      margin-bottom: 1rem;
      @media screen and (max-width: 700px) {
        flex-flow: column;
      }
      .item-grid-item {
        border-radius: 10px;
        .item-grid-item-title {
          font-size: 0.7rem;
          color: #000;
          font-weight: 700;
        }
        .item-grid-item-value {
          font-size: 0.8rem;
          color: #000;
          font-weight: 400;
          span {
            font-size: 0.8rem;
            color: #000;
            font-weight: 400;
          }
        }
      }
    }
  }
  .form {
    .form-group {
      margin-bottom: 1rem;
      label {
        font-size: 0.8rem;
        color: #000;
        font-weight: 400;
        margin-bottom: 0.5rem;
        display: block;
      }
      input,
      textarea {
        width: 100%;
        padding: 5px;
        border: 1px solid #e0e0e0;
        // border-radius: 0px;
        font-size: 0.8rem;
        color: #000;
        margin-top: 0.5rem;
      }

      button {
        padding: 10px 10px;
        background: $primary_color;
        color: #fff;
        border: none;
        border-radius: 5px;
        text-decoration: none;
        font-size: 1rem;
        margin-top: 1rem;
        display: block;
        width: 100%;
        &:hover {
          background: darken($primary_color, 10%);
        }
      }
    }
    .btns {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      button,
      a {
        padding: 10px 10px;
        background: $secondary_color;
        color: #fff;
        border: none;
        border-radius: 0px;
        text-decoration: none;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        width: 100%;
        white-space: nowrap;
        line-height: 1;
        &:hover {
          background: darken($secondary_color, 10%);
          color: #fff;
        }
        i {
          margin-left: 0;
        }
      }
    }
    .notice {
      margin-top: 1rem;
      border: 1px solid #e0e0e0;
      padding: 1rem;
      p {
        font-size: 0.7rem;
        color: #000;
        font-weight: 400;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        i {
          color: $primary_color;
          margin-right: 0.5rem;
          border-radius: 50%;
          background: lighten($primary_color, 50%);
          padding: 0.2rem;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .footer-button {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 0.5rem 1rem;
    border-top: 1px solid #e0e0e0;
    @media screen and (min-width: 1200px) {
      display: none;
    }
    .down {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      p {
        display: flex;
        flex-direction: column;
        .name {
          font-size: 1rem;
          color: $primary_color;
          font-weight: 600;
          i {
            color: $primary_color;
            font-size: 0.9rem;
          }
        }
        .price {
          font-size: 1.1rem;
          font-weight: 500;
          i {
            color: $primary_color;
            font-size: 1rem;
          }
        }
      }
      button {
        background: $primary_color;
        border-color: $primary_color;
        padding: 10px 15px;
        &:hover {
          background: darken($primary_color, 10%);
        }
      }
    }
  }
  .reserve-modal .ant-modal-body {
    background: red;
    .form-group {
      margin-bottom: 1rem;

      label {
        font-size: 0.8rem;
        color: #000;
        font-weight: 400;
        margin-bottom: 0.5rem;
        display: block;
      }

      input,
      textarea {
        width: 100%;
        padding: 5px;
        border: 1px solid #e0e0e0;
        font-size: 0.8rem;
        color: #000;
        margin-top: 0.5rem;
      }

      button {
        padding: 10px 10px;
        background: $primary_color;
        color: #fff;
        border: none;
        border-radius: 5px;
        text-decoration: none;
        font-size: 1rem;
        margin-top: 1rem;
        display: block;
        width: 100%;

        &:hover {
          background: darken($primary_color, 10%);
        }
      }
    }

    .btns {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      button,
      a {
        padding: 10px 10px;
        background: $secondary_color;
        color: #fff;
        border: none;
        border-radius: 0px;
        text-decoration: none;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        width: 100%;
        white-space: nowrap;
        line-height: 1;

        &:hover {
          background: darken($secondary_color, 10%);
          color: #fff;
        }

        i {
          margin-left: 0;
        }
      }
    }

    .notice {
      margin-top: 1rem;
      border: 1px solid #e0e0e0;
      padding: 1rem;

      p {
        font-size: 0.7rem;
        color: #000;
        font-weight: 400;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;

        i {
          color: $primary_color;
          margin-right: 0.5rem;
          border-radius: 50%;
          background: lighten($primary_color, 50%);
          padding: 0.2rem;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
