import { BACKEND } from '../variables.js';
import axios from 'axios'
const GET_SETTINGS = BACKEND + '/settings/getSettings';
const UPDATE_SETTINGS = BACKEND + '/settings/updateSettings';

export default {
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        ['GET_SETTINGS'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_SETTINGS,
            })
        },
        ['UPDATE_SETTINGS'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_SETTINGS,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
    },
};
