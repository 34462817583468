import { BACKEND } from '../variables.js';
import axios from 'axios'
const RESERVE_SPACE = BACKEND + '/spaceReservation/reserveSpace';
const GET_RESERVATIONS = BACKEND + '/spaceReservation/getSpaceReservations';
const GET_RESERVATIONS_BY_USER = BACKEND + '/spaceReservation/getSpaceReservationsByUser';
const GET_RESERVATIONS_BY_SPACE = BACKEND + '/spaceReservation/getSpaceReservationsBySpace';
const GET_RESERVATIONS_BY_TYPE = BACKEND + '/spaceReservation/getSpaceReservationsByType';

export default {
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        ['RESERVE_SPACE'](state, payload) {
            return axios({
                method: 'POST',
                url: RESERVE_SPACE,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['GET_RESERVATIONS'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_RESERVATIONS,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['GET_RESERVATIONS_BY_USER'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_RESERVATIONS_BY_USER,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['GET_RESERVATIONS_BY_SPACE'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_RESERVATIONS_BY_SPACE,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['GET_RESERVATIONS_BY_TYPE'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_RESERVATIONS_BY_TYPE,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
    },
};
