<template>
  <div class="About">
    <NavBar />
    <div class="d-flex-center">
      <div class="reserve-success" v-if="dataLoaded">
        <div class="success">
          <div class="icon">
            <i class="fa fa-check" v-if="status == 'success'"></i>
            <i
              class="fa fa-exclamation-triangle"
              v-if="status == 'partially_completed'"
            ></i>
            <i class="fa fa-times" v-if="status == 'error'"></i>
          </div>
          <h2>{{ message }}</h2>
          <p v-if="status == 'success'">
            Your room has been reserved successfully. You will receive a
            confirmation email shortly. Thank you for choosing Cumbinn.
          </p>
          <p v-if="status == 'partially_completed'">
            Your reservation has been partially completed. The balance payment
            is {{ balance }}. Please contact us at
            <b><a href="mailto:support@cumbinn.com">support@cumbinn.com</a></b>
            or call us at
            <b><a href="tel:+250782251852">+250 782 251 852</a></b> for
            assistance.
          </p>
          <p v-if="status == 'error'">
            Your reservation has failed. Please try again later or contact us at
            <b> <a href="mailto:support@cumbinn.com">support@cumbinn.com</a></b>
            or call us at
            <b><a href="tel:+250782251852">+250 782 251 852</a></b> for
            assistance.
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  components: {},
  data() {
    return {
      dataLoaded: false,
      message: "",
      status: "",
      balance: 0,
      payload: {
        order_tracking_id: "",
        merchant_reference: "",
      },
    };
  },
  methods: {
    completeReservation() {
      this.$startLoader();
      this.$store
        .dispatch("RESERVATION_COMPLETED", {
          data: this.payload,
        })
        .then((res) => {
          if (res) {
            this.dataLoaded = true;
            if (res.status === 200) {
              this.message = res.data.message;
              this.status = "success";
              this.$stopLoader();
            } else {
              this.message = res.data.message;
              this.status = res.data.status;
              this.balance = res.data.balance;
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.payload.order_tracking_id = this.$route.query.OrderTrackingId;
    this.payload.merchant_reference = this.$route.query.OrderMerchantReference;
    this.completeReservation();
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.About {
  .d-flex-center {
    min-height: 80vh;
    .success {
      max-width: 600px;
      background: #fff;
      border-radius: 10px;
      padding: 2rem;
      text-align: center;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 1700px) {
        width: 60%;
      }
      @media screen and (max-width: 1300px) {
        width: 70%;
      }
      @media screen and (max-width: 900px) {
        width: 80%;
      }
      @media screen and (max-width: 600px) {
        width: 90%;
      }
      .icon {
        font-size: 3rem;
        color: white;
        background: $primary_color;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      h2 {
        font-size: 2.5rem;
        color: $primary_color;
        margin-top: 1rem;
      }
      p {
        font-size: 1.25rem;
        color: #3d3d3d;
        margin-top: 1rem;
      }
    }
  }
}
</style>
