<template>
  <miLoader v-if="$store.state.startLoader" />
  <router-view v-if="$site()" />
</template>
<script>
import { toast } from "vue3-toastify";
export default {
  methods: {
    getSettings() {
      this.$store
        .dispatch("GET_SETTINGS", {})
        .then((res) => {
          this.$store.state.siteSettings = res.data.settings;
          console.log(res.data.settings);
        })
        .catch((err) => {
          toast.error("Something went wrong, please try again later.");
          console.log(err);
        });
    },
  },
  mounted() {
    this.getSettings();
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

$primary_color: #0c3b7c;
$secondary_color: #00bafc;
#app {
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-100 {
  margin-top: 100px;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-100 {
  margin-bottom: 100px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-100 {
  padding-bottom: 100px;
}
.bp {
  background: $primary_color;
}
.cp {
  color: $primary_color;
}
.bw {
  background: #fff;
}
.cw {
  color: #fff;
}
.btn {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  font-weight: 500;
  background: $primary_color;
  font-size: small;
  i {
    margin-left: 5px;
  }
  &.success {
    background: #00bafc;
    color: #fff;
    &:hover {
      background: darken($color: #00bafc, $amount: 10%);
    }
  }
}
.input-group {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.group-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 1rem;
  @media (max-width: 750px) {
    grid-template-columns: 1fr;
  }
}
.group-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  gap: 1rem;
  @media (max-width: 750px) {
    grid-template-columns: 1fr;
  }
}
.group-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  gap: 1rem;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 750px) {
    grid-template-columns: 1fr;
  }
}
.group-5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  gap: 1rem;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 750px) {
    grid-template-columns: 1fr;
  }
}

.why-choose-us {
  margin-top: 50px;
  .header {
    font-size: 2rem;
    margin-bottom: 20px;
    @media (max-width: 900px) {
      font-size: 1.5rem;
    }
    @media (max-width: 600px) {
      font-size: 1.25rem;
    }
  }
  .sub-header {
    font-size: 1.25rem;
    margin-bottom: 20px;
    @media (max-width: 900px) {
      font-size: 1.15rem;
    }
    @media (max-width: 600px) {
      font-size: 0.8rem;
    }
  }
  .features {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @media (max-width: 750px) {
      grid-template-columns: 1fr;
    }
    .feature-item {
      border: 1px solid #e0e0e070;
      border-radius: 5px;
      overflow: hidden;
      display: flex;
      box-shadow: 0px 0px 10px 0px #00000013;
      @media (max-width: 600px) {
        flex-direction: column;
        .left {
          padding: 10px 10px;
        }
      }
      .left {
        display: flex;
        align-items: center;
        padding: 20px 2rem;
        background-color: $primary_color;
        color: #fff;
        position: relative;
        // width: 250px;
        &:before {
          // triangle
          content: "";
          position: absolute;
          top: 10px;
          right: -9px;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 10px solid $primary_color;
        }
        i {
          font-size: 5rem;
          margin-right: 20px;
          position: absolute;
          bottom: -10px;
          left: -10px;
          opacity: 0.1;
        }
        .text {
          p {
            font-size: 1rem;
            font-weight: 400;
            opacity: 0.9;
          }
          h3 {
            font-size: 3rem;
            font-weight: 500;
            line-height: 1;
            color: #fff;
          }
        }
      }
      .right {
        padding: 20px;
        h3 {
          font-size: 1.25rem;
          margin-bottom: 10px;
          @media (max-width: 750px) {
            font-size: 1.15rem;
          }
        }
        p {
          font-size: 1rem;
          @media (max-width: 750px) {
            font-size: 0.8rem;
          }
        }
      }
      @media (max-width: 600px) {
        flex-direction: column;
        .left {
          padding: 10px 20px;

          &:before {
            display: none;
          }
          .text {
            h3 {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
.newsletters {
  background-color: $primary_color;
  padding: 50px 0;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    h2 {
      font-size: 2rem;
      color: #fff;
      margin-bottom: 20px;
      @media (max-width: 750px) {
        font-size: 1.5rem;
      }
    }
    p {
      color: #fff;
      margin-bottom: 20px;
    }
    form {
      display: flex;
      align-items: center;
      input {
        padding: 0px 20px;
        outline: none;
        margin-right: 20px;
        width: 400px;
        font-size: 1rem;
        font-weight: 500;
        background: #fff;
        border-radius: 500px;
        z-index: 1;
        height: 60px;
        box-shadow: -10px 10px 10px 0px #0000001f;
        @media (max-width: 750px) {
          width: 60vw;
        }
      }
      button {
        padding: 0px 20px;
        background-color: darken($color: $secondary_color, $amount: 10%);
        color: #fff;
        border: none;
        border-radius: 500px;
        transition: all 0.3s;
        font-size: 1rem;
        font-weight: 500;
        margin-left: -70px;
        padding-left: 65px;
        height: 60px;
        text-transform: uppercase;
        font-weight: 600;
        box-shadow: 10px 10px 10px 0px #0000001f;
        &:hover {
          background-color: $secondary_color;
        }
      }
    }
  }
}

.container {
  padding: 0 15rem;
  @media (max-width: 1500px) {
    padding: 0 10rem;
  }
  @media (max-width: 1200px) {
    padding: 0 5rem;
  }
  @media (max-width: 1000px) {
    padding: 0 3rem;
  }
  @media (max-width: 750px) {
    padding: 0 2rem;
  }
  @media (max-width: 500px) {
    padding: 0 1rem;
  }
  &.with-b {
    background: #eeeeee;
  }
}

.number-range {
  display: flex;
  align-items: center;
  i {
    font-size: 0.7rem;
    cursor: pointer;
    padding: 16px 15px;
    margin: 0;
    width: 60px;
    background: #eee;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    color: #000;
    @media (max-width: 1700px) {
      font-size: 12px;
      padding: 10px 10px;
    }
    @media (max-width: 768px) {
      font-size: 10px;
      padding: 8px 8px;
    }
    @media (max-width: 576px) {
      font-size: 8px;
      padding: 6px 6px;
    }
    &.left {
      border-radius: 50px 0 0 50px;
    }
    &.right {
      border-radius: 0 50px 50px 0;
    }
    &:hover {
      background: #999;
      color: #fff;
    }
  }
  input {
    padding: 10px 20px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    outline: none;
    width: calc(100% - 90px);
    text-align: center;
    margin: 0 !important;
    @media (max-width: 1700px) {
      font-size: 12px;
      padding: 7px 10px !important;
    }
    @media (max-width: 768px) {
      font-size: 10px;
      padding: 5px 8px !important;
    }
    @media (max-width: 576px) {
      font-size: 8px;
      padding: 4px 6px !important;
    }
  }
}
.items-listing {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  @media (max-width: 1700px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  @media (max-width: 1500px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  @media (max-width: 750px) {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
  &.events {
    grid-template-columns: 1fr;
    gap: 20px;
    @media (max-width: 1700px) {
      grid-template-columns: 1fr;
    }
    @media (max-width: 1500px) {
      grid-template-columns: 1fr;
    }
    @media (max-width: 750px) {
      grid-template-columns: 1fr;
    }
    @media (max-width: 500px) {
      grid-template-columns: 1fr;
    }
  }
  .listing-item {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    .image {
      position: relative;
      height: 200px;
      @media (max-width: 1500px) {
        height: 160px;
      }
      @media (max-width: 1200px) {
        height: 180px;
      }
      @media (max-width: 1000px) {
        height: 160px;
      }
      @media (max-width: 750px) {
        height: 140px;
      }
      @media (max-width: 500px) {
        height: 120px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .price {
        position: absolute;
        background-color: $primary_color;
        color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 1rem;
        font-weight: 500;
        // put it in center
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        @media (max-width: 1500px) {
          font-size: 0.7rem;
          padding: 3px 8px;
          bottom: -25px;
        }
        @media (max-width: 1200px) {
          font-size: 0.8rem;
          padding: 3px 8px;
        }
        @media (max-width: 1000px) {
          font-size: 0.8rem;
          padding: 3px 8px;
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
      }
    }
    .details {
      padding: 10px;
      text-decoration: none;
      color: #000;
      display: flex;
      flex-direction: column;
      .ratings {
        font-size: 1rem;

        @media screen and (max-width: 1700px) {
          font-size: 0.7rem;
        }
        @media screen and (max-width: 1300px) {
          font-size: 0.7rem;
        }
        @media screen and (max-width: 900px) {
          font-size: 0.7rem;
        }
        @media screen and (max-width: 600px) {
          font-size: 0.6rem;
        }
      }
      h3 {
        font-size: 1.25rem;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 0.6rem;
        @media (max-width: 1500px) {
          font-size: 1rem;
        }
        @media (max-width: 1200px) {
          font-size: 1rem;
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
      }
      p {
        display: flex;
        align-items: center;
        font-size: 1rem;
        margin-bottom: 10px;
        @media (max-width: 1500px) {
          font-size: 0.7rem;
        }
        @media (max-width: 1200px) {
          font-size: 0.65rem;
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }

        i {
          margin-right: 5px;
          @media (max-width: 1500px) {
            font-size: 0.65rem;
          }
          @media (max-width: 1200px) {
            font-size: 0.8rem;
          }
          @media (max-width: 1000px) {
          }
          @media (max-width: 750px) {
          }
          @media (max-width: 500px) {
          }
        }
        span {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        border-top: 1px solid #e0e0e0;
        padding: 0 20px;
        padding-top: 10px;
        @media (max-width: 1500px) {
          margin-top: 10px;
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
        span {
          display: flex;
          align-items: center;
          font-size: 1rem;
          @media (max-width: 1500px) {
            font-size: 0.75rem;
          }
          @media (max-width: 1200px) {
            font-size: 0.7rem;
          }
          @media (max-width: 1000px) {
            font-size: 0.6rem;
          }
          @media (max-width: 750px) {
          }
          @media (max-width: 500px) {
          }
          i {
            margin-right: 5px;
            color: $primary_color;
            line-height: 0;
            @media (max-width: 1500px) {
              font-size: 0.6rem;
            }
            @media (max-width: 1200px) {
            }
            @media (max-width: 1000px) {
            }
            @media (max-width: 750px) {
            }
            @media (max-width: 500px) {
            }
          }
        }
        .btn {
          padding: 10px 20px;
          border: 1px solid $primary_color;
          color: #fff;
          text-decoration: none;
          border-radius: 5px;
          transition: all 0.3s;
          font-size: 1rem;
          font-weight: 500;
          @media (max-width: 1500px) {
            padding: 4px 8px;
            font-size: 0.8rem;
            border-radius: 3px;
          }
          @media (max-width: 1200px) {
          }
          @media (max-width: 1000px) {
          }
          @media (max-width: 750px) {
          }
          @media (max-width: 500px) {
          }
          &:hover {
            box-shadow: inset 0 0 100px 100px rgb(8, 21, 37);
            color: #fff;
          }
        }
      }
    }
    .like {
      position: absolute;
      // background-color: #0000002f;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s;
      top: 10px;
      right: 10px;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      display: none;
      i {
        color: white;
        font-size: 1.25rem;
        &:hover {
          color: rgb(247, 11, 102);
        }
      }
    }
  }
}
.form-group {
  margin-bottom: 20px;
  .form-control {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    width: 100%;
    outline: none;
  }
  label {
    font-size: 0.8rem;
    margin-bottom: 10px;
    display: inline-block;
    span {
      color: red;
    }
  }
}
.flexbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my-btn {
  cursor: pointer;
  background: $primary_color;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease;
  text-decoration: none;
  font-weight: 500;
  i {
    margin-left: 5px;
  }
  &:hover {
    background: darken($color: $primary_color, $amount: 10%);
  }
}

.dash-content {
  padding: 0 2rem;
  .my-table {
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        th {
          text-align: left;
          padding: 1rem 0;
          font-weight: 600;
          border-bottom: 1px solid #e5e8ee;
          @media (max-width: 1500px) {
            font-size: 0.8rem;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 5px;
            border-bottom: 1px solid #e5e8ee;
            @media (max-width: 1500px) {
              font-size: 0.8rem;
            }
            img {
              width: 100px;
              height: 50px;
              object-fit: cover;
              border-radius: 5px;
            }
            button,
            a {
              margin-right: 10px;
              &.edit {
                background-color: #1d2088;
                color: white;
                &:hover {
                  background-color: #1eb9ee;
                }
              }
              &.delete {
                background-color: #a82929;
                color: white;
                &:hover {
                  background-color: #7a1f1f;
                }
              }
              &.default {
                background-color: #1d2088;
                color: white;
                &:hover {
                  background-color: #1eb9ee;
                }
              }
            }
          }
        }
      }
    }
  }
  .btns {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    button {
      margin-right: 10px;
      padding: 0.35rem 1rem;
      &.cancel {
        background-color: #a82929;
        color: white;
        &:hover {
          background-color: #7a1f1f;
        }
      }
    }
  }
}
.flex-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
  button {
    label {
      padding: 5px 15px;
      border: 1px solid #eeeeee;
      border-radius: 5px;
      margin: 0 10px;
      transition: all 0.3s;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        background-color: $primary_color;
        color: #fff;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      &.active {
        background-color: $primary_color;
        color: #fff;
      }
    }
  }
}
td {
  .lines {
    span {
      display: block;
      label {
        font-weight: 600;
      }
    }
  }
  &.statuses {
    background: #555;
    text-align: center;
    color: #fff;
    border: 0.5px solid #fff;
    &.pending,
    &.Pending {
      background-color: #af8102;
      color: white;
    }
    &.inProgress,
    &.processing {
      background-color: #813f02;
      color: white;
    }
    &.completed,
    &.approved,
    &.confirmed {
      background-color: #017501;
      color: white;
    }
    &.cancelled,
    &.canceled {
      background-color: #851d1d;
      color: white;
    }
  }
}
</style>
