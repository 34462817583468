<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span>Tours</span
        ><button class="my-btn cw glow wd" @click="startAdd = true">
          Add Tour
        </button>
      </h1>

      <div class="my-table">
        <table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Title</th>
              <th>Location</th>
              <th>Attraction</th>
              <th>Phone</th>
              <th>Price</th>
              <th>Expiry</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in allItems" :key="item.id">
              <td>
                <img :src="$upload(item.image)" alt="Location Image" />
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.location.name }}</td>
              <td>{{ item.attraction }}</td>
              <td>{{ item.support_number }}</td>
              <td>{{ item.currency }} {{ item.price }}</td>
              <td>{{ item.will_expire ? item.expiry_date : "No" }}</td>

              <td>
                <router-link
                  :to="{
                    name: 'AdminSpaceReservations',
                    params: { id: item.space_id, name: 'tours' },
                  }"
                  @click="
                    selectedItem = item;
                    startEdit = true;
                  "
                  class="btn success"
                >
                  Reservations
                </router-link>
                <button
                  @click="
                    selectedItem = item;
                    startEdit = true;
                  "
                  class="btn edit"
                >
                  Edit
                </button>
                <button
                  @click="
                    selectedItem = item;
                    startDelete = true;
                  "
                  class="btn delete"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>
      <Model
        v-if="startAdd"
        @closeModel="startAdd = false"
        :modelTitle="'Add New'"
        :size="'md'"
      >
        <div class="model-body">
          <div class="form-group group-3">
            <div class="input-group">
              <label for="title">Title / Name <span>*</span></label>
              <input
                type="text"
                v-model="newItem.name"
                placeholder="A 5 Days / 4 Nights Nyungwe Tour"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="location">Location <span>*</span></label>
              <select v-model="newItem.location_id" class="form-control">
                <option value="" disabled selected>Select Location</option>
                <option
                  v-for="location in locations"
                  :key="location.location_id"
                  :value="location.location_id"
                >
                  {{ location.name }}
                </option>
              </select>
            </div>
            <div class="input-group">
              <label for="title">Attraction <span>*</span></label>
              <input
                type="text"
                v-model="newItem.attraction"
                placeholder="Attraction"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group group-3">
            <div class="input-group">
              <label for="Category">Address <span>*</span></label>
              <input
                type="text"
                v-model="newItem.address"
                placeholder="Address"
                class="form-control"
                size="large"
                style="width: 100%"
              />
            </div>
            <div class="input-group">
              <label for="price">Price <span>*</span></label>
              <div class="control-group price flex">
                <select
                  name=""
                  id=""
                  v-model="newItem.currency"
                  class="form-control"
                >
                  <option value="USD">USD</option>
                  <option value="RWF">RWF</option>
                </select>
                <input
                  type="number"
                  v-model="newItem.price"
                  placeholder="Price"
                  class="form-control"
                />
              </div>
            </div>
            <div class="input-group">
              <label for="payment_frequency">Max People <span>*</span></label>
              <!-- <input
                type="number"
                v-model="newItem.maxPeople"
                class="form-control"
              /> -->
              <a-input-number
                id="inputNumber"
                v-model:value="newItem.maxPeople"
                :min="1"
                :max="10000"
                size="large"
                style="width: 100%"
              >
                <template #upIcon>
                  <ArrowUpOutlined />
                </template>
                <template #downIcon>
                  <ArrowDownOutlined />
                </template>
              </a-input-number>
            </div>
          </div>
          <div class="form-group group-3">
            <div class="input-group">
              <label for="features">Favourable for</label>
              <a-tree-select
                v-model:value="newItem.tourGroups"
                style="width: 100%"
                :tree-data="treeData"
                tree-checkable
                allow-clear
                placeholder="Please select"
                tree-node-filter-prop="label"
                size="large"
              />
            </div>
            <div class="input-group">
              <label for="map">Support number</label>
              <input
                type="text"
                v-model="newItem.support_number"
                placeholder="Support number"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="map">Support email</label>
              <input
                type="email"
                v-model="newItem.support_email"
                placeholder="Support email"
                class="form-control"
              />
            </div>
          </div>
          <!-- features -->
          <div class="form-group">
            <div class="input-group">
              <label for="features">Features</label>
              <v-combobox
                v-model="newItem.inclusives"
                label="Features"
                variant="solo"
                chips
                clearable
                multiple
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :model-value="selected"
                    closable
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>{{ item }}</strong
                    >&nbsp;
                    <span>(interest)</span>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <QuillEditor
              :toolbar="'full'"
              v-model:content="newItem.description"
              :contentType="'html'"
              theme="snow"
              rows="10"
            />
          </div>
          <div class="form-group">
            <div class="input-group">
              <label for="image">Main Image <span>*</span></label>
              <o-upload
                :multiple="false"
                @imagesUploaded="
                  (data) => {
                    newItem.image = data;
                  }
                "
                @imagesUploadedFailed="
                  (data) => {
                    console.log('failed');
                  }
                "
                :value="newItem.image"
                @startLoader="() => $startLoader()"
                @stopLoader="() => $stopLoader()"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="input-group">
              <label for="images">Other Images <span>*</span></label>
              <o-upload
                :multiple="true"
                @imagesUploaded="
                  (data) => {
                    newItem.images = data;
                  }
                "
                @imagesUploadedFailed="
                  (data) => {
                    console.log('failed');
                  }
                "
                :value="newItem.images"
                @startLoader="() => $startLoader()"
                @stopLoader="() => $stopLoader()"
              />
            </div>
          </div>
          <div class="form-group group-2">
            <div class="input-group">
              <label for="Will expire">Will expire?</label>
              <select v-model="newItem.will_expire" class="form-control">
                <option :value="0">No</option>
                <option :value="1">Yes</option>
              </select>
            </div>
            <div class="input-group">
              <label for="Expiry date">Expiry date</label>
              <input
                type="date"
                v-model="newItem.expiry_date"
                class="form-control"
                :disabled="newItem.will_expire == 0"
              />
            </div>
          </div>
          <div class="btns flex">
            <button @click="startAdd = false" class="my-btn cw wd cancel">
              Cancel
            </button>
            <button @click="createItem" class="btn cw glow wd">Save</button>
          </div>
        </div>
      </Model>
      <Model
        v-if="startEdit"
        @closeModel="startEdit = false"
        :modelTitle="'Edit'"
        :size="'md'"
      >
        <div class="model-body">
          <div class="form-group group-3">
            <div class="input-group">
              <label for="title">Title / Name <span>*</span></label>
              <input
                type="text"
                v-model="selectedItem.name"
                placeholder="A 5 Days / 4 Nights Nyungwe Tour"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="location">Location <span>*</span></label>
              <select v-model="selectedItem.location_id" class="form-control">
                <option value="" disabled selected>Select Location</option>
                <option
                  v-for="location in locations"
                  :key="location.location_id"
                  :value="location.location_id"
                >
                  {{ location.name }}
                </option>
              </select>
            </div>
            <div class="input-group">
              <label for="title">Attraction <span>*</span></label>
              <input
                type="text"
                v-model="selectedItem.attraction"
                placeholder="Attraction"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group group-3">
            <div class="input-group">
              <label for="Category">Address <span>*</span></label>
              <input
                type="text"
                v-model="selectedItem.address"
                placeholder="Address"
                class="form-control"
                size="large"
                style="width: 100%"
              />
            </div>
            <div class="input-group">
              <label for="price">Price <span>*</span></label>
              <div class="control-group price flex">
                <select
                  name=""
                  id=""
                  v-model="selectedItem.currency"
                  class="form-control"
                >
                  <option value="USD">USD</option>
                  <option value="RWF">RWF</option>
                </select>
                <input
                  type="number"
                  v-model="selectedItem.price"
                  placeholder="Price"
                  class="form-control"
                />
              </div>
            </div>
            <div class="input-group">
              <label for="payment_frequency">Max People <span>*</span></label>
              <!-- <input
                type="number"
                v-model="selectedItem.maxPeople"
                class="form-control"
              /> -->
              <a-input-number
                id="inputNumber"
                v-model:value="selectedItem.maxPeople"
                :min="1"
                :max="10000"
                size="large"
                style="width: 100%"
              >
                <template #upIcon>
                  <ArrowUpOutlined />
                </template>
                <template #downIcon>
                  <ArrowDownOutlined />
                </template>
              </a-input-number>
            </div>
          </div>
          <div class="form-group group-3">
            <div class="input-group">
              <label for="features">Favourable for</label>
              <a-tree-select
                v-model:value="selectedItem.tourGroups"
                style="width: 100%"
                :tree-data="treeData"
                tree-checkable
                allow-clear
                placeholder="Please select"
                tree-node-filter-prop="label"
                size="large"
              />
            </div>
            <div class="input-group">
              <label for="map">Support number</label>
              <input
                type="text"
                v-model="selectedItem.support_number"
                placeholder="Support number"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="map">Support email</label>
              <input
                type="email"
                v-model="selectedItem.support_email"
                placeholder="Support email"
                class="form-control"
              />
            </div>
          </div>
          <!-- features -->
          <div class="form-group">
            <div class="input-group">
              <label for="features">Features</label>
              <v-combobox
                v-model="selectedItem.inclusives"
                label="Features"
                variant="solo"
                chips
                clearable
                multiple
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :model-value="selected"
                    closable
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>{{ item }}</strong
                    >&nbsp;
                    <span>(interest)</span>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <QuillEditor
              :toolbar="'full'"
              v-model:content="selectedItem.description"
              :contentType="'html'"
              theme="snow"
              rows="10"
            />
          </div>
          <div class="form-group">
            <label for="image">Main Image <span>*</span></label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  selectedItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="selectedItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="form-group">
            <label for="images">Other Images <span>*</span></label>
            <o-upload
              :multiple="true"
              @imagesUploaded="
                (data) => {
                  selectedItem.images = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="selectedItem.images"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="form-group group-2">
            <div class="input-group">
              <label for="Will expire">Will expire?</label>
              <select v-model="selectedItem.will_expire" class="form-control">
                <option :value="0">No</option>
                <option :value="1">Yes</option>
              </select>
            </div>
            <div class="input-group">
              <label for="Expiry date">Expiry date</label>
              <input
                type="date"
                v-model="selectedItem.expiry_date"
                class="form-control"
                :disabled="selectedItem.will_expire == 0"
              />
            </div>
          </div>
          <div class="btns flex">
            <button @click="startEdit = false" class="my-btn cw wd cancel">
              Cancel
            </button>
            <button @click="updateItem" class="btn cw glow wd">Save</button>
          </div>
        </div>
      </Model>
      <confirmModel
        v-if="startDelete"
        @closeModel="startDelete = false"
        :modelTitle="'Delete Item'"
        :size="'sm'"
        :displayText="`Are you sure you want to delete ${selectedItem.name}?`"
        @confirmModel="deleteItem"
      />
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import ArrowUpOutlined from "@ant-design/icons-vue";

export default {
  components: {
    ArrowUpOutlined,
  },
  data() {
    return {
      locations: [],
      newItem: {
        name: "",
        location_id: "",
        address: "",
        price: 0,
        currency: "USD",
        support_number: "",
        support_email: "",
        description: "",
        image: "",
        images: [],
        inclusives: [],
        will_expire: 0,
        expiry_date: "",
        payment_frequency: "Per Day",
      },
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      allItemsCount: 0,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      allItems: [],
      featuresItems: [
        "Free toiletries",
        "Shower",
        "Bathrobe",
        "Safety deposit box",
        "Toilet",
        "Sofa",
        "Hardwood or parquet floors",
        "Towels",
        "Socket near the bed",
        "Tile/marble floor",
        "Desk",
        "Seating Area",
        "TV",
        "Slippers",
        "Refrigerator",
        "Telephone",
        "Ironing facilities",
        "Satellite channels",
      ],
      treeData: [
        {
          label: "Family Tour",
          value: "Family Tour",
        },
        {
          label: "Group Tour",
          value: "Group Tour",
        },
        {
          label: "Honeymoon Tour",
          value: "Honeymoon Tour",
        },
        {
          label: "Adventure Tour",
          value: "Adventure Tour",
        },
        {
          label: "Solo Tour",
          value: "Solo Tour",
        },
      ],
    };
  },
  methods: {
    getLocations() {
      this.$store
        .dispatch("GET_LOCATIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.locations = res.data.locations.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            setTimeout(() => {
              this.$userLogout();
            }, 1000);
          } else {
            toast.error("Something went wrong, please try again later.");
          }
        });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SPACE_BY_TYPE", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            type: "Tours",
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allItems = res.data.spaces.data;
              this.allItemsCount = res.data.spaces.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$userLogout();
            this.$stopLoader();
          } else {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          }
        });
    },
    createItem() {
      if (
        this.newItem.name &&
        this.newItem.location_id &&
        this.newItem.address
      ) {
        this.$startLoader();
        this.newItem.type = "Tours";
        this.$store
          .dispatch("CREATE_SPACE", {
            data: this.newItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch((err) => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all required fields");
      }
    },
    updateItem() {
      if (
        this.selectedItem.name &&
        this.selectedItem.location_id &&
        this.selectedItem.address
      ) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_SPACE", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startEdit = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              toast.error("Session expired, please login again.");
              this.$userLogout();
              this.$stopLoader();
            } else {
              toast.error("Something went wrong, please try again later.");
              this.$stopLoader();
            }
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_SPACE", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$userLogout();
            this.$stopLoader();
          } else {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          }
        });
    },
  },
  mounted() {
    this.getItems();
    this.getLocations();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 1.5rem;
      font-weight: 600;
      b {
        color: $primary_color;
        font-weight: 600;
      }
    }
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .my-table {
      table {
        width: 100%;
        border-collapse: collapse;
        thead {
          th {
            text-align: left;
            padding: 1rem 0;
            font-weight: 600;
            border-bottom: 1px solid #e5e8ee;
          }
        }
        tbody {
          tr {
            td {
              padding: 5px;
              border-bottom: 1px solid #e5e8ee;
              img {
                width: 100px;
                height: 50px;
                object-fit: cover;
                border-radius: 5px;
              }
              button {
                margin-right: 10px;
                &.edit {
                  background-color: #1d2088;
                  color: white;
                  &:hover {
                    background-color: #1eb9ee;
                  }
                }
                &.delete {
                  background-color: #a82929;
                  color: white;
                  &:hover {
                    background-color: #7a1f1f;
                  }
                }
                &.default {
                  background-color: #1d2088;
                  color: white;
                  &:hover {
                    background-color: #1eb9ee;
                  }
                }
              }
            }
          }
        }
      }
    }
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
        &.cancel {
          background-color: #a82929;
          color: white;
          &:hover {
            background-color: #7a1f1f;
          }
        }
      }
    }
  }
}

.form-group {
  column-gap: 10px;
}
.control-group {
  display: flex;
  align-items: center;
  select {
    padding: 0.5rem;
    width: 100px;
    text-align: center;
    border-radius: 5px 0px 0px 5px;
    background: #eee;
  }
  input {
    padding: 0.5rem;
    border-radius: 0px 5px 5px 0px;
  }
}
</style>