<template>
  <router-link
    :to="{
      name: 'ViewAccomodation',
      params: { id: item.accomodation_id, name: item.title },
    }"
    class="listing-item"
    v-for="item in filteredItems"
    :key="item.id"
  >
    <div class="image">
      <img :src="$upload(item.image)" :alt="item.name" />
      <p class="price">From {{ $comma(item.price) }} {{ item.currency }}</p>
    </div>
    <router-link
      :to="{
        name: 'ViewAccomodation',
        params: { id: item.accomodation_id, name: item.title },
      }"
      class="details"
    >
      <h3>{{ item.title }}</h3>
      <p>
        <i class="fa-solid fa-location-dot"></i>
        <span>{{ item.address }}</span>
      </p>
      <p>
        <i class="fa-solid fa-layer-group"></i>
        <span>{{ item.category.name }}</span>
      </p>
      <div class="ratings">
        <ratings :count="$int(item.rating)"></ratings>
      </div>
      <!-- <div class="footer">
        <span v-if="item.owner">
          <i class="fa fa-user"></i>
          {{ item.owner }}
        </span>
        <span v-else>
          <i class="fa fa-user"></i>
          Cumbinn
        </span>
        <router-link
          class="primary btn"
          :to="{
            name: 'ViewAccomodation',
            params: { id: item.accomodation_id, name: item.title },
          }"
          >View Details</router-link
        >
      </div> -->
    </router-link>
    <div class="like">
      <i class="fa fa-heart"></i>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    filteredItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rating: 4,
    };
  },
};
</script>

<style>
</style>