import { BACKEND } from '../variables.js';
import axios from 'axios'
const GET_BLOGS = BACKEND + '/blog/getBlogs';
const GET_BLOG = BACKEND + '/blog/getBlog';
const CREATE_BLOG = BACKEND + '/blog/createBlog';
const UPDATE_BLOG = BACKEND + '/blog/updateBlog';
const DELETE_BLOG = BACKEND + '/blog/deleteBlog';

export default {
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        ['GET_BLOGS'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_BLOGS + `?page=${payload.data.page}`,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['GET_BLOG'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_BLOG,
                data: payload.data
            })
        },
        ['CREATE_BLOG'](state, payload) {
            return axios({
                method: 'POST',
                url: CREATE_BLOG,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_BLOG'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_BLOG,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['DELETE_BLOG'](state, payload) {
            return axios({
                method: 'POST',
                url: DELETE_BLOG,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        }

    },
};
