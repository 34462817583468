<template>
  <div class="Properties">
    <NavBar />
    <div class="container">
      <div class="booking-section">
        <div class="steppers">
          <div class="step" :class="{ active: step >= 1 }">
            <div class="circle" v-if="step == 1">1</div>
            <div class="circle" v-if="step > 1">✓</div>
            <p>Booking Details</p>
          </div>
          <hr :class="{ active: step > 1 }" />
          <div class="step" :class="{ active: step >= 2 }">
            <div class="circle" v-if="step == 2">2</div>
            <div class="circle" v-if="step > 2">✓</div>
            <p>Your Details</p>
          </div>
          <hr :class="{ active: step > 2 }" />
          <div class="step" :class="{ active: step >= 3 }">
            <div class="circle" v-if="step == 3">3</div>
            <div class="circle" v-if="step > 3">✓</div>
            <p>Final Step</p>
          </div>
        </div>
        <div class="tabs-sections" :class="{ active: step > 3 }">
          <div class="tabs">
            <div class="tab" v-if="step == 1">
              <div class="form-title">
                <h1>Booking Details</h1>
              </div>
              <div class="form-section">
                <!-- rooms -->
                <div class="form-group rooms">
                  <label for="rooms"
                    >Select Room(s)
                    <span>Click on a room to select it</span></label
                  >
                  <div class="rooms-list">
                    <div
                      class="room"
                      v-for="room in selectedItem.rooms"
                      :key="room.id"
                      @click="addRoom(room)"
                      :class="{
                        active:
                          payload.rooms &&
                          payload.rooms.find((r) => r.id === room.id),
                      }"
                    >
                      <div class="room-name">
                        <p>{{ room.name }}</p>
                      </div>
                      <div class="room-price">
                        <p>{{ room.price }} {{ room.currency }}</p>
                      </div>
                      <div class="payment">
                        <p>{{ room.payment }}</p>
                      </div>
                      <div class="check">
                        <i
                          class="fa-solid fa-check"
                          v-if="
                            payload.rooms &&
                            payload.rooms.find((r) => r.id === room.id)
                          "
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div class="room-inputs group-3">
                    <div
                      class="form-group"
                      v-for="room in payload.rooms"
                      :key="room.id"
                    >
                      <label for="room-{{ room.id }}">{{ room.name }}</label>
                      <input
                        type="number"
                        id="room-{{ room.id }}"
                        v-model="room.quantity"
                        @change="getTotals()"
                        min="1"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group group-3">
                  <div class="input-group">
                    <label for="check-in">Check-in</label>
                    <input
                      type="date"
                      id="check-in"
                      @change="
                        countDays();
                        getTotals();
                      "
                      :min="$today()"
                      v-model="payload.checkin"
                    />
                  </div>
                  <div class="input-group">
                    <label for="check-out">Check-out</label>
                    <input
                      type="date"
                      id="check-out"
                      :min="payload.checkin"
                      @change="
                        countDays();
                        getTotals();
                      "
                      v-model="payload.checkout"
                    />
                  </div>
                  <div class="input-group">
                    <label for="check-out">Nights</label>
                    <input
                      type="number"
                      id="check-out"
                      v-model="payload.days"
                      disabled
                      style="cursor: not-allowed"
                    />
                  </div>
                </div>
                <div class="form-group group-3">
                  <div class="input-group">
                    <label for="check-in">Estimated Check-in Time</label>
                    <!-- time ranges -->
                    <select name="" id="" v-model="payload.checkinTime">
                      <option value="00:00 AM - 02:00 AM">
                        00:00 AM - 02:00 AM
                      </option>
                      <option value="02:00 AM - 04:00 AM">
                        02:00 AM - 04:00 AM
                      </option>
                      <option value="04:00 AM - 06:00 AM">
                        04:00 AM - 06:00 AM
                      </option>
                      <option value="06:00 AM - 08:00 AM">
                        06:00 AM - 08:00 AM
                      </option>
                      <option value="08:00 AM - 10:00 AM">
                        08:00 AM - 10:00 AM
                      </option>
                      <option value="10:00 AM - 12:00 PM">
                        10:00 AM - 12:00 PM
                      </option>
                      <option value="12:00 PM - 02:00 PM">
                        12:00 PM - 02:00 PM
                      </option>
                      <option value="02:00 PM - 04:00 PM">
                        02:00 PM - 04:00 PM
                      </option>
                      <option value="04:00 PM - 06:00 PM">
                        04:00 PM - 06:00 PM
                      </option>
                      <option value="06:00 PM - 08:00 PM">
                        06:00 PM - 08:00 PM
                      </option>
                      <option value="08:00 PM - 10:00 PM">
                        08:00 PM - 10:00 PM
                      </option>
                      <option value="10:00 PM - 12:00 AM">
                        10:00 PM - 12:00 AM
                      </option>
                    </select>
                  </div>
                  <div class="input-group">
                    <label for="check-out">Estimated Check-out Time</label>
                    <!-- time ranges -->
                    <select name="" id="" v-model="payload.checkoutTime">
                      <option value="00:00 AM - 02:00 AM">
                        00:00 AM - 02:00 AM
                      </option>
                      <option value="02:00 AM - 04:00 AM">
                        02:00 AM - 04:00 AM
                      </option>
                      <option value="04:00 AM - 06:00 AM">
                        04:00 AM - 06:00 AM
                      </option>
                      <option value="06:00 AM - 08:00 AM">
                        06:00 AM - 08:00 AM
                      </option>
                      <option value="08:00 AM - 10:00 AM">
                        08:00 AM - 10:00 AM
                      </option>
                      <option value="10:00 AM - 12:00 PM">
                        10:00 AM - 12:00 PM
                      </option>
                      <option value="12:00 PM - 02:00 PM">
                        12:00 PM - 02:00 PM
                      </option>
                      <option value="02:00 PM - 04:00 PM">
                        02:00 PM - 04:00 PM
                      </option>
                      <option value="04:00 PM - 06:00 PM">
                        04:00 PM - 06:00 PM
                      </option>
                      <option value="06:00 PM - 08:00 PM">
                        06:00 PM - 08:00 PM
                      </option>
                      <option value="08:00 PM - 10:00 PM">
                        08:00 PM - 10:00 PM
                      </option>
                      <option value="10:00 PM - 12:00 AM">
                        10:00 PM - 12:00 AM
                      </option>
                    </select>
                  </div>
                  <div class="input-group">
                    <!-- <label for="check-out">People</label> -->
                    <div class="group-2">
                      <div class="people-group">
                        <label for="adults">Adults</label>
                        <div class="number-range">
                          <i
                            class="fa-solid fa-minus left"
                            @click="
                              payload.adults > 1
                                ? (payload.adults = payload.adults - 1)
                                : (payload.adults = 1)
                            "
                          ></i>
                          <input
                            type="number"
                            name=""
                            id=""
                            v-model="payload.adults"
                          />
                          <i
                            class="fa-solid fa-plus right"
                            @click="payload.adults = payload.adults + 1"
                          ></i>
                        </div>
                      </div>
                      <div class="people-group">
                        <label for="children">Children</label>
                        <div class="number-range">
                          <i
                            class="fa-solid fa-minus left"
                            @click="
                              payload.children > 0
                                ? (payload.children = payload.children - 1)
                                : (payload.children = 0)
                            "
                          ></i>
                          <input
                            type="number"
                            name=""
                            id=""
                            v-model="payload.children"
                          />
                          <i
                            class="fa-solid fa-plus right"
                            @click="payload.children = payload.children + 1"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="form-group">
                  <div class="input-group">
                    <label for="check-in">Add to your stay</label>
                    <div class="checkboxes">
                      <div class="checkbox">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          v-model="payload.addons.carRental"
                        />
                        <label for="">I'm interested in car rental</label>
                        <span>
                          We will let your accomodation know you are interested
                          in a car rental and they will contact you with more
                          information about it.
                        </span>
                      </div>
                      <div class="checkbox">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          v-model="payload.addons.airportShuttle"
                        />
                        <label for="">I'm interested in airport shuttle</label>
                        <span>
                          We will let your accomodation know you are interested
                          in an airport shuttle and they will contact you with
                          more information about it.
                        </span>
                      </div>
                      <div class="checkbox">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          v-model="payload.addons.privateParking"
                        />
                        <label for="">I'm interested in private parking</label>
                        <span>
                          We will let your accomodation know you are interested
                          in private parking and they will contact you with more
                          information about it.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <label for="check-in">Special Requests</label>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="5"
                      placeholder="Special requests"
                      v-model="payload.specialRequests"
                    ></textarea>
                  </div>
                </div>
                <div class="submit">
                  <p></p>
                  <button
                    class="btn bp cw wd"
                    @click="step = 2"
                    :disabled="
                      !payload.rooms || !payload.checkin || !payload.checkout
                    "
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div class="tab" v-if="step == 2">
              <div class="form-title">
                <h1>Your Details</h1>
              </div>
              <div class="form-section">
                <div class="form-group group-2">
                  <div class="input-group">
                    <label for="first-name">First Name</label>
                    <input
                      type="text"
                      name=""
                      id="first-name"
                      v-model="payload.userInfo.firstName"
                    />
                  </div>
                  <div class="input-group">
                    <label for="last-name">Last Name</label>
                    <input
                      type="text"
                      name=""
                      id="last-name"
                      v-model="payload.userInfo.lastName"
                    />
                  </div>
                </div>
                <div class="form-group group-2">
                  <div class="input-group">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      name=""
                      id="email"
                      v-model="payload.userInfo.email"
                    />
                  </div>
                  <div class="input-group">
                    <label for="phone">Phone</label>
                    <input
                      type="tel"
                      name=""
                      id="phone"
                      v-model="payload.userInfo.phone"
                    />
                  </div>
                </div>
                <div class="form-group group-2">
                  <div class="input-group">
                    <label for="country">Country</label>
                    <input
                      type="text"
                      name=""
                      id="country"
                      v-model="payload.userInfo.country"
                    />
                  </div>
                  <div class="input-group">
                    <label for="city">City</label>
                    <input
                      type="text"
                      name=""
                      id="city"
                      v-model="payload.userInfo.city"
                    />
                  </div>
                </div>
                <div class="form-group group-2">
                  <div class="input-group">
                    <label for="address">Address</label>
                    <input
                      type="text"
                      name=""
                      id="address"
                      v-model="payload.userInfo.address"
                    />
                  </div>
                  <div class="input-group">
                    <label for="postal-code">Postal Code</label>
                    <input
                      type="text"
                      name=""
                      id="postal-code"
                      v-model="payload.userInfo.postalCode"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <label for="check-in">Any other details</label>
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="5"
                      placeholder="Any other details you would like to be considered"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
                <div class="submit">
                  <button class="btn bp cw wd back" @click="step = 1">
                    Back
                  </button>
                  <button
                    class="btn bp cw wd"
                    @click="step = 3"
                    :disabled="
                      !payload.userInfo.firstName ||
                      !payload.userInfo.lastName ||
                      !payload.userInfo.email ||
                      !payload.userInfo.phone ||
                      !payload.userInfo.country ||
                      !payload.userInfo.city ||
                      !payload.userInfo.address ||
                      !payload.userInfo.postalCode
                    "
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div class="tab" v-if="step == 3">
              <div class="form-title">
                <h1>How would you like to pay?</h1>
              </div>
              <div class="form-section">
                <div class="form-group group-3">
                  <div class="input-group">
                    <label for="Payment Method">Payment Method</label>
                    <select name="" id="" v-model="payload.payment_type">
                      <option value="Card">Card</option>
                      <option value="Mobile Money">Mobile Money</option>
                    </select>
                  </div>
                </div>
                <div class="card-data" v-if="payload.payment_type === 'Card'">
                  <div class="form-group group-3">
                    <div class="input-group">
                      <label for="card-number">Card Number</label>
                      <input
                        type="text"
                        name=""
                        id="card-number"
                        placeholder="**** **** **** ****"
                        v-model="payload.card.number"
                      />
                    </div>
                    <div class="input-group">
                      <label for="expiry-date">Expiry Date</label>
                      <div class="group-2">
                        <input
                          type="text"
                          name=""
                          id="expiry-date"
                          placeholder="Month"
                          v-model="payload.card.month"
                        />
                        <input
                          type="text"
                          name=""
                          id="expiry-date"
                          placeholder="Year"
                          v-model="payload.card.year"
                        />
                      </div>
                    </div>
                    <div class="input-group">
                      <label for="cvv">CVV</label>
                      <input
                        type="text"
                        name=""
                        id="cvv"
                        placeholder="***"
                        v-model="payload.card.cvv"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="momo-data"
                  v-if="payload.payment_type === 'Mobile Money'"
                >
                  <div class="form-group group-3" style="align-items: flex-end">
                    <div class="input-group">
                      <label for="phone">Phone Number</label>
                      <input
                        type="tel"
                        name=""
                        id="phone"
                        v-model="payload.payment_phone"
                        placeholder="250000000000"
                      />
                    </div>
                    <img
                      src="/img/mtn.jpg"
                      alt="momo"
                      v-if="$checkline('mtn', payload.payment_phone)"
                      style="height: 50px"
                    />
                    <img
                      src="/img/airtel-tigo.png"
                      alt="momo"
                      v-if="$checkline('airtel', payload.payment_phone)"
                      style="height: 50px"
                    />
                  </div>
                </div>
                <div class="form-group group-1">
                  <div class="consents">
                    <div class="checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        v-model="payload.consents.terms"
                      />
                      <label for=""
                        >I consent to the terms and conditions of CumBinn</label
                      >
                    </div>
                    <div class="checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        v-model="payload.consents.privacy"
                      />
                      <label for=""
                        >I consent to the privacy policy of CumBinn</label
                      >
                    </div>
                    <div class="checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        v-model="payload.consents.marketing"
                      />
                      <label for=""
                        >I consent to receive marketing emails about Special
                        Offers and Promotions</label
                      >
                    </div>
                  </div>
                </div>
                <div class="amounts">
                  <div class="amount flexbtn">
                    <p>Sub-Total</p>
                    <p>{{ payload.subTotal }} {{ selectedItem.currency }}</p>
                  </div>
                  <div class="amount flexbtn">
                    <p>Service Charge ({{ payload.serviceRate }}%)</p>
                    <p>{{ payload.serviceFees }} {{ selectedItem.currency }}</p>
                  </div>
                  <div class="amount flexbtn">
                    <p>Total</p>
                    <p>{{ payload.total }} {{ selectedItem.currency }}</p>
                  </div>
                </div>
                <div class="submit">
                  <button class="btn bp cw wd back" @click="step = 2">
                    Back
                  </button>
                  <button class="btn bp cw wd" @click="startPayment()">
                    Proceed Payment ({{ $comma(payload.total) }}
                    {{ selectedItem.currency }})
                  </button>
                </div>
              </div>
            </div>
            <div class="tab" v-if="step == 4">
              <div class="success">
                <div class="icon">
                  <i class="fa-solid fa-check"></i>
                </div>
                <div class="message">
                  <h1>Booking Successful</h1>
                  <p>
                    Your booking has been successfully made. You will receive an
                    email with your booking details and a follow up from the
                    hotel
                  </p>
                </div>
              </div>
              <div class="form-title">
                <h1>Booking Summary</h1>
              </div>
              <div class="form-section">
                <div class="booking-summary">
                  <div class="desc-section">
                    <div class="about-stay">
                      <label class="category">
                        {{ selectedItem.category }}
                        <div class="ratings">
                          <ratings :count="4"></ratings>
                        </div>
                      </label>
                      <h1>{{ selectedItem.name }}</h1>
                      <p class="location">{{ selectedItem.location }}</p>
                      <p class="amenities">
                        <span>
                          Free Wi-Fi in rooms, Free parking, 24-hour reception,
                          Shuttle service, Laundry, Dry cleaning, Ironing
                          service, Housekeeping, Grocery shopping service
                        </span>
                      </p>
                    </div>
                    <div class="booking-details">
                      <div class="section-title">
                        <h1>Booking Details</h1>
                      </div>
                      <div class="checkins">
                        <div class="checkin" v-if="payload.checkin">
                          <h4>Check-in</h4>
                          <p v-if="payload.checkin">{{ payload.checkin }}</p>
                          <span v-if="payload.checkinTime">
                            {{ payload.checkinTime }}
                          </span>
                        </div>
                        <div class="checkout" v-if="payload.checkout">
                          <h4>Check-out</h4>
                          <p v-if="payload.checkout">{{ payload.checkout }}</p>
                          <span v-if="payload.checkoutTime">
                            {{ payload.checkoutTime }}
                          </span>
                        </div>
                      </div>
                      <div class="days" v-if="payload.days">
                        <p>You will stay for {{ payload.days }} nights</p>
                      </div>
                      <div class="group-2">
                        <div class="people">
                          <h4>People</h4>
                          <p>Adults: {{ payload.adults }}</p>
                          <p>Children: {{ payload.children }}</p>
                        </div>
                        <div
                          class="addons"
                          v-if="
                            payload.addons.carRental ||
                            payload.addons.airportShuttle ||
                            payload.addons.privateParking
                          "
                        >
                          <h4>Add-ons</h4>
                          <p v-if="payload.addons.carRental">Car Rental</p>
                          <p v-if="payload.addons.airportShuttle">
                            Airport Shuttle
                          </p>
                          <p v-if="payload.addons.privateParking">
                            Private Parking
                          </p>
                        </div>
                      </div>
                      <div class="rooms" v-if="payload.rooms">
                        <h4>Rooms</h4>
                        <p v-for="room in payload.rooms" :key="room.id">
                          {{ room.name }} x {{ room.quantity }} :
                          {{ room.price * room.quantity }} {{ room.currency }}
                        </p>
                        <span>
                          Sub-Total Price:
                          {{ payload.subTotal }} {{ selectedItem.currency }}
                        </span>
                        <span class="service-charge">
                          Service Charge:
                          {{ payload.serviceRate }}%
                          {{ payload.serviceFees }}
                          {{ selectedItem.currency }}
                        </span>
                        <h5>
                          <span class="total-price"
                            >Total Price: {{ payload.total }}
                            {{ selectedItem.currency }}</span
                          >
                        </h5>
                      </div>
                      <div
                        class="special-requests"
                        v-if="payload.specialRequests"
                      >
                        <h4>Special Requests</h4>
                        <p>{{ payload.specialRequests }}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="dflex"
                    style="
                      margin-top: 20px;
                      display: flex;
                      justify-content: center;
                    "
                  >
                    <button class="btn bp cw wd">Download Invoice</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="desc-section" v-if="step < 4">
            <div class="about-stay">
              <label class="category">
                {{ selectedItem.category }}
                <div class="ratings">
                  <ratings :count="4"></ratings>
                </div>
              </label>
              <h1>{{ selectedItem.name }}</h1>
              <p class="location">{{ selectedItem.location }}</p>
              <p class="amenities">
                <span>
                  Free Wi-Fi in rooms, Free parking, 24-hour reception, Shuttle
                  service, Laundry, Dry cleaning, Ironing service, Housekeeping,
                  Grocery shopping service
                </span>
              </p>
            </div>
            <div class="booking-details">
              <div class="section-title">
                <h1>Booking Details</h1>
              </div>
              <div class="checkins">
                <div class="checkin" v-if="payload.checkin">
                  <h4>Check-in</h4>
                  <p v-if="payload.checkin">{{ payload.checkin }}</p>
                  <span v-if="payload.checkinTime">
                    {{ payload.checkinTime }}
                  </span>
                </div>
                <div class="checkout" v-if="payload.checkout">
                  <h4>Check-out</h4>
                  <p v-if="payload.checkout">{{ payload.checkout }}</p>
                  <span v-if="payload.checkoutTime">
                    {{ payload.checkoutTime }}
                  </span>
                </div>
              </div>
              <div class="days" v-if="payload.days">
                <p>You will stay for {{ payload.days }} nights</p>
              </div>
              <div class="group-2">
                <div class="people">
                  <h4>People</h4>
                  <p>Adults: {{ payload.adults }}</p>
                  <p>Children: {{ payload.children }}</p>
                </div>
                <div
                  class="addons"
                  v-if="
                    payload.addons.carRental ||
                    payload.addons.airportShuttle ||
                    payload.addons.privateParking
                  "
                >
                  <h4>Add-ons</h4>
                  <p v-if="payload.addons.carRental">Car Rental</p>
                  <p v-if="payload.addons.airportShuttle">Airport Shuttle</p>
                  <p v-if="payload.addons.privateParking">Private Parking</p>
                </div>
              </div>
              <div class="rooms" v-if="payload.rooms">
                <h4>Rooms</h4>
                <p v-for="room in payload.rooms" :key="room.id">
                  {{ room.name }} x {{ room.quantity }} :
                  {{ room.price * room.quantity }} {{ room.currency }}
                </p>
                <span>
                  Sub-Total Price:
                  {{ payload.subTotal }} {{ selectedItem.currency }}
                </span>
                <span class="service-charge">
                  Service Charge:
                  {{ payload.serviceRate }}%
                  {{ payload.serviceFees }}
                  {{ selectedItem.currency }}
                </span>
                <h5>
                  <span class="total-price"
                    >Total Price: {{ payload.total }}
                    {{ selectedItem.currency }}</span
                  >
                </h5>
              </div>
              <div class="special-requests" v-if="payload.specialRequests">
                <h4>Special Requests</h4>
                <p>{{ payload.specialRequests }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  components: {},
  data() {
    return {
      filteredItems: [],
      selectedId: null,
      selectedItem: {
        id: 1,
        name: "2000 Hotel Downtown Kigali",
        location:
          "Kn82 St /1,Near Kigali City Tower,Nyarugenge Sector,Kiyovu, Kigali, Rwanda",
        category: "Hotel",
        price: 110,
        currency: "USD",
        image: "downtown.avif",
        owner: "Cumbinn",
        description:
          "The 2000 Hotel, Kigali's highest hotel, offers mountain and city views as well as a rooftop deck. There is free WiFi throughout the hotel, as well as free private parking. A fitness center is also available. There is a flat-screen TV with satellite channels in each room. Certain rooms have a lounge space where you may unwind after a long day. Enjoy a cup of coffee or tea while looking out at the mountains or garden. Each room has a private bathroom with a bath. Bathrobes, slippers, and free toiletries are available as extras. Bamboo Restaurant serves both Chinese and Western fare. In the bamboo bar, guests can chose from a broad selection of wines. The 2000 Hotel Kigali has an on-site fitness center, as well as massage services. For the convenience of guests, a business center is available. The 24-hour reception is available to assist guests with any questions they may have in English, French, or Chinese. On the ground floor, there is a shopping mall and a grocery. This hotel is a 2-minute walk from the Central Business District and Kigali City Tower. A 5-minute stroll will take you to the Tourist Information Center. The Genocide Memorial is 3 kilometers distant, while the Kigali Convention Center is 7 kilometers away. The 2000 Hotel Downtown Kigali is 11 kilometers from Kigali International Airport. The hotel provides airport transfer service.",
        schedule: "Check-in from 14:00-23:59  - Check-out from 11:00-12:00",
        rooms: [
          {
            id: 1,
            name: "Superior Twin Room",
            price: 130,
            currency: "USD",
            description: "Superior Twin Room",
            payment: "Full payment required",
          },
          {
            id: 2,
            name: "Deluxe King Room Single",
            price: 110,
            currency: "USD",
            description: "Deluxe King Room Single",
            payment: "No prepayment needed",
          },
          {
            id: 3,
            name: "Executive Suite",
            price: 153,
            currency: "USD",
            description: "Executive Suite",
            payment: "50% prepayment needed",
          },
          {
            id: 4,
            name: "Superior Twin Suite",
            price: 161,
            currency: "USD",
            description: "Superior Twin Suite",
            payment: "Full payment required",
          },
          {
            id: 5,
            name: "Deluxe King Room Double",
            price: 132,
            currency: "USD",
            description: "Deluxe King Room Double",
            payment: "No prepayment needed",
          },
        ],
        features: [
          "Free Wi-Fi in rooms",
          "Free parking",
          "24-hour reception",
          "Non-smoking rooms",
          "Business center",
          "Fitness center",
          "Spa and wellness center",
          "Bar/ Lounge area",
          "Restaurant",
          "Shuttle service",
          "Laundry",
          "Dry cleaning",
          "Ironing service",
          "Shops/Commercial services",
          "Housekeeping",
          "Car rental",
          "Grocery shopping service",
        ],
        rules: [
          "Pets are not allowed",
          "For your safety to be their top priority, this facility has implemented additional health and hygiene precautions.",
          "Considering your dates are far off, go with free cancellation to maintain your flexibility. With free cancellation, changing your mind about your plans is never a problem!",
          "This establishment has made major efforts to make your stay more environmentally friendly.",
        ],
      },
      payload: {
        days: null,
        adults: 1,
        children: 0,
        checkin: null,
        checkout: null,
        rooms: null,
        checkinTime: null,
        checkoutTime: null,
        addons: {
          carRental: false,
          airportShuttle: false,
          privateParking: false,
        },
        specialRequests: null,
        userInfo: {
          firstName: null,
          lastName: null,
          email: null,
          phone: null,
          country: null,
          city: null,
          address: null,
          postalCode: null,
        },
        serviceRate: 5,
        serviceFees: 0,
        subTotal: 0,
        total: 0,
        payment_type: "Card",
        card: {
          number: null,
          month: null,
          year: null,
          cvv: null,
        },
        consents: {
          terms: false,
          privacy: false,
          marketing: false,
        },
        payment_phone: null,
      },
      step: 1,
    };
  },
  methods: {
    addRoom(room) {
      let newRoom = {
        id: room.id,
        name: room.name,
        price: room.price,
        currency: room.currency,
        quantity: 1,
        payment: room.payment,
      };
      if (this.payload.rooms === null) {
        this.payload.rooms = [newRoom];
      } else {
        let index = this.payload.rooms.findIndex((r) => r.id === room.id);
        if (index === -1) {
          this.payload.rooms.push(newRoom);
        } else {
          this.payload.rooms.splice(index, 1);
        }
      }
      this.getTotals();
    },
    countDays() {
      if (this.payload.checkin && this.payload.checkout) {
        if (this.payload.checkin > this.payload.checkout) {
          toast.error("Check-in date cannot be greater than check-out date");
        } else {
          let checkin = new Date(this.payload.checkin);
          let checkout = new Date(this.payload.checkout);
          let diff = checkout - checkin;
          let days = diff / (1000 * 60 * 60 * 24);
          this.payload.days = days;
        }
      }
    },
    getTotals() {
      // if there is a room with count under 1, remove it
      if (this.payload.rooms) {
        this.payload.rooms = this.payload.rooms.filter((r) => r.quantity > 0);
      }
      if (this.payload.rooms) {
        let subTotal =
          this.payload.rooms.reduce((a, b) => a + b.price * b.quantity, 0) *
          this.payload.days;
        this.payload.subTotal = subTotal;
        this.payload.serviceFees = (subTotal * this.payload.serviceRate) / 100;
        this.payload.total = subTotal + this.payload.serviceFees;
      }
    },
    startPayment() {
      this.$startLoader();
      setTimeout(() => {
        console.log("paid");
        this.$stopLoader();
        toast.success("Payment Successful");
        this.step = 4;
      }, 3000);
    },
  },
  mounted() {
    this.selectedId = this.$route.params.id;
    // toast.success("Wow so easy!");
    // toast.success("Hello", {
    //   autoClose: 3000,
    // });
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Properties {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  .booking-section {
    margin-top: 150px;
    .steppers {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .step {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
        cursor: pointer;
        .circle {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: #f2f2f2;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          font-weight: 500;
          color: #000;
        }
        p {
          font-size: 14px;
          font-weight: 500;
          color: #000;
          white-space: nowrap;
          margin-left: 10px;
        }
        &.active {
          .circle {
            background-color: $primary_color;
            color: #fff;
          }
          p {
            color: $primary_color;
          }
        }
        &:hover {
          .circle {
            background-color: $primary_color;
            color: #fff;
          }
          p {
            color: $primary_color;
          }
        }
      }
      hr {
        width: 100%;
        border: none;
        border-top: 1px solid #f2f2f2;
        &.active {
          border-top: 1px solid $primary_color;
        }
      }
    }
    .tabs-sections {
      display: grid;
      grid-template-columns: 4fr 1fr;
      margin: 2rem 0;
      gap: 1rem;
      &.active {
        grid-template-columns: 1fr;
      }
      .tabs {
        border: 1px solid #f2f2f2;
        padding: 20px;
        border-radius: 10px;
        hr {
          margin: 20px 0;
          border: none;
          border-top: 1px solid #f2f2f2;
        }
        .tab {
          .form-title {
            display: flex;
            justify-content: space-between;
            h1 {
              font-size: 1.5rem;
              font-weight: 700;
              color: $primary_color;
              margin: 10px 0;
            }
          }
          .form-section {
            .rooms {
              .rooms-list {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 10px;
                .room {
                  border: 1px solid #f2f2f2;
                  padding: 20px;
                  border-radius: 10px;
                  cursor: pointer;
                  .room-name {
                    p {
                      font-size: 12px;
                      font-weight: 600;
                      color: $primary_color;
                    }
                  }
                  .room-price {
                    p {
                      font-size: 16px;
                      font-weight: 500;
                      color: #000;
                    }
                  }
                  .payment {
                    p {
                      font-size: 12px;
                      font-weight: 400;
                      color: #02a70b;
                    }
                  }
                  .check {
                    i {
                      font-size: 20px;
                      color: $primary_color;
                    }
                  }
                  &.active {
                    background-color: #f2f2f2;
                  }
                }
              }
            }
            .form-group {
              margin: 20px 0;
              .checkboxes {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .checkbox {
                  margin: 10px 0;
                  display: flex;
                  position: relative;
                  input {
                    margin-right: 10px;
                    width: 20px;
                  }
                  label {
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                    cursor: pointer;
                    position: relative;
                  }
                  span {
                    font-size: 12px;
                    font-weight: 400;
                    color: #555;
                    display: block;
                    margin-top: 5px;
                    position: absolute;
                    bottom: -5px;
                    left: 1.8rem;
                  }
                }
              }
              label {
                font-size: 14px;
                font-weight: 500;
                color: #000;
                margin-bottom: 10px;
                display: block;
                span {
                  font-size: 12px;
                  font-weight: 400;
                  color: #000;
                }
              }
              input,
              select,
              textarea {
                width: 100%;
                padding: 10px;
                border: 1px solid #f2f2f2;
                border-radius: 5px;
                outline: none;
              }
            }
            .consents {
              display: flex;
              flex-direction: column;
              gap: 1rem;
              .checkbox {
                display: flex;
                position: relative;
                input {
                  margin-right: 10px;
                  width: 20px;
                }
                label {
                  font-size: 14px;
                  font-weight: 500;
                  color: #000;
                  cursor: pointer;
                  position: relative;
                  margin-bottom: 0;
                }
              }
            }
            .submit {
              display: flex;
              justify-content: space-between;
              align-items: center;
              button {
                padding: 10px 40px;
                border: none;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 500;
                color: #fff;
                cursor: pointer;
                background-color: $primary_color;
                &:hover {
                  background-color: darken($primary_color, 10%);
                }
                &.back {
                  background-color: #333;
                  color: #fff;
                  &:hover {
                    background-color: darken(#333, 10%);
                  }
                }
              }
            }
          }
          .success {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 2rem;
            .icon {
              i {
                font-size: 50px;
                color: #419b41;
              }
            }
            .message {
              h1 {
                font-size: 1.5rem;
                font-weight: 700;
                color: #419b41;
                margin: 0 0;
              }
              p {
                font-size: 14px;
                font-weight: 400;
                color: #000;
              }
            }
          }
        }
      }
      .desc-section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .about-stay {
          border: 1px solid #f2f2f2;
          padding: 20px;
          border-radius: 10px;
          .category {
            font-size: 14px;
            font-weight: 500;
            color: #000;
            display: flex;
            align-items: center;
            .ratings {
              margin-left: 5px;
            }
          }
          h1 {
            font-size: 1rem;
            font-weight: 700;
            color: $primary_color;
            margin: 10px 0;
          }
          .location {
            font-size: 12px;
            font-weight: 500;
            color: #000;
            margin: 10px 0;
          }
          .amenities {
            font-size: 12px;
            font-weight: 400;
            color: #000;
            margin: 10px 0;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            span {
              display: block;
              margin: 10px 0;
            }
          }
        }
        .booking-details {
          border: 1px solid #f2f2f2;
          padding: 20px;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          .section-title {
            h1 {
              font-size: 1rem;
              font-weight: 700;
              color: $primary_color;
            }
          }
          .checkins {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .checkin {
              h4 {
                font-size: 14px;
                font-weight: 500;
                color: #000;
              }
              p {
                font-size: 14px;
                font-weight: 400;
                color: #000;
              }
              span {
                font-size: 12px;
                font-weight: 400;
                color: #000;
              }
            }
            .checkout {
              h4 {
                font-size: 14px;
                font-weight: 500;
                color: #000;
              }
              p {
                font-size: 14px;
                font-weight: 400;
                color: #000;
              }
              span {
                font-size: 12px;
                font-weight: 400;
                color: #000;
              }
            }
          }
          .days {
            h4 {
              font-size: 14px;
              font-weight: 500;
              color: #000;
            }
            p {
              font-size: 14px;
              font-weight: 400;
              color: #000;
            }
          }
          .rooms {
            h4 {
              font-size: 14px;
              font-weight: 500;
              color: #000;
            }
            p {
              font-size: 14px;
              font-weight: 400;
              color: #000;
            }
            span {
              font-size: 12px;
              font-weight: 400;
              color: #000;
              display: block;
              margin: 10px 0;
              &.total-price {
                font-size: 16px;
                font-weight: 500;
                color: $primary_color;
              }
            }
            h5 {
              font-size: 16px;
              font-weight: 500;
              color: $primary_color;
              margin: 10px 0;
              span {
                font-size: 14px;
                font-weight: 400;
                color: #000;
                margin: 10px 0;
              }
            }
          }
          .people {
            h4 {
              font-size: 14px;
              font-weight: 500;
              color: #000;
            }
            p {
              font-size: 14px;
              font-weight: 400;
              color: #000;
            }
          }
          .addons {
            h4 {
              font-size: 14px;
              font-weight: 500;
              color: #000;
            }
            p {
              font-size: 14px;
              font-weight: 400;
              color: #000;
            }
          }
          .special-requests {
            h4 {
              font-size: 14px;
              font-weight: 500;
              color: #000;
            }
            p {
              font-size: 14px;
              font-weight: 400;
              color: #000;
            }
          }
        }
      }
    }
    .amounts {
      margin-bottom: 2rem;
    }
  }
}
</style>
