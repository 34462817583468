<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span>Stays</span
        ><button class="my-btn cw glow wd" @click="startAdd = true">
          Add Stay
        </button>
      </h1>

      <div class="my-table">
        <table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Title</th>
              <th>Location</th>
              <th>Category</th>
              <th>Price</th>
              <th>Rating</th>
              <th>Added by</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in accomodations" :key="item.id">
              <td>
                <img :src="$upload(item.image)" alt="Location Image" />
              </td>
              <td>{{ item.title }}</td>
              <td v-if="item.location">{{ item.location.name }}</td>
              <td v-else>Not set</td>
              <td v-if="item.category">{{ item.category.name }}</td>
              <td v-else>Not set</td>
              <td>{{ $comma(item.price) }} {{ item.currency }}</td>
              <td>{{ item.rating }}</td>
              <td v-if="item.created_by">{{ item.created_by.name }}</td>
              <td v-else>Not set</td>
              <td>
                <router-link
                  :to="{
                    name: 'AdminRooms',
                    params: { id: item.accomodation_id },
                  }"
                  @click="
                    selectedItem = item;
                    startEdit = true;
                  "
                  class="btn default"
                >
                  Rooms
                </router-link>
                <button
                  @click="
                    selectedItem = item;
                    startEdit = true;
                  "
                  class="btn edit"
                >
                  Edit
                </button>
                <button
                  @click="
                    selectedItem = item;
                    startDelete = true;
                  "
                  class="btn delete"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>
      <Model
        v-if="startAdd"
        @closeModel="startAdd = false"
        :modelTitle="'Add Accomodation'"
        :size="'md'"
      >
        <div class="model-body">
          <div class="form-group group-2">
            <div class="input-group">
              <label for="title">Title</label>
              <input
                type="text"
                v-model="newItem.title"
                placeholder="Title"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="location">Location</label>
              <select v-model="newItem.location_id" class="form-control">
                <option value="" disabled selected>Select Location</option>
                <option
                  v-for="location in locations"
                  :key="location.location_id"
                  :value="location.location_id"
                >
                  {{ location.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group group-2">
            <div class="input-group">
              <label for="Category">Category</label>
              <select v-model="newItem.category_id" class="form-control">
                <option value="">Select category</option>
                <option
                  v-for="category in categories"
                  :key="category.id"
                  :value="category.category_id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
            <div class="input-group">
              <label for="price">Price</label>
              <div class="control-group price flex">
                <select
                  name=""
                  id=""
                  v-model="newItem.currency"
                  class="form-control"
                >
                  <option value="USD">USD</option>
                  <option value="RWF">RWF</option>
                </select>
                <input
                  type="number"
                  v-model="newItem.price"
                  placeholder="Price"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="form-group group-2">
            <!-- address -->
            <div class="input-group">
              <label for="map">Address</label>
              <input
                type="text"
                v-model="newItem.address"
                placeholder="Address"
                class="form-control"
              />
            </div>
            <!-- rating -->
            <div class="input-group">
              <label for="rating">Stars</label>
              <select
                name=""
                id=""
                v-model="newItem.rating"
                class="form-control"
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>
          <!-- Favourable -->
          <div class="form-group">
            <div class="input-group">
              <label for="features">Favourable for</label>
              <a-tree-select
                v-model:value="newItem.favourable"
                style="width: 100%"
                :tree-data="treeData"
                tree-checkable
                allow-clear
                placeholder="Please select"
                tree-node-filter-prop="label"
                size="large"
              />
            </div>
          </div>
          <!-- features -->
          <div class="form-group">
            <div class="input-group">
              <label for="features">Features</label>
              <v-combobox
                v-model="newItem.features"
                label="Accomodation features"
                variant="solo"
                chips
                clearable
                multiple
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :model-value="selected"
                    closable
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>{{ item }}</strong
                    >&nbsp;
                    <span>(interest)</span>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </div>
          <!-- facilities -->
          <div class="form-group">
            <div class="input-group">
              <label for="facilities">Facilities</label>
              <v-combobox
                v-model="newItem.facilities"
                label="Facilities"
                variant="solo"
                chips
                clearable
                multiple
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :model-value="selected"
                    closable
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>{{ item }}</strong
                    >&nbsp;
                    <span>(interest)</span>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </div>
          <!-- amenities -->
          <div class="form-group">
            <div class="input-group">
              <label for="amenities">Amenities</label>
              <v-combobox
                v-model="newItem.amenities"
                label="Amenities"
                variant="solo"
                chips
                clearable
                multiple
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :model-value="selected"
                    closable
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>{{ item }}</strong
                    >&nbsp;
                    <span>(interest)</span>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <textarea
              v-model="newItem.description"
              placeholder="Description"
              class="form-control"
            ></textarea>
          </div>
          <div class="form-group">
            <label for="image">Main Image</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  newItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="newItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="form-group">
            <label for="images">Other Images</label>
            <o-upload
              :multiple="true"
              @imagesUploaded="
                (data) => {
                  newItem.images = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="newItem.images"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="form-group">
            <label for="Other info">Other info</label>
            <textarea
              v-model="newItem.otherInfo"
              placeholder="Other information"
              class="form-control"
            ></textarea>
          </div>
          <div class="btns flex">
            <button @click="startAdd = false" class="my-btn cw wd cancel">
              Cancel
            </button>
            <button @click="createItem" class="btn cw glow wd">
              Add Accomodation
            </button>
          </div>
        </div>
      </Model>
      <Model
        v-if="startEdit"
        @closeModel="startEdit = false"
        :modelTitle="'Edit Accomodation'"
        :size="'md'"
      >
        <div class="model-body">
          <div class="form-group group-2">
            <div class="input-group">
              <label for="title">Title</label>
              <input
                type="text"
                v-model="selectedItem.title"
                placeholder="Title"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="location">Location</label>
              <select v-model="selectedItem.location_id" class="form-control">
                <option value="" disabled selected>Select Location</option>
                <option
                  v-for="location in locations"
                  :key="location.location_id"
                  :value="location.location_id"
                >
                  {{ location.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group group-2">
            <div class="input-group">
              <label for="Category">Category</label>
              <select v-model="selectedItem.category_id" class="form-control">
                <option value="">Select category</option>
                <option
                  v-for="category in categories"
                  :key="category.id"
                  :value="category.category_id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
            <div class="input-group">
              <label for="price">Price</label>
              <div class="control-group price flex">
                <select
                  name=""
                  id=""
                  v-model="selectedItem.currency"
                  class="form-control"
                >
                  <option value="USD">USD</option>
                  <option value="RWF">RWF</option>
                </select>
                <input
                  type="number"
                  v-model="selectedItem.price"
                  placeholder="Price"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="form-group group-2">
            <!-- address -->
            <div class="input-group">
              <label for="map">Address</label>
              <input
                type="text"
                v-model="selectedItem.address"
                placeholder="Address"
                class="form-control"
              />
            </div>
            <!-- rating -->
            <div class="input-group">
              <label for="rating">Rating</label>
              <select
                name=""
                id=""
                v-model="selectedItem.rating"
                class="form-control"
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>
          <!-- Favourable -->
          <div class="form-group">
            <div class="input-group">
              <label for="features">Favourable for</label>
              <a-tree-select
                v-model:value="selectedItem.favourable"
                style="width: 100%"
                :tree-data="treeData"
                tree-checkable
                allow-clear
                placeholder="Please select"
                tree-node-filter-prop="label"
                size="large"
              />
            </div>
          </div>
          <!-- features -->
          <div class="form-group">
            <div class="input-group">
              <label for="features">Features</label>
              <v-combobox
                v-model="selectedItem.features"
                label="Accomodation features"
                variant="solo"
                chips
                clearable
                multiple
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :model-value="selected"
                    closable
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>{{ item }}</strong
                    >&nbsp;
                    <span>(interest)</span>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </div>
          <!-- facilities -->

          <div class="form-group">
            <div class="input-group">
              <label for="features">Facilities</label>
              <v-combobox
                v-model="selectedItem.facilities"
                label="Facilities"
                variant="solo"
                chips
                clearable
                multiple
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :model-value="selected"
                    closable
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>{{ item }}</strong
                    >&nbsp;
                    <span>(interest)</span>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </div>
          <!-- amenities -->
          <div class="form-group">
            <div class="input-group">
              <label for="features">Amenities</label>
              <v-combobox
                v-model="selectedItem.amenities"
                label="Amenities"
                variant="solo"
                chips
                clearable
                multiple
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :model-value="selected"
                    closable
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>{{ item }}</strong
                    >&nbsp;
                    <span>(interest)</span>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <textarea
              v-model="selectedItem.description"
              placeholder="Description"
              class="form-control"
            ></textarea>
          </div>
          <div class="form-group">
            <label for="image">Main Image</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  selectedItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="selectedItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="form-group">
            <label for="images">Other Images</label>
            <o-upload
              :multiple="true"
              @imagesUploaded="
                (data) => {
                  selectedItem.images = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="selectedItem.images"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="form-group">
            <label for="Other info">Other info</label>
            <textarea
              v-model="selectedItem.otherInfo"
              placeholder="Other information"
              class="form-control"
            ></textarea>
          </div>
          <div class="btns flex">
            <button @click="startEdit = false" class="my-btn cw wd cancel">
              Cancel
            </button>
            <button @click="updateItem" class="btn cw glow wd">
              Update Accomodation
            </button>
          </div>
        </div>
      </Model>
      <confirmModel
        v-if="startDelete"
        @closeModel="startDelete = false"
        :modelTitle="'Delete Accomodation'"
        :size="'sm'"
        :displayText="'Are you sure you want to delete this Accomodation?'"
        @confirmModel="deleteItem"
      />
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      locations: [],
      newItem: {
        location_id: "",
        title: "",
        image: "",
        category_id: "",
        images: [],
        price: 1,
        currency: "USD",
        features: [],
        facilities: [],
        amenities: [],
        description: "",
        address: "",
        otherInfo: "",
        rating: 4,
        favourable: [],
      },
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      allItemsCount: 0,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      accomodations: [],
      categories: [],
      featuresItems: [
        "Free toiletries",
        "Shower",
        "Bathrobe",
        "Safety deposit box",
        "Toilet",
        "Sofa",
        "Hardwood or parquet floors",
        "Towels",
        "Socket near the bed",
        "Tile/marble floor",
        "Desk",
        "Seating Area",
        "TV",
        "Slippers",
        "Refrigerator",
        "Telephone",
        "Ironing facilities",
        "Satellite channels",
      ],
      treeData: [
        {
          label: "Family Tour",
          value: "Family Tour",
        },
        {
          label: "Group Tour",
          value: "Group Tour",
        },
        {
          label: "Honeymoon Tour",
          value: "Honeymoon Tour",
        },
        {
          label: "Adventure Tour",
          value: "Adventure Tour",
        },
        {
          label: "Solo Tour",
          value: "Solo Tour",
        },
      ],
    };
  },
  methods: {
    getLocations() {
      this.$store
        .dispatch("GET_LOCATIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.locations = res.data.locations.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getCategories() {
      this.$store
        .dispatch("GET_CATEGORIES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 100,
            page: 1,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.categories = res.data.categories.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_ACCOMODATIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              if (res.data.accomodations) {
                this.accomodations = res.data.accomodations.data;
                this.allItemsCount = res.data.accomodations.total;
                this.pagesNumber = Math.ceil(
                  this.allItemsCount / this.itemsPerpage
                );
              }
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    createItem() {
      if (this.newItem.title) {
        this.$startLoader();
        this.$store
          .dispatch("CREATE_ACCOMODATION", {
            data: this.newItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateItem() {
      if (this.selectedItem.title) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_ACCOMODATION", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startEdit = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_ACCOMODATION", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
    this.getLocations();
    this.getCategories();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 1.5rem;
      font-weight: 600;
      b {
        color: $primary_color;
        font-weight: 600;
      }
    }
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .my-table {
      table {
        width: 100%;
        border-collapse: collapse;
        thead {
          th {
            text-align: left;
            padding: 1rem 0;
            font-weight: 600;
            border-bottom: 1px solid #e5e8ee;
          }
        }
        tbody {
          tr {
            td {
              padding: 5px;
              border-bottom: 1px solid #e5e8ee;
              img {
                width: 100px;
                height: 50px;
                object-fit: cover;
                border-radius: 5px;
              }
              button {
                margin-right: 10px;
                &.edit {
                  background-color: #1d2088;
                  color: white;
                  &:hover {
                    background-color: #1eb9ee;
                  }
                }
                &.delete {
                  background-color: #a82929;
                  color: white;
                  &:hover {
                    background-color: #7a1f1f;
                  }
                }
                &.default {
                  background-color: #1d2088;
                  color: white;
                  &:hover {
                    background-color: #1eb9ee;
                  }
                }
              }
            }
          }
        }
      }
    }
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
        &.cancel {
          background-color: #a82929;
          color: white;
          &:hover {
            background-color: #7a1f1f;
          }
        }
      }
    }
  }
}

.form-group {
  column-gap: 10px;
}
.control-group {
  display: flex;
  align-items: center;
  select {
    padding: 0.5rem;
    width: 100px;
    text-align: center;
    border-radius: 5px 0px 0px 5px;
    background: #eee;
  }
  input {
    padding: 0.5rem;
    border-radius: 0px 5px 5px 0px;
  }
}
</style>