import { BACKEND } from "../variables.js";
import axios from "axios";
const GET_ALL_RESERVATIONS = BACKEND + "/reservation/getAllReservations";
const CREATE_RESERVATION = BACKEND + "/reservation/createReservation";
const UPDATE_RESERVATION = BACKEND + "/reservation/updateReservation";
const DELETE_RESERVATION = BACKEND + "/reservation/deleteReservation";
const GET_RESERVATION = BACKEND + "/reservation/getReservation";
const GET_RESERVATION_BY_USER = BACKEND + "/reservation/getReservationsByUser";
const RESERVATION_COMPLETED = BACKEND + "/reservation/reservationComplete";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_ALL_RESERVATIONS"](state, payload) {
      return axios({
        method: "POST",
        url: GET_ALL_RESERVATIONS + `?page=${payload.data.page}`,
        data: payload.data,
      });
    },
    ["CREATE_RESERVATION"](state, payload) {
      return axios({
        method: "POST",
        url: CREATE_RESERVATION,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_RESERVATION"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_RESERVATION,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_RESERVATION"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_RESERVATION,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_RESERVATION"](state, payload) {
      return axios({
        method: "POST",
        url: GET_RESERVATION,
        data: payload.data,
      });
    },
    ["GET_RESERVATION_BY_USER"](state, payload) {
      return axios({
        method: "POST",
        url: GET_RESERVATION_BY_USER,
        data: payload.data,
      });
    },
    ["RESERVATION_COMPLETED"](state, payload) {
      return axios({
        method: "POST",
        url: RESERVATION_COMPLETED,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
};
