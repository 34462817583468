<template>
  <div id="TopBar">
    <div class="top-bar">
      <div class="left">
        <a
          :href="`${$site().site_facebook}`"
          target="_blank"
          rel="noopener noreferrer"
          v-if="$site().site_facebook"
        >
          <i class="fab fa-facebook-f"></i>
        </a>
        <a
          :href="`${$site().site_twitter}`"
          target="_blank"
          rel="noopener noreferrer"
          v-if="$site().site_twitter"
        >
          <i class="fab fa-x-twitter"></i>
        </a>
        <a
          :href="`${$site().site_instagram}`"
          target="_blank"
          rel="noopener noreferrer"
          v-if="$site().site_instagram"
        >
          <i class="fab fa-instagram"></i>
        </a>
      </div>
      <div class="right">
        <a href="tel:+250 782 251 852">
          <i class="fas fa-phone-alt"></i> {{ $site().site_phone }}
        </a>
        <a href="mailto:support@cumbinn.com">
          <i class="fas fa-envelope"></i> {{ $site().site_email }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss">
$primary_color: #0c3b7c;
$secondary_color: #00bafc;
#TopBar {
  .left {
    @media (max-width: 900px) {
      display: none;
    }
  }
  .top-bar {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px 20px;
    background-color: darken($color: $primary_color, $amount: 10%);
    color: #fff;
    @media screen and (max-width: 1700px) {
      padding: 0px 10px;
    }
    @media screen and (max-width: 1300px) {
      padding: 0px 5px;
    }
    @media screen and (max-width: 900px) {
      padding: 0px 3px;
    }
    @media screen and (max-width: 600px) {
      padding: 0px 2px;
    }
    a {
      color: #fff;
      text-decoration: none;
      margin: 0 10px;
      font-size: 12px;
      @media screen and (max-width: 1700px) {
        font-size: 10px;
      }
      i {
        margin-right: 5px;
        @media screen and (max-width: 1700px) {
          font-size: 10px;
        }
      }
    }
  }
}
</style>