<template>
  <div class="book-room model">
    <div class="model-dialog" :class="{ active: active }">
      <div class="model-content">
        <div class="model-header">
          <h3>
            Reserve <span>{{ accomodation.title }}</span>
          </h3>
          <button @click="$emit('close')" class="close">X</button>
        </div>
        <div class="model-body">
          <div class="form-section" v-if="activetab == 1">
            <!-- rooms -->
            <div class="form-group">
              <div class="input-group">
                <label for="rooms">Select Room</label>
                <a-select
                  placeholder="Select Room"
                  style="width: 100%"
                  @change="addRoom()"
                  v-model:value="selectedRoom"
                >
                  <a-select-option
                    v-for="room in accomodation.rooms"
                    :key="room.room_id"
                    :value="room.room_id"
                    >{{ room.name }} - {{ room.price }}
                    {{ room.currency }}</a-select-option
                  >
                </a-select>
              </div>
              <div class="room-inputs">
                <div
                  class="form-group"
                  v-for="room in payload.rooms"
                  :key="room.id"
                >
                  <label for="room-{{ room.id }}">Number of rooms</label>
                  <input
                    type="number"
                    id="room-{{ room.id }}"
                    v-model="payload.room_count"
                    @change="getTotals()"
                    min="1"
                  />
                </div>
              </div>
            </div>
            <div class="form-group group-3">
              <div class="input-group">
                <label for="check-in">Check-in</label>
                <a-date-picker
                  v-model:value="payload.check_in"
                  @change="
                    countDays();
                    getTotals();
                  "
                  :format="dateFormatList"
                  placeholder="Checkin date"
                  :bordered="true"
                />
              </div>
              <div class="input-group">
                <label for="check-out">Check-out</label>
                <a-date-picker
                  v-model:value="payload.check_out"
                  @change="
                    countDays();
                    getTotals();
                  "
                  :format="dateFormatList"
                  placeholder="Checkout date"
                />
              </div>
              <div class="input-group">
                <label for="check-out">Nights</label>
                <a-input v-model:value="payload.days" />
              </div>
            </div>
            <div class="form-group group-2">
              <div class="input-group">
                <!-- <label for="check-out">People</label> -->
                <div class="people-group">
                  <label for="adults">Adults</label>
                  <div class="number-range">
                    <i
                      class="fa-solid fa-minus left"
                      @click="
                        payload.adults > 1
                          ? (payload.adults = payload.adults - 1)
                          : (payload.adults = 1)
                      "
                    ></i>
                    <input
                      type="number"
                      name=""
                      id=""
                      v-model="payload.adults"
                    />
                    <i
                      class="fa-solid fa-plus right"
                      @click="payload.adults = payload.adults + 1"
                    ></i>
                  </div>
                </div>
              </div>
              <div class="input-group">
                <div class="people-group">
                  <label for="children">Children</label>
                  <div class="number-range">
                    <i
                      class="fa-solid fa-minus left"
                      @click="
                        payload.children > 0
                          ? (payload.children = payload.children - 1)
                          : (payload.children = 0)
                      "
                    ></i>
                    <input
                      type="number"
                      name=""
                      id=""
                      v-model="payload.children"
                    />
                    <i
                      class="fa-solid fa-plus right"
                      @click="payload.children = payload.children + 1"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="submit">
              <button
                class="btn bp cw wd"
                @click="activetab = 2"
                :disabled="
                  !payload.rooms || !payload.check_in || !payload.check_out
                "
              >
                Next
              </button>
            </div>
          </div>
          <div class="form-section" v-if="activetab == 2">
            <div class="form-group">
              <label for="names">Names</label>
              <input
                type="text"
                id="names"
                v-model="payload.names"
                placeholder="Enter your names"
              />
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="email"
                id="email"
                v-model="payload.email"
                placeholder="Enter your email"
              />
            </div>
            <div class="form-group">
              <label for="phone">Phone</label>
              <input
                type="tel"
                id="phone"
                v-model="payload.phone"
                placeholder="Enter your phone number"
              />
            </div>

            <div class="form-group">
              <label for="payment_mode">Payment Mode</label>
              <a-select
                v-model="payload.payment_mode"
                style="width: 100%"
                placeholder="Select Payment Mode"
              >
                <a-select-option value="card">Card</a-select-option>
                <a-select-option value="mobile">Mobile Money</a-select-option>
              </a-select>
            </div>

            <div class="consents">
              <div class="checkbox">
                <input type="checkbox" id="terms" />
                <label for="terms">
                  I agree to the <span>terms and conditions</span>
                </label>
              </div>
              <div class="checkbox">
                <input type="checkbox" id="privacy" />
                <label for="privacy">
                  I agree to the <span>privacy policy</span>
                </label>
              </div>
            </div>
            <div class="submit" style="margin-top: 20px">
              <button class="btn bp cw wd back" @click="activetab = 1">
                Back
              </button>
              <button
                class="btn bp cw wd"
                :disabled="!payload.names || !payload.email || !payload.phone"
                @click="reserveStay()"
              >
                Reserve
              </button>
            </div>
          </div>
          <div class="form-section" v-if="activetab == 3">
            <iframe
              :src="iframe_url"
              width="100%"
              height="900px"
              scrolling="yes"
              frameBorder="0"
              v-if="iframe_url"
            >
              <p>Browser unable to load iFrame</p>
            </iframe>
          </div>
        </div>
        <div class="price">
          <div class="price-details">
            <div class="price-item">
              <span>Subtotal:&nbsp;</span>
              <span>{{ payload.subTotal }} {{ accomodation.currency }}</span>
            </div>
            <div class="price-item">
              <span>Service Fees:&nbsp;</span>
              <span>{{ payload.serviceFees }} {{ accomodation.currency }}</span>
            </div>
            <div class="price-item">
              <span>Total:&nbsp;</span>
              <span class="total"
                >{{ payload.total }} {{ accomodation.currency }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  props: {
    accomodation: {
      type: Object,
      required: true,
    },
    activeRoom: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      payload: {
        rooms: null,
        room_id: null,
        room_count: null,
        check_in: null,
        check_out: null,
        days: 0,
        serviceRate: 0,
        subTotal: 0,
        serviceFees: 0,
        total_price: 0,
        checkinTime: "00:00 AM - 02:00 AM",
        checkoutTime: "00:00 AM - 02:00 AM",
        adults: 1,
        children: 0,
        names: "",
        email: "",
        phone: "",
        payment_mode: "card",
        currency: "",
        accomodation_id: null,
        rate: 0,
      },
      activetab: 1,
      selectedRoom: null,
      active: true,
      iframe_url: "",
      orderData: null,
    };
  },
  methods: {
    addRoom() {
      if (!this.selectedRoom) {
        return;
      }
      let room = this.accomodation.rooms.find(
        (r) => r.room_id == this.selectedRoom
      );
      this.payload.rooms = [];
      let newRoom = {
        room_id: room.room_id,
        name: room.name,
        price: room.price,
        currency: room.currency,
        quantity: 1,
        payment: room.payment,
      };

      this.payload.room_id = room.room_id;
      this.payload.room_count = 1;
      this.payload.currency = room.currency;
      this.payload.rooms.push(newRoom);
      this.getTotals();
    },
    countDays() {
      if (this.payload.check_in && this.payload.check_out) {
        if (this.payload.check_in > this.payload.check_out) {
          toast.error("Check-in date cannot be greater than check-out date");
          this.payload.checkout = null;
        } else {
          let check_in = new Date(this.payload.check_in);
          let check_out = new Date(this.payload.check_out);
          let diff = check_out - check_in;
          let days = diff / (1000 * 60 * 60 * 24);
          this.payload.days = days.toFixed(0);
        }
      }
    },
    getTotals() {
      // if there is a room with count under 1, remove it
      if (this.payload.rooms) {
        this.payload.rooms = this.payload.rooms.filter((r) => r.quantity > 0);
      }
      if (this.payload.rooms) {
        let subTotal =
          this.payload.rooms.reduce((a, b) => a + b.price * b.quantity, 0) *
          this.payload.days;
        this.payload.subTotal = subTotal;
        this.payload.serviceFees = (subTotal * this.payload.serviceRate) / 100;
        this.payload.total = subTotal + this.payload.serviceFees;
      }
    },
    closeModel() {
      this.active = false;
      setTimeout(() => {
        this.$emit("close");
      }, 1000);
    },

    reserveStay() {
      this.$startLoader();
      this.payload.total_price =
        this.accomodation.rooms.find((r) => r.room_id == this.selectedRoom)
          .price * this.payload.days;
      this.$store
        .dispatch("CREATE_RESERVATION", {
          data: this.payload,
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.iframe_url = res.data.iframe_url;
              this.orderData = res.data.order;
              this.activetab = 3;
              // toast.success(res.data.message);
              this.$stopLoader();
              // this.$emit("reserved");
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    if (this.activeRoom) {
      this.selectedRoom = this.activeRoom;
      this.addRoom();
    }
    this.payload.serviceRate = this.$site().service_fees;
    this.payload.accomodation_id = this.accomodation.accomodation_id;
    this.payload.rate = this.$site().service_fees;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.book-room {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: 700px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1000;
  justify-content: flex-end;
  @media (max-width: 1700px) {
    width: 600px;
  }
  @media (max-width: 768px) {
    width: 70%;
  }
  @media (max-width: 576px) {
    width: 90%;
  }
  .model-dialog {
    width: 100%;
    height: 100%;
    margin-right: -600px;
    background-color: white;
    border-radius: 5px 0px 0px 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.192);
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    transition: all 1s ease;
    &.active {
      margin-right: 0px;
    }
    .model-content {
      position: relative;
      .model-header {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
        position: relative;
        @media (max-width: 1700px) {
          padding: 5px 10px;
        }
        @media (max-width: 768px) {
          padding: 5px 10px;
        }
        @media (max-width: 576px) {
          padding: 5px 8px;
        }
        h3 {
          font-size: 1.1rem;
          @media (max-width: 1700px) {
            font-size: 12px;
          }
          @media (max-width: 768px) {
            font-size: 10px;
          }
          @media (max-width: 576px) {
            font-size: 8px;
          }
          span {
            color: $primary_color;
          }
        }
        .close {
          background-color: $primary_color;
          color: white;
          border: none;
          padding: 5px 10px;
          border-radius: 5px;
          cursor: pointer;
          @media (max-width: 1700px) {
            font-size: 12px;
            padding: 5px 10px;
            border-radius: 5px;
          }
          @media (max-width: 768px) {
            font-size: 10px;
          }
          @media (max-width: 576px) {
            font-size: 8px;
          }
        }
      }
      .model-body {
        margin-top: 20px;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 80px);
        padding: 0 20px;
        padding-bottom: 20px;
        position: relative;
        @media (max-width: 1700px) {
          margin-top: 0px;
        }
        @media (max-width: 768px) {
        }
        @media (max-width: 576px) {
        }
        .form-section {
          position: relative;
          .rooms {
            .rooms-list {
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
            }
          }
          .form-group {
            margin: 20px 0;
            .checkboxes {
              display: flex;
              flex-direction: column;
              gap: 10px;
              .checkbox {
                margin: 10px 0;
                display: flex;
                position: relative;
                input {
                  margin-right: 10px;
                  width: 20px;
                  @media (max-width: 1700px) {
                    font-size: 12px;
                  }
                  @media (max-width: 768px) {
                    font-size: 10px;
                  }
                  @media (max-width: 576px) {
                    font-size: 8px;
                  }
                }
                label {
                  font-size: 14px;
                  font-weight: 500;
                  color: #000;
                  cursor: pointer;
                  position: relative;
                  @media (max-width: 1700px) {
                    font-size: 12px;
                  }
                  @media (max-width: 768px) {
                    font-size: 10px;
                  }
                  @media (max-width: 576px) {
                    font-size: 8px;
                  }
                }
                span {
                  font-size: 12px;
                  font-weight: 400;
                  color: #555;
                  display: block;
                  margin-top: 5px;
                  position: absolute;
                  bottom: -5px;
                  left: 1.8rem;
                  @media (max-width: 1700px) {
                    font-size: 12px;
                  }
                  @media (max-width: 768px) {
                    font-size: 10px;
                  }
                  @media (max-width: 576px) {
                    font-size: 8px;
                  }
                }
              }
            }
            label {
              font-size: 14px;
              font-weight: 500;
              color: #000;
              margin-bottom: 10px;
              display: block;
              @media (max-width: 1700px) {
                font-size: 12px;
              }
              @media (max-width: 768px) {
                font-size: 10px;
              }
              @media (max-width: 576px) {
                font-size: 8px;
              }
              span {
                font-size: 12px;
                font-weight: 400;
                color: #000;
                @media (max-width: 1700px) {
                  font-size: 12px;
                }
                @media (max-width: 768px) {
                  font-size: 10px;
                }
                @media (max-width: 576px) {
                  font-size: 8px;
                }
              }
            }
            input,
            select,
            textarea {
              width: 100%;
              padding: 10px;
              border: 1px solid #f2f2f2;
              border-radius: 5px;
              outline: none;
              @media (max-width: 1700px) {
                font-size: 12px;
              }
              @media (max-width: 768px) {
                font-size: 10px;
              }
              @media (max-width: 576px) {
                font-size: 8px;
              }
            }
          }
          .consents {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .checkbox {
              display: flex;
              position: relative;
              input {
                margin-right: 10px;
                width: 20px;
              }
              label {
                font-size: 14px;
                font-weight: 500;
                color: #000;
                cursor: pointer;
                position: relative;
                margin-bottom: 0;
              }
            }
          }
          .submit {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 5rem;
            button {
              padding: 10px 40px;
              border: none;
              border-radius: 5px;
              font-size: 14px;
              font-weight: 500;
              color: #fff;
              cursor: pointer;
              background-color: $primary_color;
              &:hover {
                background-color: darken($primary_color, 10%);
              }
              &.back {
                background-color: #333;
                color: #fff;
                &:hover {
                  background-color: darken(#333, 10%);
                }
              }
            }
          }
        }
      }
      .price {
        position: fixed;
        bottom: 0;
        right: 0;
        width: 500px;
        background-color: #fff;
        padding: 20px;
        border-top: 1px solid #ccc;
        @media (max-width: 1700px) {
          width: 400px;
          padding: 10px 20px;
        }
        @media (max-width: 768px) {
          width: 70%;
        }
        @media (max-width: 576px) {
          width: 90%;
        }
        .price-details {
          display: flex;
          justify-content: space-between;
          .price-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            span {
              font-size: 14px;
              font-weight: 600;
              color: #000;
              @media (max-width: 1700px) {
                font-size: 12px;
              }
              @media (max-width: 768px) {
                font-size: 10px;
              }
              @media (max-width: 576px) {
                font-size: 8px;
              }
              &.total {
                color: $primary_color;
                font-size: 1.2rem;
                font-weight: 500;
                @media (max-width: 1700px) {
                  font-size: 12px;
                }
                @media (max-width: 768px) {
                  font-size: 10px;
                }
                @media (max-width: 576px) {
                  font-size: 8px;
                }
              }
              &:first-child {
                font-weight: 300;
              }
            }
          }
        }
      }
    }
  }
}
</style>