<template>
  <div class="contact">
    <NavBar />
    <div class="jumbotron">
      <div class="left">
        <h1>Get in touch</h1>
        <p>
          We are here to help you. If you have any questions or need assistance,
          feel free to contact us.
        </p>
      </div>
      <div class="right">
        <img src="/img/contact.png" alt="contact" />
      </div>
    </div>
    <div class="contact-section">
      <div class="left">
        <i class="fa-solid fa-headset"></i>
        <div class="title">
          <h1>Contact Cumbinn Support Team</h1>
          <p>
            We are here to help you. If you have any questions or need
            assistance, feel free to contact us.
          </p>
        </div>
        <div class="contact-item">
          <h3>CONTACT PHONE</h3>
          <div class="text">
            <div class="icon">
              <i class="fas fa-phone-alt"></i>
            </div>
            <p>+250 782 251 852</p>
          </div>
        </div>
        <div class="contact-item">
          <h3>CONTACT EMAIL</h3>
          <div class="text">
            <div class="icon">
              <i class="fas fa-envelope"></i>
            </div>
            <p>support@cumbinn.com</p>
          </div>
        </div>
        <div class="contact-item">
          <h3>FROM ANY ISSUE, ANYWHERE</h3>
          <div class="text">
            <p>
              Fill out the form and we will get back to you as soon as possible
            </p>
          </div>
        </div>
        <div class="socials">
          <h3>CONNECT WITH US</h3>
          <div class="icons">
            <i class="fab fa-facebook-f"></i>
            <i class="fa-brands fa-x-twitter"></i>
            <i class="fab fa-instagram"></i>
            <i class="fab fa-linkedin-in"></i>
          </div>
        </div>
      </div>
      <div class="right">
        <form action="">
          <div class="input-group group-2">
            <div class="form-group">
              <label for="name">First Name</label>
              <input type="text" id="name" placeholder="First Name" />
            </div>
            <div class="form-group">
              <label for="name">Last Name</label>
              <input type="text" id="name" placeholder="Last Name" />
            </div>
          </div>
          <!-- email, phone -->
          <div class="input-group group-2">
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" id="email" placeholder="Email" />
            </div>
            <div class="form-group">
              <label for="phone">Phone</label>
              <input type="text" id="phone" placeholder="Phone" />
            </div>
          </div>
          <!-- sender category, location -->
          <div class="input-group group-2">
            <div class="form-group">
              <label for="category">Category</label>
              <select name="category" id="category">
                <option value="general">General</option>
                <option value="support">Support</option>
                <option value="feedback">Feedback</option>
                <option value="partnership">Partnership</option>
                <option value="others">Others</option>
              </select>
            </div>
            <div class="form-group">
              <label for="location">Location</label>
              <input type="text" id="location" placeholder="Your location" />
            </div>
          </div>
          <!-- message -->
          <div class="input-group">
            <div class="form-group">
              <label for="message">Message</label>
              <textarea
                name="message"
                id="message"
                rows="5"
                placeholder="Your message"
              ></textarea>
            </div>
          </div>

          <!-- checkbox -->
          <div class="input-group">
            <div class="form-group checkbox">
              <input type="checkbox" />
              <label for="terms">
                Yes, I would like to receive email communication regarding news
                and offers from Cumbinn.
              </label>
            </div>
            <div class="form-group">
              <div class="form-group checkbox">
                <input type="checkbox" />
                <label for="terms">
                  Yes, I would like to receive phone calls from Cumbinn
                  regarding my inquiry.
                </label>
              </div>
            </div>
          </div>
          <div class="input-group">
            <button type="submit" class="btn bp cw">Submit</button>
          </div>
        </form>
      </div>
    </div>
    <div class="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d255203.55263329137!2d30.12724445!3d-1.9297706000000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dca4258ed8e797%3A0xf32b36a5411d0bc8!2sKigali!5e0!3m2!1sen!2srw!4v1708441285862!5m2!1sen!2srw"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.contact {
  .jumbotron {
    margin-top: 90px;
    display: flex;
    justify-content: space-between;
    background: #f4f4f4;
    @media (max-width: 1700px) {
      margin-top: 60px;
    }
    .left {
      padding-left: 10rem;
      width: 40%;
      padding-top: 2rem;
      @media (max-width: 1700px) {
        padding-top: 2rem;
      }
      @media (max-width: 1000px) {
        padding-top: 3rem;
        padding-left: 2rem;
        width: 100%;
      }
      @media (max-width: 750px) {
        padding-left: 1.5rem;
      }
      @media (max-width: 500px) {
        padding-left: 1rem;
      }
      h1 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
        @media (max-width: 1700px) {
          font-size: 2rem;
        }
        @media (max-width: 1300px) {
          font-size: 1.75rem;
        }
        @media (max-width: 1000px) {
          font-size: 1.5rem;
        }
      }
      p {
        font-size: 1.2rem;
        color: #333;
        @media (max-width: 1700px) {
          font-size: 1rem;
        }
        @media (max-width: 1300px) {
          font-size: 0.9rem;
        }
      }
    }
    .right {
      width: 40%;
      height: 400px;
      overflow: hidden;
      @media (max-width: 1700px) {
        height: 250px;
      }
      @media (max-width: 1000px) {
        display: none;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
      }
    }
  }
  .contact-section {
    display: flex;
    justify-content: space-between;
    border: 1px #eee solid;
    margin: 3rem 20rem;
    margin-top: -10rem;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 10px #00000010;
    position: relative;
    @media (max-width: 1700px) {
      margin: 3rem 10rem;
      margin-top: -2rem;
    }
    @media (max-width: 1300px) {
      margin: 3rem 5rem;
    }
    @media (max-width: 1000px) {
      margin: 1rem 1rem;
      flex-direction: column;
    }
    .left {
      width: 30%;
      background: rgb(8, 48, 121);
      background: linear-gradient(
        142deg,
        rgba(8, 48, 121, 1) 0%,
        rgba(15, 39, 75, 1) 100%
      );
      padding: 1rem 3rem;
      border-radius: 10px 0px 0px 10px;
      padding-top: 2rem;
      @media (max-width: 1000px) {
        width: 100%;
        border-radius: 10px 10px 0px 0px;
      }
      @media (max-width: 750px) {
        padding: 2rem 1.5rem;
      }
      @media (max-width: 500px) {
        padding: 2rem 1.5rem;
      }

      .fa-headset {
        font-size: 25rem;
        position: absolute;
        bottom: 0rem;
        left: 0rem;
        color: #ffffff0c;
      }
      .title {
        margin-bottom: 2rem;
        h1 {
          font-size: 2rem;
          margin-bottom: 1rem;
          color: #fff;
          line-height: 1.15;
          @media (max-width: 1700px) {
            font-size: 1.5rem;
          }
          @media (max-width: 1300px) {
            font-size: 1.25rem;
          }
          @media (max-width: 900px) {
            font-size: 1.15rem;
          }
          @media (max-width: 600px) {
            font-size: 1.1rem;
          }
        }
        p {
          font-size: 0.9rem;
          color: #ffffffb6;
        }
      }
      .contact-item {
        margin-bottom: 2rem;
        h3 {
          font-size: 1rem;
          margin-bottom: 1rem;
          color: #ffffff59;
          @media (max-width: 1700px) {
            font-size: 0.8rem;
          }
          @media (max-width: 1300px) {
            font-size: 0.7rem;
          }
          @media (max-width: 900px) {
            font-size: 0.7rem;
          }
          @media (max-width: 600px) {
            font-size: 0.65rem;
          }
        }
        .text {
          display: flex;
          align-items: center;
          .icon {
            font-size: 1.5rem;
            margin-right: 1rem;
            color: #fff;
            @media (max-width: 1700px) {
              margin-right: 0.5rem;
              font-size: 0.8rem;
            }
            @media (max-width: 1300px) {
              font-size: 0.7rem;
            }
            @media (max-width: 900px) {
              font-size: 0.7rem;
            }
            @media (max-width: 600px) {
              font-size: 0.65rem;
            }
          }
          p {
            font-size: 1.2rem;
            color: #fff;
            margin-bottom: 0;
            @media (max-width: 1700px) {
              font-size: 0.9rem;
            }
          }
        }
      }
      .socials {
        margin-top: 3rem;
        h3 {
          font-size: 1rem;
          margin-bottom: 1rem;
          color: #ffffff59;
        }
        .icons {
          i {
            font-size: 1.5rem;
            margin-right: 1rem;
            color: #fff;
            cursor: pointer;
            transition: all 0.3s ease;
            @media (max-width: 1700px) {
              font-size: 1rem;
            }
            @media (max-width: 1300px) {
              font-size: 0.9rem;
            }
            @media (max-width: 900px) {
              font-size: 0.85rem;
            }
            @media (max-width: 600px) {
            }
            &:hover {
              color: $primary_color;
            }
          }
        }
      }
    }
    .right {
      width: 70%;
      padding: 2rem 2rem;
      @media (max-width: 1000px) {
        width: 100%;
      }

      form {
        .input-group {
          margin-bottom: 2rem;
          .form-group {
            margin-bottom: 1rem;
            @media (max-width: 1700px) {
              margin-bottom: 0rem;
            }
            label {
              font-size: 1rem;
              margin-bottom: 0.5rem;
              display: block;
              @media (max-width: 1700px) {
                font-size: 0.8rem;
              }
              @media (max-width: 1300px) {
                font-size: 0.7rem;
              }
              @media (max-width: 900px) {
                font-size: 0.7rem;
              }
              @media (max-width: 600px) {
                font-size: 0.65rem;
              }
            }
            input,
            select,
            textarea {
              width: 100%;
              padding: 0.6rem 1rem;
              border: 1px solid #ddd;
              border-radius: 5px;
              outline: none;
              font-size: 0.95rem;
              @media (max-width: 1700px) {
                font-size: 0.8rem;
                padding: 0.35rem 0.8rem;
              }
              @media (max-width: 1300px) {
                font-size: 0.7rem;
              }
              @media (max-width: 900px) {
                font-size: 0.7rem;
              }
              @media (max-width: 600px) {
                font-size: 0.65rem;
              }
            }
            input[type="checkbox"] {
              margin-right: 1rem;
            }
            &.checkbox {
              display: flex;
              align-items: flex-start;
              input {
                width: auto;
              }
              label {
                font-size: 0.9rem;
                margin-top: -4px;
                @media (max-width: 1700px) {
                  font-size: 0.8rem;
                }
                @media (max-width: 1300px) {
                  font-size: 0.7rem;
                }
                @media (max-width: 900px) {
                  font-size: 0.7rem;
                }
                @media (max-width: 600px) {
                  font-size: 0.65rem;
                }
              }
            }
          }
          button {
            padding: 0.5rem 2rem;
            background-color: $primary_color;
            color: #fff;
            border: none;
            border-radius: 5px;
            font-size: 1.2rem;
            cursor: pointer;
            transition: all 0.5s ease;
            &:hover {
              background-color: $secondary_color;
            }
            @media (max-width: 1700px) {
              padding: 0.4rem 2rem;
              font-size: 0.8rem;
            }
            @media (max-width: 1300px) {
              font-size: 0.7rem;
            }
            @media (max-width: 900px) {
              font-size: 0.7rem;
            }
            @media (max-width: 600px) {
              font-size: 0.65rem;
            }
          }
        }
      }
    }
  }
  .map {
    width: 1005;
    iframe {
      width: 100%;
      border: 0;
    }
    margin-bottom: 50px;
  }
}
</style>
