<template>
  <div class="home">
    <NavBar />
    <div
      class="first-section"
      :style="`background-image: url(${$upload('b6.jpg')})`"
    >
      <!-- <div class="background-video">
        <img :src="$upload('b6.jpg')" />
      </div> -->
      <div class="text">
        <h1>Plan your next trip ahead effortlessly with Cumbinn</h1>
        <p>
          Find discounts on Hotels, Conference and Events facilities, and much
          more.
        </p>
        <div class="flex-c">
          <div class="filter-widget">
            <div class="filter-widget-top">
              <ul class="widget-options">
                <li>
                  <button
                    href="#"
                    :class="{ active: activeTab === 'stays' }"
                    @click="activeTab = 'stays'"
                  >
                    <i class="fas fa-hotel"></i>
                    <span>Stays</span>
                  </button>
                </li>
                <li>
                  <button
                    href="#"
                    :class="{ active: activeTab === 'Spaces' }"
                    @click="activeTab = 'Spaces'"
                  >
                    <i class="fa-solid fa-building"></i>
                    <span>Space</span>
                  </button>
                </li>
                <li>
                  <button
                    href="#"
                    :class="{ active: activeTab === 'Tours' }"
                    @click="activeTab = 'Tours'"
                  >
                    <i class="fas fa-tree"></i>
                    <span>Tour</span>
                  </button>
                </li>
                <li>
                  <button
                    href="#"
                    :class="{ active: activeTab === 'Cars' }"
                    @click="activeTab = 'Cars'"
                  >
                    <i class="fas fa-car"></i>
                    <span>Car</span>
                  </button>
                </li>
                <li>
                  <button
                    href="#"
                    :class="{ active: activeTab === 'Events' }"
                    @click="activeTab = 'Events'"
                  >
                    <i class="fas fa-calendar-alt"></i>
                    <span>Event</span>
                  </button>
                </li>
              </ul>
            </div>
            <div class="filter-section">
              <div class="filter" v-if="activeTab == 'stays'">
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                  <div class="inputs">
                    <label for="Destination">Desired location</label>
                    <select name="" id="" v-model="searchQuery.location">
                      <option value="">Select location</option>
                      <option
                        v-for="destination in alldestinations"
                        :key="destination.location_id"
                        :value="destination.location_id"
                      >
                        {{ destination.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-calendar-alt"></i>
                  </div>
                  <div class="inputs">
                    <label for="Date">Category</label>
                    <select
                      name="category"
                      id=""
                      v-model="searchQuery.category"
                    >
                      <option value="">Select category</option>
                      <option
                        v-for="category in categories"
                        :key="category.category_id"
                        :value="category.category_id"
                      >
                        {{ category.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-user-friends"></i>
                  </div>
                  <div class="inputs">
                    <label for="Tour Type">Tour Type</label>
                    <select name="" id="" v-model="searchQuery.favour">
                      <option value="">Select tour type</option>
                      <option>Family Tour</option>
                      <option>Group Tour</option>
                      <option>Honeymoon Tour</option>
                      <option>Adventure Tour</option>
                      <option>Solo Tour</option>
                    </select>
                  </div>
                </div>
                <!-- <div class="filter-item">
            <div class="icon">
              <i class="fas fa-money-bill-wave"></i>
            </div>
            <div class="inputs">
              <label for="Budget">Budget</label>
              <select name="" id="">
                <option value="">Under $50</option>
                <option value="">$50 - $100</option>
                <option value="">$100 - $300</option>
                <option value="">$300 - $500</option>
                <option value="">$500 - $1000</option>
                <option value="">$1000 - $2000</option>
                <option value="">$2000 - $5000</option>
                <option value="">$5000 - $10000</option>
                <option value="">$10000 - $20000</option>
                <option value="">Over $20000</option>
              </select>
            </div>
          </div> -->
              </div>
              <div class="filter" v-if="activeTab == 'Spaces'">
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                  <div class="inputs">
                    <label for="Destination">Desired location</label>
                    <select name="" id="" v-model="spaceData.location_id">
                      <option value="">Select location</option>
                      <option
                        v-for="destination in alldestinations"
                        :key="destination.location_id"
                        :value="destination.location_id"
                      >
                        {{ destination.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-money-bill-wave"></i>
                  </div>
                  <div class="inputs">
                    <label for="Price">Price</label>
                    <select name="price" id="" v-model="spaceData.price">
                      <option value="">Select price</option>
                      <option :value="50">&le; 50</option>
                      <option :value="100">&le; 100</option>
                      <option :value="200">&le; 200</option>
                      <option :value="500">&le; 500</option>
                      <option :value="1000">&le; 1000</option>
                      <option :value="2000">&le; 2000</option>
                      <option :value="5000">&le; 5000</option>
                      <option :value="10000">&le; 10000</option>
                      <option value="10000+">&ge; 10000</option>
                    </select>
                  </div>
                </div>
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-money-bill-wave"></i>
                  </div>
                  <div class="inputs">
                    <label for="Payment Frequency">Payment Frequency</label>
                    <select
                      name="payment_frequency"
                      id=""
                      v-model="spaceData.payment_frequency"
                    >
                      <option value="">Select frequency</option>
                      <option value="Per Hour">Per Hour</option>
                      <option value="Per Day">Per Day</option>
                      <option value="Per Week">Per Week</option>
                      <option value="Per Month">Per Month</option>
                      <option value="Per Year">Per Year</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="filter" v-if="activeTab == 'Tours'">
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                  <div class="inputs">
                    <label for="Destination">Where are you visiting?</label>
                    <select name="" id="" v-model="tourData.location_id">
                      <option value="">Select destination</option>
                      <option
                        v-for="destination in alldestinations"
                        :key="destination.location_id"
                        :value="destination.location_id"
                      >
                        {{ destination.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                  <div class="inputs">
                    <label for="Attraction">Attraction</label>
                    <select name="" id="" v-model="tourData.attraction">
                      <option value="">Select attraction</option>
                      <option
                        v-for="(attraction, index) in tourAttractions"
                        :key="index"
                        :value="attraction.attraction"
                      >
                        {{ attraction.attraction }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-user-friends"></i>
                  </div>
                  <div class="inputs">
                    <label for="Tour Type">Tour Type</label>
                    <select name="" id="" v-model="tourData.favour">
                      <option value="">Select tour type</option>
                      <option>Family Tour</option>
                      <option>Group Tour</option>
                      <option>Honeymoon Tour</option>
                      <option>Adventure Tour</option>
                      <option>Solo Tour</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="filter" v-if="activeTab == 'Cars'">
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                  <div class="inputs">
                    <label for="Destination">Where are you?</label>
                    <select name="" id="" v-model="carData.location_id">
                      <option value="">Select location</option>
                      <option
                        v-for="destination in alldestinations"
                        :key="destination.location_id"
                        :value="destination.location_id"
                      >
                        {{ destination.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-car"></i>
                  </div>
                  <div class="inputs">
                    <label for="Car Brand">Car Brand</label>
                    <select name="" id="" v-model="carData.car_brand">
                      <option value="">Select car brand</option>
                      <option
                        v-for="brand in carBrands"
                        :key="brand.car_brand"
                        :value="brand.car_brand"
                      >
                        {{ brand.car_brand }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-car"></i>
                  </div>
                  <div class="inputs">
                    <label for="Car Type">Car Type</label>
                    <select name="" id="" v-model="carData.car_type">
                      <option value="">Select car type</option>
                      <option
                        v-for="cartype in carTypes"
                        :key="cartype.car_type"
                        :value="cartype.car_type"
                      >
                        {{ cartype.car_type }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="filter" v-if="activeTab == 'Events'">
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-map-marker-alt"></i>
                  </div>
                  <div class="inputs">
                    <label for="Destination">Event location</label>
                    <select name="" id="" v-model="eventData.location_id">
                      <option value="">Select location</option>
                      <option
                        v-for="destination in alldestinations"
                        :key="destination.location_id"
                        :value="destination.location_id"
                      >
                        {{ destination.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-map"></i>
                  </div>
                  <div class="inputs">
                    <label for="Attraction">Place</label>
                    <select name="" id="" v-model="eventData.attraction">
                      <option value="">Select place</option>
                      <option
                        v-for="attraction in eventAttractions"
                        :key="attraction.attraction"
                        :value="attraction.attraction"
                      >
                        {{ attraction.attraction }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="filter-item">
                  <div class="icon">
                    <i class="fas fa-user-friends"></i>
                  </div>
                  <div class="inputs">
                    <label for="Event Type">Event Type</label>
                    <select name="" id="" v-model="eventData.event_type">
                      <option value="">Select event type</option>
                      <option>Entertainment</option>
                      <option>Conference</option>
                      <option>Meeting</option>
                      <option>Networking</option>
                      <option>Product launches</option>
                      <option>Arts</option>
                      <option>Educational</option>
                      <option>Community</option>
                      <option>Charity</option>
                      <option>Religious</option>
                      <option>Political</option>
                      <option>Business</option>
                      <option>Health</option>
                      <option>Environmental</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="filter-btn">
                <button class="mybtn" @click="SearchStays()">Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="filtered-area">
        <div class="category-list">
          <h2>Ultimate Stays</h2>
          <!-- <ul>
            <li
              @click="filterItems('all')"
              :class="{ active: selectedCategory === 'all' }"
            >
              <div class="top">
                <img src="/img/all.png" alt="all" />
              </div>
              <div class="bottom">
                <span>All</span>
              </div>
            </li>
            <li
              v-for="category in allCategories"
              :key="category.id"
              @click="filterItems(category.name)"
              :class="{ active: selectedCategory === category.name }"
            >
              <div class="top">
                <img :src="'/img/' + category.icon" :alt="category.name" />
              </div>
              <div class="bottom">
                <span>{{ category.name }}</span>
              </div>
            </li>
          </ul> -->
        </div>
        <div class="items-listing" v-if="filteredItems.length">
          <AccomodationCard :filteredItems="filteredItems" />
        </div>
        <!-- view more -->
        <div class="view-more d-flex-center mt-10 mb-10">
          <router-link to="/stays" class="bp cw btn">
            View More
            <i class="fa fa-arrow-right"></i>
          </router-link>
        </div>
      </div>
    </div>

    <div class="container with-b">
      <!-- Browse by Destination  -->
      <div class="location-area">
        <div class="title">
          <h2>Desired Destinations</h2>
        </div>
        <ul>
          <li
            v-for="destination in destinations"
            :key="destination.location_id"
          >
            <router-link
              :to="{
                name: 'Stays',
                query: { location: destination.location_id },
              }"
              class="img"
            >
              <img :src="$upload(destination.image)" :alt="destination.name" />
            </router-link>
            <div class="details">
              <h3>{{ destination.name }}</h3>
              <p>{{ destination.accomodations }} Stays</p>
            </div>
          </li>
        </ul>
        <div class="view-more">
          <router-link :to="{ name: 'Destinations' }" class="bp cw btn">
            View More
            <i class="fa fa-arrow-right"></i>
          </router-link>
        </div>
      </div>
    </div>
    <!-- <div class="newsletters">
      <div class="container">
        <h2>Spend less, get more with Cumbinn</h2>
        <p>
          Subscribe to our newsletter and get the best deals on your favorite
          destinations.
        </p>
        <form action="">
          <input type="email" placeholder="Enter your email" />
          <button class="primary">Subscribe</button>
        </form>
      </div>
    </div> -->
    <div class="container">
      <div class="why-choose-us">
        <h2 class="header">Why Choose Cumbinn?</h2>
        <p class="sub-header">
          Cumbinn.com seamlessly links millions of tourists to fantastic places
          of interest, a wide range of transport modes, and unforgettable
          experiences in Rwanda. Cumbinn.com, one of the leading travel
          marketplaces in Rwanda, makes it possible for businesses of all kinds
          and well-known brands to expand their clientele globally.
        </p>
        <div class="features">
          <!-- No Reservation Fees -->
          <div class="feature-item">
            <div class="left">
              <i class="fa-solid fa-filter-circle-dollar"></i>
              <div class="text">
                <p>Reason</p>
                <h3>01</h3>
              </div>
            </div>
            <div class="right">
              <h3>No Reservation Fees</h3>
              <p>
                You won't be subject to any booking fees from us. It's only a
                refundable <b>$1</b>. In many instances, you can cancel your
                reservation without incurring any fees.
              </p>
            </div>
          </div>
          <!-- Instant Confirmation -->
          <div class="feature-item">
            <div class="left">
              <i class="fa-solid fa-check-to-slot"></i>
              <div class="text">
                <p>Reason</p>
                <h3>02</h3>
              </div>
            </div>
            <div class="right">
              <h3>Instant Confirmation</h3>
              <p>
                Every reservation made on Cumbinn.com is promptly confirmed. It
                only takes a few clicks to book your ideal stay.
              </p>
            </div>
          </div>
          <!-- Secure Booking -->
          <div class="feature-item">
            <div class="left">
              <i class="fa-solid fa-lock"></i>
              <div class="text">
                <p>Reason</p>
                <h3>03</h3>
              </div>
            </div>
            <div class="right">
              <h3>Secure Booking</h3>
              <p>
                Through our secure platform, we enable hundreds of thousands of
                transactions each day, and we adhere to the highest standards to
                ensure your privacy.
              </p>
            </div>
          </div>
          <!-- Low Rates -->
          <div class="feature-item">
            <div class="left">
              <i class="fa-solid fa-dollar-sign"></i>
              <div class="text">
                <p>Reason</p>
                <h3>04</h3>
              </div>
            </div>
            <div class="right">
              <h3>Low Rates</h3>
              <p>
                You can rely on us to provide you with the most competitive
                rates. And with our pledge to price match, you can rest assured
                that you're always receiving a fantastic deal.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- .blog posts -->
      <div class="blog-posts" v-if="blogs.length > 0">
        <h2 class="header">From Our Blog</h2>
        <p class="sub-header">
          Get the latest news and updates from Cumbinn.com
        </p>
        <div class="posts">
          <div class="post" v-for="post in blogs" :key="post.blog_id">
            <div class="image">
              <img :src="$upload(post.image)" :alt="post.title" />
            </div>
            <div class="details">
              <h3>{{ post.title }}</h3>
              <p>{{ $date(post.created_at) }}</p>
              <p v-html="post.description" class="content"></p>
              <router-link to="/blog" class="btn">Read More</router-link>
            </div>
          </div>
        </div>
        <div class="view-more d-flex-center mt-10">
          <router-link to="/categories" class="bp cw btn">
            View More
            <i class="fa fa-arrow-right"></i>
          </router-link>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      statuses: ["For Sale", "For Rent"],
      allCategories: [
        {
          id: 1,
          name: "Hotel",
          icon: "hotel.png",
          count: 28,
        },
        {
          id: 2,
          name: "Motel",
          icon: "motel.png",
          count: 100,
        },
        // apartments, land, commercial, lodge, resort, guest house
        {
          id: 3,
          name: "Apartment",
          icon: "apartment.png",
          count: 100,
        },
        {
          id: 5,
          name: "Lodge",
          icon: "lodge.png",
          count: 100,
        },
        {
          id: 6,
          name: "Resort",
          icon: "resort.png",
          count: 100,
        },
        {
          id: 7,
          name: "Guest House",
          icon: "guest_house.png",
          count: 100,
        },
      ],
      allItems: [],
      filteredItems: [],
      selectedCategory: "all",
      destinations: [],
      alldestinations: [],
      blogs: [],
      categories: null,
      searchQuery: {
        category: "",
        location: "",
        favour: "",
      },
      activeTab: "stays",
      stayData: {
        location: "",
        category: "",
        favour: "",
      },
      spaceData: {
        location_id: "",
        price: "",
        payment_frequency: "",
      },
      tourData: {
        location_id: "",
        attraction: "",
        favour: "",
      },
      carData: {
        location_id: "",
        car_brand: "",
        car_type: "",
      },
      eventData: {
        location_id: "",
        attraction: "",
        event_type: "",
      },
      eventAttractions: [],
      tourAttractions: [],
      carBrands: [],
      carTypes: [],
    };
  },
  methods: {
    filterItems(category) {
      this.selectedCategory = category;
      if (category === "all") {
        this.filteredItems = this.allItems;
      } else {
        this.filteredItems = this.allItems.filter(
          (item) => item.category === category
        );
      }
      this.selectedCategory = category;
    },
    getLocations() {
      this.$startLoader();
      this.$store
        .dispatch("GET_LOCATIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 5,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.destinations = res.data.locations.data;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getManyLocations() {
      this.$startLoader();
      this.$store
        .dispatch("GET_LOCATIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 20,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.alldestinations = res.data.locations.data;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getCategories() {
      this.$startLoader();
      this.$store
        .dispatch("GET_CATEGORIES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.categories = res.data.categories.data;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_ACCOMODATIONS", {
          data: {
            paginate: 8,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              if (res.data.accomodations) {
                this.allItems = res.data.accomodations.data;
                this.allItemsCount = res.data.accomodations.total;
                this.filteredItems = res.data.accomodations.data;
              }
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getBlogs() {
      this.$startLoader();
      this.$store
        .dispatch("GET_BLOGS", {
          data: {
            paginate: 4,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.blogs = res.data.blogs.data;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    SearchStays() {
      if (this.activeTab === "stays") {
        this.$router.push({
          name: "Stays",
          query: this.searchQuery,
        });
      } else if (this.activeTab === "Spaces") {
        this.$router.push({
          name: "Spaces",
          query: this.spaceData,
        });
      } else if (this.activeTab === "Tours") {
        this.$router.push({
          name: "Tours",
          query: this.tourData,
        });
      } else if (this.activeTab === "Cars") {
        this.$router.push({
          name: "Cars",
          query: this.carData,
        });
      } else if (this.activeTab === "Events") {
        this.$router.push({
          name: "Events",
          query: this.eventData,
        });
      }
    },
    getEventAttractions() {
      this.$store
        .dispatch("GET_ATTRACTIONS_BY_TYPE", {
          token: this.$getAdminToken(),
          data: {
            paginate: 5,
            page: this.currentPage,
            type: "Events",
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.eventAttractions = res.data.attractions.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
        });
    },
    getTourAttractions() {
      this.$store
        .dispatch("GET_ATTRACTIONS_BY_TYPE", {
          token: this.$getAdminToken(),
          data: {
            paginate: 5,
            page: this.currentPage,
            type: "Tours",
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.tourAttractions = res.data.attractions.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
        });
    },
    getCarBrands() {
      this.$store
        .dispatch("GET_CAR_BRANDS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 5,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.carBrands = res.data.carBrands.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
        });
    },
    getCarTypes() {
      this.$store
        .dispatch("GET_CAR_TYPES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 5,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.carTypes = res.data.carTypes.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
        });
    },
  },
  mounted() {
    // const video = document.getElementById("video");
    // video.playbackRate = 0.5;

    this.getItems();
    this.getLocations();
    this.getCategories();
    this.getBlogs();
    this.getManyLocations();
    this.getEventAttractions();
    this.getTourAttractions();
    this.getCarBrands();
    this.getCarTypes();
  },
  computed: {
    categoryNames() {
      return this.allCategories.map((category) => {
        return category.name;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.home {
  overflow-x: hidden;
  .first-section {
    position: relative;
    width: 100%;
    min-height: 100vh;
    height: fit-content;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    // background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    .background-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .text {
      // position: absolute;
      text-align: center;
      color: #fff;
      background: #000;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 30%,
        rgba(0, 0, 0, 0.6) 100%
      );
      min-height: 50%;
      width: 100%;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 100px;
      padding-top: 50px;
      @media screen and (max-width: 600px) {
        padding-bottom: 2rem;
        padding-top: 5rem;
        margin-top: 100px;
      }
      h1 {
        font-size: 3rem;
        margin-bottom: 0px;
        color: #fff;
        font-weight: 500;
        width: 60%;
        @media screen and (max-width: 1700px) {
          font-size: 2.5rem;
        }
        @media screen and (max-width: 1300px) {
          font-size: 2.15rem;
          width: 70%;
        }
        @media screen and (max-width: 900px) {
          width: 75%;
          font-size: 2rem;
        }
        @media screen and (max-width: 600px) {
          width: 90%;
          font-size: 1.5rem;
        }
        img {
          height: 35px;
        }
      }
      p {
        font-size: 1.25rem;
        @media screen and (max-width: 1700px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 1300px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 900px) {
          font-size: 0.9rem;
        }
        @media screen and (max-width: 600px) {
          font-size: 0.8rem;
          // display: none;
        }
      }
      .btn {
        padding: 15px 30px;
        background-color: $primary_color;
        color: #fff;
        text-decoration: none;
        border-radius: 5px;
        transition: all 0.3s;
        font-size: 1.2rem;
        font-weight: 500;
        &:hover {
          background-color: darken($color: $primary_color, $amount: 10%);
        }
      }
    }
  }

  .filter-widget {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @media screen and (max-width: 600px) {
      align-items: center;
    }
    .filter-widget-top {
      display: flex;
      justify-content: flex-start;
      margin-top: 20px;
      width: fit-content;
      border-radius: 10px 10px 0 0;
      background: #ffffff;
      padding: 10px 10px;
      border-bottom: 1px solid #00141a0c;
      @media screen and (max-width: 900px) {
        width: 600px;
      }
      @media screen and (max-width: 650px) {
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        width: calc(100vw - 50px);
      }
      .widget-options {
        display: flex;
        gap: 10px;
        list-style-type: none;
        margin-bottom: 0;
        width: 100%;
        @media screen and (max-width: 600px) {
          flex-wrap: wrap;
        }
        li {
          width: 90px;
          @media screen and (max-width: 260px) {
            width: 100%;
          }
          button {
            width: 100%;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            color: #333;
            border: none;
            border-radius: 5px;
            transition: all 0.3s;
            font-size: 1rem;
            font-weight: 500;
            cursor: pointer;
            border: 1px solid $primary_color;
            gap: 5px;
            @media screen and (max-width: 1700px) {
              font-size: 0.8rem;
            }
            @media screen and (max-width: 1300px) {
              font-size: 0.8rem;
            }
            @media screen and (max-width: 900px) {
              font-size: 0.8rem;
            }
            @media screen and (max-width: 600px) {
              font-size: 0.8rem;
            }
            i {
              font-size: 1rem;
              color: $primary_color;
              opacity: 1;
              @media screen and (max-width: 1700px) {
                font-size: 0.8rem;
              }
              @media screen and (max-width: 1300px) {
                font-size: 0.8rem;
              }
              @media screen and (max-width: 900px) {
                font-size: 0.8rem;
              }
              @media screen and (max-width: 600px) {
                font-size: 0.8rem;
              }
            }
            &:hover,
            &.active {
              background-color: $primary_color;
              color: #fff;
              i {
                color: #fff;
              }
            }
          }
        }
      }
    }
    .filter-section {
      display: grid;
      grid-template-columns: 4fr 1fr;
      width: 1200px;
      // margin-left: 18px;
      border-radius: 0 10px 10px 10px;
      overflow: hidden;
      // margin-top: -40px;
      z-index: 1;
      @media screen and (max-width: 1700px) {
        width: 1000px;
      }
      @media screen and (max-width: 1300px) {
        width: 800px;
      }
      @media screen and (max-width: 900px) {
        width: 600px;
        border-radius: 0 0px 20px 20px;
      }
      @media screen and (max-width: 650px) {
        width: calc(100vw - 50px);
      }
      @media screen and (max-width: 420px) {
        grid-template-columns: repeat(1, 1fr);
      }
      .filter {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        background: #ffffff;
        @media screen and (max-width: 1700px) {
          grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width: 1300px) {
          grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width: 900px) {
          grid-template-columns: repeat(1, 1fr);
        }
        .filter-item {
          display: flex;
          align-items: center;
          padding: 1rem;
          border-right: 1px solid #001a031e;
          @media screen and (max-width: 900px) {
            padding: 0.5rem 1rem;
          }
          .icon {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            i {
              font-size: 1.5rem;
              color: $primary_color;
              opacity: 0.7;
              margin-right: 1rem;
              @media screen and (max-width: 1700px) {
                font-size: 1.2rem;
              }
              @media screen and (max-width: 1300px) {
                font-size: 1.2rem;
              }
              @media screen and (max-width: 900px) {
                font-size: 1.2rem;
              }
              @media screen and (max-width: 600px) {
                font-size: 1.2rem;
              }
            }
          }
          .inputs {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            label {
              font-size: 0.65rem;
              font-weight: 400;
              color: #333333;
              // margin-left: 4px;
            }
            select,
            input {
              width: 100%;
              border: none;
              padding: 0;
              font-size: 1rem;
              font-weight: 700;
              color: #333333;
              background: transparent;
              outline: none;
              &:focus {
                border: none;
              }
            }
          }
        }
      }
      .filter-btn {
        .mybtn {
          padding: 0.5rem 2rem;
          font-size: 1rem;
          font-weight: 500;
          color: white;
          border: none;
          border-radius: 0px;
          cursor: pointer;
          background: $primary_color;
          transition: all 0.4s ease-in-out;
          width: 100%;
          height: 100%;
          &:hover {
            background: darken($color: $primary_color, $amount: 10%);
          }
        }
      }
    }
  }
  .container {
    padding: 0 10rem;
    @media (max-width: 1500px) {
      padding: 0 5rem;
    }
    @media (max-width: 1200px) {
      padding: 0 4rem;
    }
    @media (max-width: 1000px) {
      padding: 0 3rem;
    }
    @media (max-width: 750px) {
      padding: 0 2rem;
    }
    @media (max-width: 500px) {
      padding: 0 1rem;
    }
    .search-area {
      margin-top: 50px;
      border: 1px solid #e0e0e0;
      padding: 20px;
      border-radius: 5px;
      .search {
        h2 {
          font-size: 2rem;
          margin-bottom: 20px;
        }
        form {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: space-between;
          column-gap: 20px;
          .combo {
            width: 100%;
          }
          button {
            padding: 15px 0;
            background-color: $primary_color;
            color: #fff;
            border: none;
            border-radius: 5px;
            transition: all 0.3s;
            font-size: 1.2rem;
            font-weight: 500;
            &:hover {
              background-color: darken($color: $primary_color, $amount: 10%);
            }
          }
        }
      }
    }
    .filtered-area {
      margin-top: 50px;
      .category-list {
        h2 {
          font-size: 2rem;
          margin-bottom: 20px;
          @media screen and (max-width: 1700px) {
            font-size: 1.5rem;
          }
          @media screen and (max-width: 1300px) {
            font-size: 1.4rem;
          }
          @media screen and (max-width: 900px) {
            font-size: 1.2rem;
          }
          @media screen and (max-width: 600px) {
            font-size: 1rem;
          }
        }
        ul {
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          column-gap: 3rem;
          li {
            display: flex;
            flex-direction: column;
            justify-content: center;
            opacity: 0.4;
            align-items: center;
            cursor: pointer;
            border-bottom: 2px solid transparent;
            transition: all 0.3s;
            .top {
              img {
                width: 30px;
                height: 30px;
                @media screen and (max-width: 1700px) {
                  width: 25px;
                  height: 25px;
                }
                @media screen and (max-width: 1300px) {
                  width: 20px;
                  height: 20px;
                }
                @media screen and (max-width: 900px) {
                  width: 15px;
                  height: 15px;
                }
                @media screen and (max-width: 600px) {
                  width: 10px;
                  height: 10px;
                }
              }
            }
            .bottom {
              display: flex;
              flex-direction: column;
              align-items: center;
              span {
                font-size: 0.8rem;
                font-weight: 500;
                @media screen and (max-width: 1700px) {
                  font-size: 0.7rem;
                }
                @media screen and (max-width: 1300px) {
                  font-size: 0.6rem;
                }
                @media screen and (max-width: 900px) {
                  font-size: 0.5rem;
                }
                @media screen and (max-width: 600px) {
                  font-size: 0.4rem;
                }
              }
            }
            &.active,
            &:hover {
              opacity: 1;
              border-bottom: 2px solid $primary_color;
            }
          }
        }
      }
    }
    .location-area {
      margin-top: 50px;
      margin-bottom: 50px;
      .title {
        h2 {
          font-size: 2rem;
          margin-bottom: 20px;
          color: #000;
          @media (max-width: 900px) {
            font-size: 1.5rem;
          }
          @media (max-width: 600px) {
            font-size: 1.25rem;
          }
        }
      }
      ul {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        @media (max-width: 1100px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 750px) {
          display: grid;
          grid-template-columns: 1fr;
        }
        li {
          border: 1px solid #e0e0e0;
          border-radius: 5px;
          overflow: hidden;
          height: 300px;
          position: relative;
          cursor: pointer;
          @media (max-width: 900px) {
            height: 200px;
          }
          .img {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .details {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.603) 0%,
              rgba(0, 0, 0, 0.699) 50%,
              rgba(0, 0, 0, 0) 100%
            );
            padding: 20px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 900px) {
            }
            @media (max-width: 600px) {
              padding: 10px 10px;
            }
            h3 {
              font-size: 1.25rem;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #fff;
              width: 65%;
              @media (max-width: 900px) {
                font-size: 1rem;
              }
              @media (max-width: 600px) {
                font-size: 0.8rem;
              }
            }
            p {
              width: 35%;
              font-size: 1.25rem;
              color: #fff;
              @media (max-width: 900px) {
                font-size: 1rem;
              }
              @media (max-width: 600px) {
                font-size: 0.8rem;
              }
            }
          }
        }
        :nth-child(1) {
          grid-column: auto / span 3;
        }
        :nth-child(5) {
          grid-column: auto / span 3;
        }
        @media (max-width: 1100px) {
          :nth-child(2),
          :nth-child(3),
          :nth-child(4) {
            width: 100%;
          }
          :nth-child(1) {
            grid-column: auto / span 2;
          }
          :nth-child(5) {
            grid-column: auto / span 1;
          }
        }
        @media (max-width: 750px) {
          :nth-child(2),
          :nth-child(3),
          :nth-child(4),
          :nth-child(5) {
            width: 100%;
          }
          :nth-child(1) {
            grid-column: auto / span 1;
          }
        }
      }
      .view-more {
        margin-top: 20px;
        text-align: right;
        a {
          padding: 10px 20px;
          text-decoration: none;
          border-radius: 5px;
          transition: all 0.3s;
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
    &.with-b {
      padding-top: 4rem;
      padding-bottom: 4rem;
      @media (max-width: 750px) {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }
  }

  .blog-posts {
    margin: 50px 0;
    .header {
      font-size: 2rem;
      margin-bottom: 20px;
      @media (max-width: 750px) {
        font-size: 1.5rem;
      }
    }
    .sub-header {
      font-size: 1.25rem;
      margin-bottom: 20px;
      @media (max-width: 750px) {
        font-size: 1.15rem;
      }
    }
    .posts {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      @media (max-width: 750px) {
        grid-template-columns: 1fr;
      }
      .post {
        border: 1px solid #e0e0e070;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0px 0px 10px 0px #00000013;
        .image {
          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
          }
        }
        .details {
          padding: 20px;
          h3 {
            font-size: 1.25rem;
            margin-bottom: 10px;
            @media (max-width: 750px) {
              font-size: 1.15rem;
            }
          }
          p.content {
            font-size: 0.8rem;
            margin-bottom: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: #747474;
            @media (max-width: 750px) {
              font-size: 0.8rem;
            }
            :deep(p > h2),
            :deep(p > h1),
            :deep(p > h3),
            :deep(p > h4),
            :deep(p > h5),
            :deep(p > h6) {
              font-size: 0.9rem;
            }
            :deep(p > img) {
              display: none;
            }
          }
          .btn {
            padding: 10px 20px;
            border: 1px solid $primary_color;
            color: #fff;
            text-decoration: none;
            border-radius: 5px;
            transition: all 0.3s;
            font-size: 1rem;
            font-weight: 500;
            margin-top: 5px;
            display: inline-block;
            &:hover {
              background-color: $primary_color;
              color: #fff;
            }

            @media (max-width: 750px) {
              padding: 4px 8px;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}
</style>
