<template>
  <div id="NavBar">
    <TopBar />
    <div class="nav-bar">
      <div class="left">
        <!-- links for real estate web  -->
        <ul class="nav-links">
          <li>
            <router-link to="/" class="nav-link">Home</router-link>
          </li>
          <li>
            <router-link
              :to="{
                name: 'Destinations',
              }"
              class="nav-link"
              >Destinations</router-link
            >
          </li>
          <!-- categories -->
          <li>
            <div class="nav-link drop-item">
              <span>Stays</span>
              <div class="drop-content">
                <router-link
                  :to="{
                    name: 'Stays',
                  }"
                  class="drop-link"
                  >All</router-link
                >
                <router-link
                  class="drop-link"
                  v-for="category in categories"
                  :key="category.category_id"
                  :to="{
                    name: 'Stays',
                    query: { category: category.category_id },
                  }"
                  >{{ category.name }}</router-link
                >
              </div>
            </div>
          </li>
          <li>
            <router-link
              :to="{
                name: 'Spaces',
              }"
              class="nav-link"
              >Spaces</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'Tours',
              }"
              class="nav-link"
              >Tours</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'Cars',
              }"
              class="nav-link"
              >Cars</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'Events',
              }"
              class="nav-link"
              >Events</router-link
            >
          </li>
        </ul>
      </div>
      <div class="middle">
        <img src="/img/logob.png" alt="Cumbinn Logo" />
      </div>
      <div class="right">
        <ul class="nav-links">
          <li>
            <router-link :to="{ name: 'About' }" class="nav-link"
              >About</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Blog' }" class="nav-link"
              >Blog</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Contact' }" class="nav-link"
              >Contact</router-link
            >
          </li>
          <li v-if="$getSiteUser()">
            <div class="account-button" @click="$siteuserLogout">
              <button>Logout</button>
            </div>
          </li>
          <li v-if="!$getSiteUser()">
            <router-link :to="{ name: 'Login' }" class="nav-link"
              >Login</router-link
            >
          </li>
          <li v-if="!$getSiteUser()">
            <router-link to="/register" class="nav-link">Register</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="collapsible-bar">
      <div class="collapsible-top">
        <div class="left">
          <img src="/img/logob.png" alt="Cumbinn Logo" />
        </div>
        <div class="right">
          <div class="collapsible-button" @click="toggleCollapsible">
            <i class="fas fa-bars"></i>
          </div>
        </div>
      </div>
      <div class="collapsible-links" v-if="collapsible">
        <ul class="nav-links">
          <li>
            <router-link to="/" class="nav-link">Home</router-link>
          </li>
          <li>
            <router-link
              :to="{
                name: 'Destinations',
              }"
              class="nav-link"
              >Destinations</router-link
            >
          </li>
          <li>
            <div class="nav-link drop-item">
              <span>Stays</span>
              <div class="drop-content">
                <router-link
                  :to="{
                    name: 'Stays',
                  }"
                  class="drop-link"
                  >All</router-link
                >
                <router-link
                  class="drop-link"
                  v-for="category in categories"
                  :key="category.category_id"
                  :to="{
                    name: 'Stays',
                    query: { category: category.category_id },
                  }"
                  >{{ category.name }}</router-link
                >
              </div>
            </div>
          </li>
          <li>
            <router-link
              :to="{
                name: 'Spaces',
              }"
              class="nav-link"
              >Spaces</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'Tours',
              }"
              class="nav-link"
              >Tours</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'Cars',
              }"
              class="nav-link"
              >Cars</router-link
            >
          </li>
          <li>
            <router-link
              :to="{
                name: 'Events',
              }"
              class="nav-link"
              >Events</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'About' }" class="nav-link"
              >About</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Blog' }" class="nav-link"
              >Blog</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Contact' }" class="nav-link"
              >Contact</router-link
            >
          </li>
          <li v-if="$getSiteUser()">
            <div class="account-button" @click="$siteuserLogout">
              <button>Logout</button>
            </div>
          </li>
          <li v-if="!$getSiteUser()">
            <router-link :to="{ name: 'Login' }" class="nav-link"
              >Login</router-link
            >
          </li>
          <li v-if="!$getSiteUser()">
            <router-link to="/register" class="nav-link">Register</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  data() {
    return {
      destinations: null,
      categories: null,
      collapsible: false,
    };
  },
  methods: {
    getLocations() {
      this.$store
        .dispatch("GET_LOCATIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 6,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.destinations = res.data.locations.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
        });
    },
    getCategories() {
      this.$store
        .dispatch("GET_CATEGORIES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 6,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.categories = res.data.categories.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
        });
    },
    toggleCollapsible() {
      this.collapsible = !this.collapsible;
    },
  },
  mounted() {
    this.getLocations();
    this.getCategories();
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #0c3b7c;
$secondary_color: #00bafc;
#NavBar {
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  .nav-bar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    padding: 0px 20px;
    background-color: #fff;
    color: #000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 0 15rem;
    @media (max-width: 1500px) {
      padding: 0 8rem;
    }
    @media (max-width: 1200px) {
      padding: 0 1rem;
    }
    @media (max-width: 1000px) {
      display: none;
      padding: 0 3rem;
    }
    @media (max-width: 750px) {
      padding: 0 2rem;
    }
    @media (max-width: 500px) {
      padding: 0 1rem;
    }
    .left,
    .right {
      display: flex;
      justify-content: flex-start;
      .nav-links {
        display: flex;
        list-style: none;
        align-items: center;
        margin: 0;
        .nav-link {
          text-decoration: none;
          color: $primary_color;
          margin: 0 10px;
          font-weight: 500;
          padding: 20px 0;
          cursor: pointer;
          @media (max-width: 1700px) {
            font-size: 14px;
            padding: 10px 0;
          }
          @media (max-width: 1500px) {
            font-size: 14px;
          }
          @media (max-width: 1200px) {
          }
          @media (max-width: 1000px) {
          }
          @media (max-width: 750px) {
          }
          @media (max-width: 500px) {
          }
          &:hover {
            color: $secondary_color;
          }
        }
        .drop-item {
          position: relative;
          cursor: pointer;

          @media (max-width: 1500px) {
          }
          @media (max-width: 1200px) {
          }
          @media (max-width: 1000px) {
          }
          @media (max-width: 750px) {
          }
          @media (max-width: 500px) {
          }
          .drop-content {
            display: none;
            position: absolute;
            background-color: #fff;
            min-width: 160px;
            margin-top: 20px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            @media (max-width: 1500px) {
              margin-top: 10px;
            }
            @media (max-width: 1200px) {
            }
            @media (max-width: 1000px) {
            }
            @media (max-width: 750px) {
            }
            @media (max-width: 500px) {
            }
            .drop-link {
              color: $primary_color;
              padding: 12px 16px;
              text-decoration: none;
              display: block;
              cursor: pointer;
              @media (max-width: 1500px) {
                padding: 10px 10px;
                font-size: 14px;
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 1000px) {
              }
              @media (max-width: 750px) {
              }
              @media (max-width: 500px) {
              }
              &:hover {
                background-color: $secondary_color;
                color: #fff;
              }
            }
          }
          &:hover .drop-content {
            display: block;
          }
        }
        .router-link-active,
        .router-link-exact-active {
          color: $secondary_color;
        }
      }
    }
    .right {
      justify-content: flex-end !important;
    }
    .middle {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 200px;
        @media (max-width: 1500px) {
          width: 150px;
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
        }
      }
    }
  }
  .collapsible-bar {
    display: none;
    z-index: 100;
    @media (max-width: 1000px) {
      display: block;
      background-color: #fff;
      color: #000;
      border-bottom: 1px solid #221b4942;
      padding: 0 15rem;
      @media (max-width: 1500px) {
        padding: 0 8rem;
      }
      @media (max-width: 1200px) {
        padding: 0 1rem;
      }
      @media (max-width: 1000px) {
        padding: 0 3rem;
      }
      @media (max-width: 750px) {
        padding: 0 2rem;
      }
      @media (max-width: 500px) {
        padding: 0 1rem;
      }
      .collapsible-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        .left {
          img {
            @media (max-width: 1000px) {
              width: 200px;
            }
            @media (max-width: 750px) {
              width: 150px;
            }
            @media (max-width: 500px) {
              width: 100px;
            }
          }
        }
        .right {
          .collapsible-button {
            padding: 10px;
            cursor: pointer;
            i {
              font-size: 1.5rem;
            }
          }
        }
      }
      .collapsible-links {
        border-top: 1px solid #221b4942;
        overflow-y: auto;
        height: 80vh;
        .nav-links {
          display: flex;
          list-style: none;
          flex-direction: column;
          margin: 0;
          flex-wrap: wrap;
          padding-bottom: 20px;
          .nav-link {
            text-decoration: none;
            color: $primary_color;
            margin: 0 10px;
            font-weight: 500;
            padding: 20px 0;
            cursor: pointer;
            display: block;
            @media (max-width: 1700px) {
              font-size: 14px;
              padding: 10px 0;
            }
            @media (max-width: 1500px) {
              font-size: 14px;
            }
            @media (max-width: 1200px) {
            }
            @media (max-width: 1000px) {
            }
            @media (max-width: 750px) {
            }
            @media (max-width: 500px) {
            }
            &:hover {
              color: $secondary_color;
            }
          }
          .drop-item {
            position: relative;
            cursor: pointer;
            .drop-content {
              display: none;
              position: absolute;
              background-color: #fff;
              min-width: 160px;
              margin-top: 20px;
              box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
              z-index: 1;
              .drop-link {
                color: $primary_color;
                padding: 12px 16px;
                text-decoration: none;
                display: block;
                cursor: pointer;
                &:hover {
                  background-color: $secondary_color;
                  color: #fff;
                }
              }
            }
            &:hover .drop-content {
              display: block;
            }
          }
          .router-link-active,
          .router-link-exact-active {
            color: $secondary_color;
          }
        }
      }
    }
  }
}
.account-button {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    padding: 0.25rem 1rem;
    background-color: $primary_color;
    color: #fff !important;
    border: none;
    border-radius: 500px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.5s ease;
    display: inline-block;
    &:hover {
      background-color: $secondary_color;
    }
    @media (max-width: 1700px) {
      padding: 0.25rem 1rem;
      font-size: 0.8rem;
    }
    @media (max-width: 1300px) {
      font-size: 0.7rem;
    }
    @media (max-width: 900px) {
      font-size: 0.7rem;
    }
  }
}
</style>