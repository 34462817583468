import { BACKEND } from '../variables.js';
import axios from 'axios'
const USER_LOGIN = BACKEND + '/user/userLogin';
const USER_LOGOUT = BACKEND + '/user/userLogout';
const GET_USER = BACKEND + '/user/getUser';
const UPDATE_USER = BACKEND + '/user/updateUser';
const UPDATE_USER_PASSWORD = BACKEND + '/user/updatePassword';
const DELETE_USER = BACKEND + '/user/deleteUser';
const REGISTER_USER = BACKEND + '/user/register';

export default {
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        ['USER_LOGIN'](state, payload) {
            return axios({
                method: 'POST',
                url: USER_LOGIN,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['USER_LOGOUT'](state, payload) {
            return axios({
                method: 'POST',
                url: USER_LOGOUT,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['GET_USER'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_USER,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_USER'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_USER,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_USER_PASSWORD'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_USER_PASSWORD,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['DELETE_USER'](state, payload) {
            return axios({
                method: 'POST',
                url: DELETE_USER,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['REGISTER_USER'](state, payload) {
            return axios({
                method: 'POST',
                url: REGISTER_USER,
                data: payload.data
            })
        },
    },
};
