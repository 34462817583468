import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import mixin from './Mixins/mixin.vue'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Antd from "ant-design-vue";

import "./assets/scss/ant.scss";


// componets
import TopBar from './views/components/TopBar.vue'
import NavBar from './views/components/NavBar.vue'
import Footer from './views/components/Footer.vue'
import AccomodationCard from './views/components/AccomodationCard.vue'
import SpaceCard from './views/components/SpaceCard.vue'
import TourCard from './views/components/TourCard.vue'
import CarCard from './views/components/CarCard.vue'
import EventCard from './views/components/EventCard.vue'
import ratings from './views/components/Rating.vue'
import miLoader from './views/components/miLoader.vue'
import Model from './views/components/Model.vue'
import confirmModel from './views/components/confirmModel.vue'
import OUpload from './views/components/OUpload.vue'
import BookRoom from './views/components/BookRoom.vue'


const vuetify = createVuetify({
    components,
    directives,
})


const app = createApp(App)
app.component('TopBar', TopBar)
app.component('NavBar', NavBar)
app.component('Footer', Footer)
app.component('AccomodationCard', AccomodationCard)
app.component('SpaceCard', SpaceCard)
app.component('TourCard', TourCard)
app.component('CarCard', CarCard)
app.component('EventCard', EventCard)
app.component('Ratings', ratings)
app.component('miLoader', miLoader)
app.component('Model', Model)
app.component('confirmModel', confirmModel)
app.component('OUpload', OUpload)
app.component('BookRoom', BookRoom)

app.component('QuillEditor', QuillEditor)


app.use(Antd);
app.mixin(mixin)
app.use(vuetify)
app.config.productionTip = false


app.use(store).use(router).mount('#app')
