<template>
  <div class="home">
    <NavBar />
    <div class="container" v-if="blogPost">
      <!-- .blog posts -->
      <div class="blog-view">
        <div class="blog-view-header">
          <h2>{{ blogPost.title }}</h2>
          <p>
            <span>
              {{ blogPost.slug }}
            </span>
            <label for="">
              <i class="fa fa-calendar"></i> {{ $date(blogPost.created_at) }}
            </label>
          </p>
        </div>
        <div class="blog-view-content">
          <img :src="$upload(blogPost.image)" :alt="blogPost.title" />
          <p v-html="blogPost.description"></p>
          <span class="author">
            <i class="fa fa-user"></i> Published by {{ blogPost.author }}
          </span>
        </div>
      </div>
      <div class="blog-posts" v-if="allItems.length > 0">
        <h2 class="header">Related Blog Posts</h2>
        <div class="posts">
          <div class="post" v-for="post in allItems" :key="post.blog_id">
            <div class="image">
              <img :src="$upload(post.image)" :alt="post.title" />
            </div>
            <div class="details">
              <h3>
                <router-link
                  :to="{
                    name: 'ViewBlog',
                    params: { id: post.blog_id, name: $url(post.title) },
                  }"
                ></router-link
                >{{ post.title }}
              </h3>
              <p>{{ $date(post.created_at) }}</p>
              <p v-html="post.description" class="content"></p>
              <router-link to="/blog" class="btn">Read More</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      allItems: [],
      currentPage: 1,
      pagesNumber: 1,
      blogPost: null,
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_BLOG", {
          data: {
            blog_id: this.$route.params.id,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.blogPost = res.data.blog;
              this.allItems = res.data.blog.related;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.home {
  overflow-x: hidden;
  width: 100%;
  .container {
    padding: 0 15rem;
    @media (max-width: 1500px) {
      padding: 0 5rem;
    }
    @media (max-width: 1200px) {
      padding: 0 4rem;
    }
    @media (max-width: 1000px) {
      padding: 0 3rem;
    }
    @media (max-width: 750px) {
      padding: 0 2rem;
    }
    @media (max-width: 500px) {
      padding: 0 1rem;
    }

    .blog-view {
      margin: 50px 0;
      margin-top: 10rem;
      .blog-view-header {
        h2 {
          font-size: 3.5rem;
          margin-bottom: 20px;
          text-align: center;
          line-height: 1.25;
          @media (max-width: 750px) {
            font-size: 1.5rem;
          }
        }
        p {
          font-size: 1rem;
          color: #747474;
          text-align: center;
          display: flex;
          flex-direction: column;
          span {
            font-weight: 400;
            color: #333;
            font-size: 1.4rem;
          }
          label {
            margin-left: 10px;
          }
        }
      }
      .blog-view-content {
        img {
          width: 100%;
          height: 500px;
          object-fit: cover;
          margin-bottom: 20px;
        }
        p {
          font-size: 1rem;
          margin-bottom: 20px;
          color: #000;
          line-height: 1.5;
          :deep(p > h2),
          :deep(p > h1),
          :deep(p > h3),
          :deep(p > h4),
          :deep(p > h5),
          :deep(p > h6) {
            // font-size: 1.1rem;
          }
          :deep(p > img) {
            // display: none;
          }
        }
        .author {
          font-size: 1rem;
          color: #747474;
          i {
            margin-right: 5px;
          }
        }
      }
    }
    .blog-posts {
      margin: 50px 0;
      margin-top: 150px;
      .header {
        font-size: 2rem;
        margin-bottom: 20px;
        @media (max-width: 750px) {
          font-size: 1.5rem;
        }
      }
      .sub-header {
        font-size: 1.25rem;
        margin-bottom: 20px;
        @media (max-width: 750px) {
          font-size: 1.15rem;
        }
      }
      .posts {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;
        @media (max-width: 750px) {
          grid-template-columns: 1fr;
        }
        .post {
          border: 1px solid #e0e0e070;
          border-radius: 5px;
          overflow: hidden;
          box-shadow: 0px 0px 10px 0px #00000013;
          .image {
            img {
              width: 100%;
              height: 200px;
              object-fit: cover;
            }
          }
          .details {
            padding: 20px;
            h3 {
              font-size: 1.25rem;
              margin-bottom: 10px;
              @media (max-width: 750px) {
                font-size: 1.15rem;
              }
            }
            p.content {
              font-size: 0.8rem;
              margin-bottom: 10px;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              color: #747474;
              @media (max-width: 750px) {
                font-size: 0.8rem;
              }
              :deep(p > h2),
              :deep(p > h1),
              :deep(p > h3),
              :deep(p > h4),
              :deep(p > h5),
              :deep(p > h6) {
                font-size: 0.9rem;
              }
              :deep(p > img) {
                display: none;
              }
            }
            .btn {
              padding: 10px 20px;
              border: 1px solid $primary_color;
              color: #fff;
              text-decoration: none;
              border-radius: 5px;
              transition: all 0.3s;
              font-size: 1rem;
              font-weight: 500;
              margin-top: 5px;
              display: inline-block;
              &:hover {
                background-color: $primary_color;
                color: #fff;
              }

              @media (max-width: 750px) {
                padding: 4px 8px;
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
