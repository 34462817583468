<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span>ADMINS</span
        ><button class="my-btn cw glow wd" @click="startAdd = true">
          Add Admin
        </button>
      </h1>

      <div class="my-table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Added at</th>
              <th v-if="$getUser().type == 'main'">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in admins" :key="item.admin_id">
              <td>
                {{ index + 1 }}
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ $date(item.created_at) }}</td>
              <td v-if="$getUser().type == 'main'">
                <button
                  @click="
                    selectedItem = item;
                    startEdit = true;
                  "
                  class="btn default"
                >
                  Edit
                </button>
                <button
                  @click="
                    selectedItem = item;
                    startUpdatePassword = true;
                  "
                  v-if="$getUser().type == 'main'"
                  class="btn edit"
                >
                  Update Password
                </button>
                <button
                  @click="
                    selectedItem = item;
                    startDelete = true;
                  "
                  class="btn delete"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>
      <Model
        v-if="startAdd"
        @closeModel="startAdd = false"
        :modelTitle="'Add Admin'"
        :size="'sm'"
      >
        <div class="model-body">
          <div class="form-group">
            <label for="name">Names</label>
            <input
              type="text"
              id="name"
              v-model="newItem.name"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="text"
              id="email"
              v-model="newItem.email"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input
              type="text"
              id="password"
              v-model="newItem.password"
              class="form-control"
            />
          </div>

          <div class="btns flex">
            <button @click="startAdd = false" class="my-btn cw wd cancel">
              Cancel
            </button>
            <button @click="createItem" class="my-btn cw glow wd">
              Add Admin
            </button>
          </div>
        </div>
      </Model>
      <Model
        v-if="startEdit"
        @closeModel="startEdit = false"
        :modelTitle="'Edit Admin'"
        :size="'sm'"
      >
        <div class="model-body">
          <div class="form-group">
            <label for="name">Names</label>
            <input
              type="text"
              id="name"
              v-model="selectedItem.name"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="text"
              id="email"
              v-model="selectedItem.email"
              class="form-control"
            />
          </div>
          <div class="btns flex">
            <button @click="startEdit = false" class="my-btn cw wd cancel">
              Cancel
            </button>
            <button @click="updateItem" class="my-btn cw glow wd">
              Update Admin
            </button>
          </div>
        </div>
      </Model>
      <confirmModel
        v-if="startDelete"
        @closeModel="startDelete = false"
        :modelTitle="'Delete Admin'"
        :size="'sm'"
        :displayText="'Are you sure you want to delete this user?'"
        @confirmModel="deleteItem"
      />
      <Model
        v-if="startUpdatePassword"
        @closeModel="startUpdatePassword = false"
        :modelTitle="'Update Admin Password'"
        :size="'sm'"
      >
        <div class="model-body">
          <div class="form-group">
            <label for="password">New Password</label>
            <input
              type="text"
              id="password"
              v-model="selectedItem.password"
              class="form-control"
            />
          </div>
        </div>
        <div class="btns flex">
          <button
            @click="startUpdatePassword = false"
            class="my-btn cw wd cancel"
          >
            Cancel
          </button>
          <button @click="updateItemPassword" class="my-btn cw glow wd">
            Update Password
          </button>
        </div></Model
      >
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      admins: [],
      newItem: {
        name: "",
        email: "",
        password: "",
      },
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      startUpdatePassword: false,
      allItemsCount: 0,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ADMINS", {
          token: this.$getAdminToken(),
          data: {
            paginate: this.itemsPerpage,
            page: this.currentPage,
          },
        })
        .then((res) => {
          this.admins = res.data.admins.data;
          this.allItemsCount = res.data.admins.total;
          this.pagesNumber = Math.ceil(this.allItemsCount / this.itemsPerpage);
          this.$stopLoader();
        })
        .catch((err) => {
          toast.error("Something went wrong, please try again later.");
          console.log(err);
          this.$stopLoader();
        });
    },
    createItem() {
      if (this.newItem.name && this.newItem.email) {
        this.$startLoader();
        this.$store
          .dispatch("CREATE_ADMIN", {
            data: this.newItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateItem() {
      if (this.selectedItem.name && this.selectedItem.email) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_ADMIN", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startEdit = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_ADMIN", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    updateItemPassword() {
      if (this.selectedItem.password) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_ADMIN_PASSWORD", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startUpdatePassword = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 1.5rem;
      font-weight: 600;
    }
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: darken($primary_color, 10%);
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .my-table {
      table {
        width: 100%;
        border-collapse: collapse;
        thead {
          th {
            text-align: left;
            padding: 1rem 0;
            font-weight: 600;
            border-bottom: 1px solid #e5e8ee;
          }
        }
        tbody {
          tr {
            td {
              padding: 5px;
              border-bottom: 1px solid #e5e8ee;
              img {
                width: 100px;
                height: 50px;
                object-fit: cover;
                border-radius: 5px;
              }
              button {
                margin-right: 10px;
                &.edit {
                  background-color: $secondary_color;
                  color: white;
                  &:hover {
                    background-color: darken($secondary_color, 10%);
                  }
                }
                &.delete {
                  background-color: #a82929;
                  color: white;
                  &:hover {
                    background-color: #7a1f1f;
                  }
                }
                &.default {
                  background-color: $primary_color !important;
                  color: white;
                  &:hover {
                    background-color: darken($primary_color, 10%);
                  }
                }
              }
            }
          }
        }
      }
    }
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
        &.cancel {
          background-color: #a82929;
          color: white;
          &:hover {
            background-color: #7a1f1f;
          }
        }
      }
    }
  }
}
</style>