<template>
  <div class="About">
    <NavBar />
    <div class="jumbotron" style="background-image: url('/img/ab.jpg')">
      <div class="left">
        <h1>About Cumbinn</h1>
        <p>
          We seamlessly connect millions of visitors to Rwanda's outstanding
          points of interest, a variety of transportation options, and
          life-changing experiences.
        </p>
      </div>
    </div>
    <div class="about-section">
      <div class="why-choose-us">
        <h2 class="header">Why Choose Cumbinn?</h2>
        <p class="sub-header">
          Cumbinn.com seamlessly links millions of tourists to fantastic places
          of interest, a wide range of transport modes, and unforgettable
          experiences in Rwanda. Cumbinn.com, one of the leading travel
          marketplaces in Rwanda, makes it possible for businesses of all kinds
          and well-known brands to expand their clientele globally.
        </p>
        <div class="features">
          <!-- No Reservation Fees -->
          <div class="feature-item">
            <div class="left">
              <i class="fa-solid fa-filter-circle-dollar"></i>
              <div class="text">
                <p>Reason</p>
                <h3>01</h3>
              </div>
            </div>
            <div class="right">
              <h3>No Reservation Fees</h3>
              <p>
                You won't be subject to any booking fees from us. It's only a
                refundable <b>$1</b>. In many instances, you can cancel your
                reservation without incurring any fees.
              </p>
            </div>
          </div>
          <!-- Instant Confirmation -->
          <div class="feature-item">
            <div class="left">
              <i class="fa-solid fa-check-to-slot"></i>
              <div class="text">
                <p>Reason</p>
                <h3>02</h3>
              </div>
            </div>
            <div class="right">
              <h3>Instant Confirmation</h3>
              <p>
                Every reservation made on Cumbinn.com is promptly confirmed. It
                only takes a few clicks to book your ideal stay.
              </p>
            </div>
          </div>
          <!-- Secure Booking -->
          <div class="feature-item">
            <div class="left">
              <i class="fa-solid fa-lock"></i>
              <div class="text">
                <p>Reason</p>
                <h3>03</h3>
              </div>
            </div>
            <div class="right">
              <h3>Secure Booking</h3>
              <p>
                Through our secure platform, we enable hundreds of thousands of
                transactions each day, and we adhere to the highest standards to
                ensure your privacy.
              </p>
            </div>
          </div>
          <!-- Low Rates -->
          <div class="feature-item">
            <div class="left">
              <i class="fa-solid fa-dollar-sign"></i>
              <div class="text">
                <p>Reason</p>
                <h3>04</h3>
              </div>
            </div>
            <div class="right">
              <h3>Low Rates</h3>
              <p>
                You can rely on us to provide you with the most competitive
                rates. And with our pledge to price match, you can rest assured
                that you're always receiving a fantastic deal.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="faqs">
        <h2 class="header">Frequently Asked Questions</h2>
        <div class="faqs">
          <div
            class="faq"
            v-for="(faq, index) in faqs"
            :key="index"
            :class="{ active: faq.active }"
            @click="faq.active = !faq.active"
          >
            <div class="question">
              <h3>{{ faq.question }}</h3>
              <i class="fas fa-chevron-down"></i>
            </div>
            <div class="answer">
              <p>{{ faq.answer }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="services">
        <h2 class="header">Cumbinn Services</h2>
        <div class="service-items">
          <div
            class="service-item"
            v-for="(service, index) in services"
            :key="index"
          >
            <div class="img">
              <img :src="`/img/${service.icon}`" alt="" />
            </div>
            <h3>{{ service.title }}</h3>
            <p>{{ service.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="newsletters">
      <div class="container">
        <h2>Spend less, get more with Cumbinn</h2>
        <p>
          Subscribe to our newsletter and get the best deals on your favorite
          destinations.
        </p>
        <form action="">
          <input type="email" placeholder="Enter your email" />
          <button class="primary">Subscribe</button>
        </form>
      </div>
    </div>
    <div class="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d255203.55263329137!2d30.12724445!3d-1.9297706000000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dca4258ed8e797%3A0xf32b36a5411d0bc8!2sKigali!5e0!3m2!1sen!2srw!4v1708441285862!5m2!1sen!2srw"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      faqs: [
        {
          question: "What is Cumbinn?",
          answer:
            "Cumbinn.com seamlessly links millions of tourists to fantastic places of interest, a wide range of transport modes, and unforgettable experiences in Rwanda. Cumbinn.com, one of the leading travel marketplaces in Rwanda, makes it possible for businesses of all kinds and well-known brands to expand their clientele globally.",
        },
        {
          question: "How do I book a hotel?",
          answer:
            "You can book a hotel by visiting our website and searching for the hotel you want to book. Once you find the hotel, you can click on the hotel and book it.",
        },
        {
          question: "How do I book a car?",
          answer:
            "You can book a car by visiting our website and searching for the car you want to book. Once you find the car, you can click on the car and book it.",
        },
        {
          question: "How do I book a tour?",
          answer:
            "You can book a tour by visiting our website and searching for the tour you want to book. Once you find the tour, you can click on the tour and book it.",
        },
      ],
      services: [
        {
          icon: "booking.png",
          title: "Accomodation Booking",
          description:
            "Book your favorite hotel, guest house, lodge, resort, or apartment with us. We have a wide range of accomodation options to choose from. We have the best rates and we guarantee you the best experience. Book now and pay later. We have a wide range of accomodation options to choose from. We have the best rates and we guarantee you the best experience. Book now and pay later.",
        },
        {
          icon: "car.png",
          title: "Transport Booking",
          description:
            "Book your favorite car with us. We have a wide range of cars to choose from. We have the best rates and we guarantee you the best experience. Book now and pay later. We have a wide range of cars to choose from. We have the best rates and we guarantee you the best experience. Book now and pay later.",
        },
        {
          icon: "tour.png",
          title: "Tour Booking",
          description:
            "Book your favorite tour with us. We have a wide range of tours to choose from. We have the best rates and we guarantee you the best experience. Book now and pay later. We have a wide range of tours to choose from. We have the best rates and we guarantee you the best experience. Book now and pay later.",
        },
      ],
    };
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.About {
  .jumbotron {
    margin-top: 90px;
    display: flex;
    justify-content: space-between;
    background-attachment: fixed;
    background-size: cover;
    .left {
      padding-left: 10rem;
      width: 60%;
      clip-path: ellipse(100% 100% at 0% 50%);
      height: 400px;
      background: #eeeeeeef;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 1000px) {
        padding-left: rem;
        // no clip
        clip-path: none;
        width: 100%;
      }
      @media (max-width: 750px) {
        padding-left: 2rem;
        height: fit-content;
        padding: 2rem;
      }
      @media (max-width: 500px) {
        padding: 1rem;
      }

      h1 {
        font-size: 2.5rem;
        margin-bottom: 1rem;
        @media (max-width: 750px) {
          font-size: 2rem;
        }
        @media (max-width: 500px) {
          font-size: 1.5rem;
        }
      }
      p {
        font-size: 1.2rem;
        color: #333;
        width: 50%;
        @media (max-width: 750px) {
          width: 100%;
          font-size: 1rem;
        }
        @media (max-width: 500px) {
          font-size: 0.9rem;
        }
      }
    }
  }
  .about-section {
    display: flex;
    justify-content: space-between;
    margin: 3rem 10rem;
    margin-top: 1rem;
    background: #fff;
    border-radius: 10px;
    position: relative;
    padding: 0 3rem;
    padding-bottom: 3rem;
    @media (max-width: 1000px) {
      margin: 3rem 5rem;
      padding: 0 2rem;
    }
    @media (max-width: 750px) {
      margin: 1rem 1rem;
      padding: 0 1rem;
    }
    @media (max-width: 500px) {
      margin: 0rem 0rem;
      padding: 0 1rem;
    }
  }
  .container {
    padding: 0 10rem;
    @media (max-width: 1500px) {
      padding: 0 5rem;
    }
    @media (max-width: 1200px) {
      padding: 0 4rem;
    }
    @media (max-width: 1000px) {
      padding: 0 3rem;
    }
    @media (max-width: 750px) {
      padding: 0 1.5rem;
    }
    @media (max-width: 500px) {
      padding: 0 1rem;
    }
    .faqs {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
      background: #f8f8f8;
      padding: 20px;
      @media (max-width: 1000px) {
        padding: 10px;
        margin-top: 30px;
      }
      @media (max-width: 750px) {
        padding: 10px;
        margin-top: 20px;
      }
      @media (max-width: 500px) {
        padding: 10px;
        margin-top: 10px;
      }
      .faq {
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid #d4d4d4;
        margin-bottom: 10px;
        @media (max-width: 1000px) {
          padding: 10px;
        }
        @media (max-width: 750px) {
          padding: 10px;
        }
        cursor: pointer;
        .question {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          h3 {
            font-size: 1.1rem;
          }
          i {
            font-size: 0.9rem;
          }
        }
        .answer {
          height: 0;
          overflow: hidden;
          padding: 0;
          transition: all 0.3s;
          p {
            font-size: 0.9rem;
            color: #333;
          }
        }
        &.active {
          .answer {
            padding: 20px;
            height: auto;
            @media (max-width: 1000px) {
              padding: 10px;
            }
            @media (max-width: 750px) {
              padding: 5px;
            }
            @media (max-width: 500px) {
              padding: 2px;
            }
          }
        }
      }
    }
    .services {
      margin-top: 50px;
      margin-bottom: 3rem;
      .service-items {
        display: flex;
        justify-content: space-between;
        @media (max-width: 750px) {
          flex-direction: column;
        }

        .service-item {
          width: 30%;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          @media (max-width: 750px) {
            width: 100%;
            margin-bottom: 2rem;
          }
          .img {
            border: 1px solid $primary_color;
            border-radius: 50%;
            padding: 10px;
            box-shadow: 0px 0px 20px 5px #0c3b7c52;
            background: $primary_color;
            width: 70px;
            height: 70px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            margin-bottom: 20px;
            filter: invert(1);
          }
          h3 {
            font-size: 1.2rem;
            margin-bottom: 10px;
          }
          p {
            font-size: 0.9rem;
            color: #333;
          }
        }
      }
    }
  }
  .map {
    width: 1005;
    iframe {
      width: 100%;
      border: 0;
    }
  }
}
</style>
