<template>
  <div class="login">
    <div class="login-section">
      <img :src="$img('kigali.png')" alt="Logo" />
      <div class="right">
        <form action="" @submit.prevent="login">
          <h2>Login to your <span>Cumbinn</span> account</h2>
          <!-- <p class="tagline">
            Ireme Property Management System is a comprehensive software
            solution designed to streamline and simplify property management
            tasks.Ireme is the go-to solution for managing residential and
            commercial properties of all sizes.
          </p> -->
          <div class="input-group">
            <div class="form-group">
              <input
                type="email"
                id="email"
                placeholder="Email"
                v-model="payload.email"
              />
            </div>
            <div class="form-group">
              <input
                type="password"
                id="password"
                placeholder="Password"
                v-model="payload.password"
              />
            </div>
          </div>
          <div class="keep">
            <v-checkbox
              v-model="payload.keep"
              color="indigo"
              label=""
              value="indigo"
              hide-details
            ></v-checkbox>
            <label for="keep">Keep me logged in</label>
          </div>
          <div class="input-group">
            <button type="submit" class="my-btn bp cw round">Login</button>
          </div>
          <div class="forgot">
            <label>Forgot password?</label>
            <router-link :to="{ name: 'AdminLogin' }"
              >Reset password</router-link
            >
          </div>
        </form>
      </div>
      <div class="particals">
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      payload: {
        email: "",
        password: "",
        keep: false,
      },
    };
  },
  methods: {
    login() {
      this.$startLoader();
      this.$store
        .dispatch("ADMIN_LOGIN", this.payload)
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.$AdminLogin(res.data.admin, res.data.token);
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    document.title = "Admin Login";
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/main.scss";
.login {
  .login-section {
    display: flex;
    justify-content: space-between;
    height: 100vh;
    overflow: hidden;
    img {
      width: 40%;
      height: 100vh;
      object-fit: cover;
      top: 0;
      left: 0;
    }
    .right {
      box-shadow: 0 0 20px 0 #0000004d;
      background: transparent;
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    form {
      padding: 40px;
      border-radius: 5px;
      width: 50%;
      h2 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 1.5rem;
        color: $secondary_color;
        span {
          color: $primary_color;
        }
      }
      p.tagline {
        font-size: 0.7rem;
        margin-bottom: 20px;
        text-align: center;
        color: #808080;
        margin-bottom: 4rem;
        display: block;
      }
      .input-group {
        .form-group {
          margin-bottom: 20px;
          input {
            width: 100%;
            padding: 10px;
            border-left: 5px solid $primary_color;
            border-radius: 0px;
            outline: none;
            background: #f5f5f5;
          }
        }
        button {
          width: 100%;
          padding: 15px 10px;
          border: none;
          cursor: pointer;
          color: #fff;
          font-size: 1rem;
        }
      }
      .keep {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        input {
          margin-right: 10px;
        }
      }
      .forgot {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        a {
          color: $primary_color;
          text-decoration: none;
        }
      }
    }
  }
  .particals {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    opacity: 0.1;
    .particle {
      position: absolute;
      background: $primary_color;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      animation: animate 10s linear infinite;
      opacity: 0.5;
      @for $i from 1 through 50 {
        &:nth-child(#{$i}) {
          top: random(100) + vh;
          left: random(100) + vw;
          animation-duration: random(30) + s;
        }
      }
    }
  }
  @keyframes animate {
    0% {
      transform: translate(0, 0);
      opacity: 1;
    }
    100% {
      transform: translate(100vw, 100vh);
      opacity: 0;
    }
  }
}
</style>