import { BACKEND } from '../variables.js';
import axios from 'axios'
const GET_ALL_ROOMS = BACKEND + '/room/getAllRooms';
const CREATE_ROOM = BACKEND + '/room/createRoom';
const UPDATE_ROOM = BACKEND + '/room/updateRoom';
const DELETE_ROOM = BACKEND + '/room/deleteRoom';
const GET_ROOM = BACKEND + '/room/getRoom';
const GET_ROOMS_BY_ACCOMODATION = BACKEND + '/room/getRoomsByAccomodation';


export default {
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        ['GET_ALL_ROOMS'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_ALL_ROOMS + `?page=${payload.data.page}`,
                data: payload.data
            })
        },
        ['CREATE_ROOM'](state, payload) {
            return axios({
                method: 'POST',
                url: CREATE_ROOM,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_ROOM'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_ROOM,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['DELETE_ROOM'](state, payload) {
            return axios({
                method: 'POST',
                url: DELETE_ROOM,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['GET_ROOM'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_ROOM,
                data: payload.data
            })
        },
        ['GET_ROOMS_BY_ACCOMODATION'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_ROOMS_BY_ACCOMODATION,
                data: payload.data
            })
        },
    },
};
