<template>
  <div class="ratings">
    <ul>
      <li v-for="i in stars" :key="i">
        <i class="fa-solid fa-star"></i>
      </li>
      <li v-for="j in whites" :key="j">
        <i class="fa-regular fa-star"></i>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      stars: 0,
      whites: 0,
    };
  },
  mounted() {
    this.stars = parseInt(this.count);
    this.whites = 5 - parseInt(this.count);
  },
};
</script>

<style scoped lang="scss">
.ratings {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 0.5rem;
    i {
      color: #f1c40f;
    }
  }
}
</style>