import { BACKEND } from '../variables.js';
import axios from 'axios'
const ADMIN_LOGIN = BACKEND + '/admin/login';
const ADMIN_LOGOUT = BACKEND + '/admin/logout';
const GET_ADMINS = BACKEND + '/admin/getAllAdmins';
const CREATE_ADMIN = BACKEND + '/admin/createAdmin';
const UPDATE_ADMIN = BACKEND + '/admin/updateAdmin';
const DELETE_ADMIN = BACKEND + '/admin/deleteAdmin';
const UPDATE_ADMIN_PASSWORD = BACKEND + '/admin/updateAdminPassword';
const UPDATE_ADMIN_PROFILE = BACKEND + '/admin/updateAdminProfile';
const UPDATE_ADMIN_PROFILE_PASSWORD = BACKEND + '/admin/updateAdminProfilePassword';

export default {
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        ['ADMIN_LOGIN'](state, payload) {
            return axios({
                method: 'POST',
                url: ADMIN_LOGIN,
                data: payload
            })
        },
        ['ADMIN_LOGOUT'](state, payload) {
            return axios({
                method: 'POST',
                url: ADMIN_LOGOUT,
                data: payload
            })
        },
        ['GET_ADMINS'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_ADMINS + `?page=${payload.data.page}`,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['CREATE_ADMIN'](state, payload) {
            return axios({
                method: 'POST',
                url: CREATE_ADMIN,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_ADMIN'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_ADMIN,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['DELETE_ADMIN'](state, payload) {
            return axios({
                method: 'POST',
                url: DELETE_ADMIN,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_ADMIN_PASSWORD'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_ADMIN_PASSWORD,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_ADMIN_PROFILE'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_ADMIN_PROFILE,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_ADMIN_PROFILE_PASSWORD'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_ADMIN_PROFILE_PASSWORD,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },

    },
};
