<template>
  <div class="Sidebar">
    <div class="sidebar-header">
      <img
        :src="$img('logow.png')"
        alt="K-Realty Logo"
        @error="$imageurlalt()"
      />
    </div>
    <ul class="nav-links">
      <li>
        <router-link
          :to="{ name: 'AdminDashboard' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/dashboard') }"
        >
          <span>
            <i class="fas fa-tachometer-alt"></i>
            Overview</span
          >
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminReservations' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/reservations') }"
        >
          <span>
            <i class="fas fa-calendar-check"></i>
            Reservations</span
          >
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminAccomodations' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/accomodations') }"
        >
          <span>
            <i class="fas fa-bed"></i>
            Stays</span
          >
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminSpaces' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/spaces') }"
        >
          <span>
            <i class="fas fa-building"></i>
            Space / Offices</span
          >
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminTours' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/tours') }"
        >
          <span>
            <i class="fas fa-route"></i>
            Tours</span
          >
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminCars' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/cars') }"
        >
          <span>
            <i class="fas fa-car"></i>
            Cars</span
          >
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminEvents' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/events') }"
        >
          <span>
            <i class="fas fa-calendar-alt"></i>
            Events</span
          >
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminLocations' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/locations') }"
        >
          <span>
            <i class="fas fa-map-marker-alt"></i>
            Locations</span
          >
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminCategories' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/categories') }"
        >
          <span>
            <i class="fas fa-list"></i>
            Categories</span
          >
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminBlogs' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/blogs') }"
        >
          <span>
            <i class="fas fa-blog"></i>
            Blog Posts</span
          >
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminAdmins' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/admins') }"
        >
          <span>
            <i class="fas fa-users"></i>
            Admins</span
          >
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminSettings' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/settings') }"
        >
          <span>
            <i class="fas fa-cogs"></i>
            Settings</span
          >
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      userRole: "",
      activePage: "",
    };
  },
  methods: {
    toggleSubLinks(id) {
      if (this.activePage == id) {
        this.activePage = "";
      } else {
        this.activePage = id;
      }
    },
  },
  mounted() {
    if (this.$urlContains("admin/properties")) {
      this.activePage = "properties";
    }
  },
  watch: {
    $route(to, from) {
      this.activePage = null;
      if (this.$urlContains("admin/properties")) {
        this.activePage = "properties";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.Sidebar {
  width: 100%;
  height: 100%;
  border-right: 1px solid white;
  border-right: 1px solid var(--neutral-gray-gray-50, #e5e8ee);
  box-shadow: 4px 0px 30px 0px rgba(131, 98, 234, 0.05);
  background: rgb(12, 54, 130);
  background: linear-gradient(
    220deg,
    rgba(12, 54, 130, 1) 0%,
    rgba(6, 10, 61, 1) 100%
  );
  .sidebar-header {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 50%;
      max-width: 200px;
    }
  }
  .nav-links {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    li {
      width: 100%;
      display: flex;
      flex-direction: column;
      .nav-link {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 1rem;
        padding-left: 2rem;
        padding: 1rem 1rem;
        cursor: pointer;
        text-decoration: none;
        @media (max-width: 1500px) {
          padding: 0.6rem 1rem;
          height: auto;
        }
        span {
          font-size: 0.92rem;
          font-weight: 400;
          color: #ffffffad;
          text-transform: capitalize;
          i {
            margin-right: 5px;
          }
          @media (max-width: 1500px) {
            font-size: 0.8rem;
          }
        }
        &:hover,
        &.active {
          background-color: rgba(255, 255, 255, 0.123);
          border-left: 4px solid $primary_color;
          span {
            color: #fff;
          }
        }
        &.nav-link-with-sublinks {
          display: flex;
          justify-content: space-between;
          i {
            margin-left: auto;
            font-size: 0.8rem;
            color: var(--neutral-gray-gray-100, #c4c4c4);
            cursor: pointer;
          }
        }
      }
      .sub-links {
        width: 100%;
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
        background-color: #1692bb1e;
        &.active {
          padding: 10px;
          height: 100%;
          li {
            .nav-link {
              padding-left: 3rem;
              color: $primary_color;
              border-left: 4px solid transparent;
              span {
                font-weight: 400;
                font-size: 0.9rem;
                @media (max-width: 1500px) {
                  font-size: 0.8rem;
                }
              }
              &:hover,
              &.active {
                background-color: #1692bb3d;
                span {
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>