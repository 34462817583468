<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title">
        <span>reservations</span>
      </h1>

      <div class="my-table">
        <table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Stay</th>
              <th>Dates</th>
              <th>Room</th>
              <th>Client</th>
              <th>Reservation Status</th>
              <th>Amount</th>
              <th>Payment</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in reservations" :key="item.id">
              <td>
                <img
                  :src="$upload(item.accomodation.image)"
                  alt="Location Image"
                />
              </td>
              <td>{{ item.accomodation.title }}</td>
              <td>
                <div class="lines">
                  <span>
                    <label>Checkin:</label> {{ $date(item.check_in) }}
                  </span>
                  <span>
                    <label>Checkout:</label> {{ $date(item.check_out) }}
                  </span>
                </div>
              </td>
              <td>
                <div class="lines">
                  <span> <label>Room:</label> {{ item.room.name }} </span>
                  <span>
                    <label>Price:</label> {{ $comma(item.room.price) }}
                    {{ item.room.currency }}
                  </span>
                  <span> <label>Count:</label> {{ item.room_count }}</span>
                </div>
              </td>
              <td>
                <div class="lines">
                  <span>
                    {{ item.user.name }}
                  </span>
                  <span>
                    {{ item.user.email }}
                  </span>
                  <span>
                    {{ item.user.phone }}
                  </span>
                </div>
              </td>
              <td>
                <b :class="'badge ' + item.status">{{ item.status }}</b>
              </td>
              <td>
                <div class="lines">
                  <span>
                    <label>Total Amount:</label> {{ $comma(item.total_price) }}
                    {{ item.currency }}
                  </span>
                  <span>
                    <label>Mode:</label> {{ $comma(item.payment_mode) }}
                  </span>
                </div>
              </td>
              <td>
                <div class="lines">
                  <span>
                    <label>Paid Amount:</label> {{ $comma(item.paid_amount) }}
                    {{ item.currency }}
                  </span>
                  <span>
                    <label>Account:</label> {{ item.payment_account }}
                  </span>
                  <span>
                    <label>Reference:</label>
                    {{ item.order_tracking_id }}
                  </span>
                  <span>
                    <label>Status:</label>
                    <b :class="'badge ' + item.payment_status">{{
                      item.payment_status
                    }}</b>
                  </span>
                </div>
              </td>
              <td>
                <button
                  @click="
                    selectedItem = item;
                    startEdit = true;
                  "
                  class="btn edit"
                >
                  Edit
                </button>
                <button
                  @click="
                    selectedItem = item;
                    startDelete = true;
                  "
                  class="btn delete"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>
      <Model
        v-if="startEdit"
        @closeModel="startEdit = false"
        :modelTitle="'Edit Reservation'"
        :size="'md'"
      >
        <div class="model-body">
          <div class="form-group group-3">
            <div class="input-group">
              <label for="title">Status</label>
              <select v-model="selectedItem.status" class="form-control">
                <option value="pending">Pending</option>
                <option value="inprogress">Processing</option>
                <option value="confirmed">Confirmed</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </div>
            <div class="input-group">
              <label for="title">Payment Status</label>
              <select
                v-model="selectedItem.payment_status"
                class="form-control"
              >
                <option value="pending">Pending</option>
                <option value="inprogress">Processing</option>
                <option value="cancelled">Cancelled</option>
                <option value="partially_completed">Partially Completed</option>
                <option value="completed">Completed</option>
              </select>
            </div>
            <div class="input-group">
              <label for="title">Payment Mdde</label>
              <select v-model="selectedItem.payment_mode" class="form-control">
                <option value="cash">Cash</option>
                <option value="card">Card</option>
                <option value="Mobile Money">Mobile Money</option>
              </select>
            </div>
          </div>
          <div class="form-group group-4">
            <div class="input-group">
              <label for="checkin">Checkin</label>
              <input
                type="date"
                v-model="selectedItem.check_in"
                placeholder="Checkin"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="checkout">Checkout</label>
              <input
                type="date"
                v-model="selectedItem.check_out"
                placeholder="Checkout"
                class="form-control"
              />
            </div>

            <div class="input-group">
              <label for="checkout">Adults</label>
              <input
                type="number"
                v-model="selectedItem.adults"
                placeholder="Adults"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="checkout">Children</label>
              <input
                type="number"
                v-model="selectedItem.children"
                placeholder="Children"
                class="form-control"
              />
            </div>
          </div>
          <div class="btns flex">
            <button @click="startEdit = false" class="my-btn cw wd cancel">
              Cancel
            </button>
            <button @click="updateItem" class="btn cw glow wd">Update</button>
          </div>
        </div>
      </Model>
      <confirmModel
        v-if="startDelete"
        @closeModel="startDelete = false"
        :modelTitle="'Delete Reservation'"
        :size="'sm'"
        :displayText="'Are you sure you want to delete this Reservation?'"
        @confirmModel="deleteItem"
      />
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      locations: [],
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      allItemsCount: 0,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      reservations: [],
      categories: [],
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_RESERVATIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              if (res.data.reservations) {
                this.reservations = res.data.reservations.data;
                this.allItemsCount = res.data.reservations.total;
                this.pagesNumber = Math.ceil(
                  this.allItemsCount / this.itemsPerpage
                );
              }
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    updateItem() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_RESERVATION", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startEdit = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_RESERVATION", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 1.5rem;
      font-weight: 600;
      b {
        color: $primary_color;
        font-weight: 600;
      }
    }
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
  .dash-content {
    padding: 0 2rem;
    .my-table {
      table {
        width: 100%;
        border-collapse: collapse;
        thead {
          th {
            text-align: left;
            padding: 1rem 0;
            font-weight: 600;
            border-bottom: 1px solid #e5e8ee;
          }
        }
        tbody {
          tr {
            td {
              padding: 5px;
              border-bottom: 1px solid #e5e8ee;
              img {
                width: 100px;
                height: 50px;
                object-fit: cover;
                border-radius: 5px;
              }
              button {
                margin-right: 10px;
                &.edit {
                  background-color: #1d2088;
                  color: white;
                  &:hover {
                    background-color: #1eb9ee;
                  }
                }
                &.delete {
                  background-color: #a82929;
                  color: white;
                  &:hover {
                    background-color: #7a1f1f;
                  }
                }
                &.default {
                  background-color: #1d2088;
                  color: white;
                  &:hover {
                    background-color: #1eb9ee;
                  }
                }
              }
            }
          }
        }
      }
    }
    .btns {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 10px;
        padding: 0.75rem 1rem;
        &.cancel {
          background-color: #a82929;
          color: white;
          &:hover {
            background-color: #7a1f1f;
          }
        }
      }
    }
  }
}

.form-group {
  column-gap: 10px;
}
.control-group {
  display: flex;
  align-items: center;
  select {
    padding: 0.5rem;
    width: 100px;
    text-align: center;
    border-radius: 5px 0px 0px 5px;
    background: #eee;
  }
  input {
    padding: 0.5rem;
    border-radius: 0px 5px 5px 0px;
  }
}
b.badge {
  padding: 0.1rem 0.5rem;
  border-radius: 50px;
  color: white;
  font-size: 0.8rem;
  font-weight: 500;
  &.pending {
    background-color: #f0ad4e;
  }
  &.inprogress {
    background-color: #5bc0de;
  }
  &.cancelled {
    background-color: #d9534f;
  }
  &.partially_completed {
    background-color: #f0ad4e;
  }
  &.completed {
    background-color: #5cb85c;
  }
}
</style>