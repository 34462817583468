import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/site/Home.vue";
import Contact from "../views/site/Contact.vue";
import Destinations from "../views/site/Destinations.vue";
import Stays from "../views/site/Stays.vue";
import About from "../views/site/About.vue";
import ViewAccomodation from "../views/site/ViewAccomodation.vue";
import BookAccomodation from "../views/site/BookAccomodation.vue";
import Blog from "../views/site/Blog.vue";
import ViewBlog from "../views/site/ViewBlog.vue";
import Login from "../views/site/Login.vue";
import Register from "../views/site/Register.vue";
import Spaces from "../views/site/Spaces.vue";
import ViewSpace from "../views/site/ViewSpace.vue";
import Tours from "../views/site/Tours.vue";
import ViewTour from "../views/site/ViewTour.vue";
import Cars from "../views/site/Cars.vue";
import ViewCar from "../views/site/ViewCar.vue";
import Events from "../views/site/Events.vue";
import ViewEvent from "../views/site/ViewEvent.vue";
import reservationCompleted from "../views/site/reservationCompleted.vue";

// adminroutes
import AdminLogin from "../views/admin/Login.vue";
import Admin from "../views/admin/Home.vue";
import AdminDashboard from "../views/admin/Dashboard.vue";
import AdminLocations from "../views/admin/AdminLocations.vue";
import AdminAccomodations from "../views/admin/AdminAccomodations.vue";
import AdminAdmins from "../views/admin/AdminAdmins.vue";
import AdminSettings from "../views/admin/AdminSettings.vue";
import AdminCategories from "../views/admin/AdminCategories.vue";
import AdminRooms from "../views/admin/AdminRooms.vue";
import AdminReservations from "../views/admin/AdminReservations.vue";
import AdminBlogs from "../views/admin/AdminBlogs.vue";
import AdminSpaces from "../views/admin/AdminSpaces.vue";
import AdminTours from "../views/admin/AdminTours.vue";
import AdminCars from "../views/admin/AdminCars.vue";
import AdminEvents from "../views/admin/AdminEvents.vue";
import AdminSpaceReservations from "../views/admin/AdminSpaceReservations.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/destinations",
    name: "Destinations",
    component: Destinations,
  },
  {
    path: "/stays",
    name: "Stays",
    component: Stays,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/view-accomodation/:id/:name",
    name: "ViewAccomodation",
    component: ViewAccomodation,
  },
  {
    path: "/book-accomodation/:id/:name",
    name: "BookAccomodation",
    component: BookAccomodation,
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/view-blog/:id",
    name: "ViewBlog",
    component: ViewBlog,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/spaces",
    name: "Spaces",
    component: Spaces,
  },
  {
    path: "/view-space/:id/:name",
    name: "ViewSpace",
    component: ViewSpace,
  },
  {
    path: "/tours",
    name: "Tours",
    component: Tours,
  },
  {
    path: "/view-tour/:id/:name",
    name: "ViewTour",
    component: ViewTour,
  },
  {
    path: "/cars",
    name: "Cars",
    component: Cars,
  },
  {
    path: "/view-car/:id/:name",
    name: "ViewCar",
    component: ViewCar,
  },
  {
    path: "/events",
    name: "Events",
    component: Events,
  },
  {
    path: "/view-event/:id/:name",
    name: "ViewEvent",
    component: ViewEvent,
  },
  {
    path: "/reservations/complete",
    name: "reservationCompleted",
    component: reservationCompleted,
  },
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    redirect: "/admin/dashboard",
    children: [
      {
        path: "/admin/dashboard",
        name: "AdminDashboard",
        component: AdminDashboard,
      },
      {
        path: "/admin/locations",
        name: "AdminLocations",
        component: AdminLocations,
      },
      {
        path: "/admin/accomodations",
        name: "AdminAccomodations",
        component: AdminAccomodations,
      },
      {
        path: "/admin/admins",
        name: "AdminAdmins",
        component: AdminAdmins,
      },
      {
        path: "/admin/settings",
        name: "AdminSettings",
        component: AdminSettings,
      },
      {
        path: "/admin/categories",
        name: "AdminCategories",
        component: AdminCategories,
      },
      {
        path: "/admin/accomodations/rooms/:id",
        name: "AdminRooms",
        component: AdminRooms,
      },
      {
        path: "/admin/reservations",
        name: "AdminReservations",
        component: AdminReservations,
      },
      {
        path: "/admin/blogs",
        name: "AdminBlogs",
        component: AdminBlogs,
      },
      {
        path: "/admin/spaces",
        name: "AdminSpaces",
        component: AdminSpaces,
      },
      {
        path: "/admin/tours",
        name: "AdminTours",
        component: AdminTours,
      },
      {
        path: "/admin/cars",
        name: "AdminCars",
        component: AdminCars,
      },
      {
        path: "/admin/events",
        name: "AdminEvents",
        component: AdminEvents,
      },
      {
        path: "/admin/:name/space-reservations/:id",
        name: "AdminSpaceReservations",
        component: AdminSpaceReservations,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
