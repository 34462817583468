import { BACKEND } from '../variables.js';
import axios from 'axios'
const GET_SPACES = BACKEND + '/space/getSpaces';
const GET_FILTERED_SPACES = BACKEND + '/space/getFilteredSpaces';
const GET_SPACE_BY_ID = BACKEND + '/space/getSpaceById';
const GET_SPACE_BY_TYPE = BACKEND + '/space/getSpaceByType';
const CREATE_SPACE = BACKEND + '/space/createSpace';
const UPDATE_SPACE = BACKEND + '/space/updateSpace';
const DELETE_SPACE = BACKEND + '/space/deleteSpace';
const GET_CAR_BRANDS = BACKEND + '/space/getCarBrands';
const GET_CAR_TYPES = BACKEND + '/space/getCarTypes';
const GET_ATTRACTIONS_BY_TYPE = BACKEND + '/space/getAttractionsByType';

export default {
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        ['GET_SPACES'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_SPACES,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['GET_FILTERED_SPACES'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_FILTERED_SPACES,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['GET_SPACE_BY_ID'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_SPACE_BY_ID,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['GET_SPACE_BY_TYPE'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_SPACE_BY_TYPE,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['CREATE_SPACE'](state, payload) {
            return axios({
                method: 'POST',
                url: CREATE_SPACE,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_SPACE'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_SPACE,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['DELETE_SPACE'](state, payload) {
            return axios({
                method: 'POST',
                url: DELETE_SPACE,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['GET_CAR_BRANDS'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_CAR_BRANDS,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['GET_CAR_TYPES'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_CAR_TYPES,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['GET_ATTRACTIONS_BY_TYPE'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_ATTRACTIONS_BY_TYPE,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },

    },
};
