<template>
  <div class="dashboard">
    <div class="dash-content">
      <h1 class="page-title" v-if="accomodation">
        <span>{{ accomodation.title }} Rooms</span
        ><button class="my-btn cw glow wd" @click="startAdd = true">
          Add Room
        </button>
      </h1>

      <div class="my-table">
        <table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Accomodation</th>
              <th>Name</th>
              <th>Beds</th>
              <th>Price</th>
              <th>Size</th>
              <th>Payment</th>
              <th>Added by</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in Rooms" :key="item.id">
              <td>
                <img :src="$upload(item.image)" alt="Room Image" />
              </td>
              <td>{{ item.accomodation.title }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.beds }}</td>
              <td>{{ $comma(item.price) }} {{ item.currency }}</td>
              <td>{{ item.size }}</td>
              <td>{{ item.payment_mode }}</td>
              <td>{{ item.created_by.name }}</td>
              <td>
                <button
                  @click="
                    selectedItem = item;
                    startEdit = true;
                  "
                  class="btn edit"
                >
                  Edit
                </button>
                <button
                  @click="
                    selectedItem = item;
                    startDelete = true;
                  "
                  class="btn delete"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>
      <Model
        v-if="startAdd"
        @closeModel="startAdd = false"
        :modelTitle="'Add Room'"
        :size="'md'"
      >
        <div class="model-body">
          <div class="form-group group-3">
            <div class="input-group">
              <label for="Name">Name</label>
              <input
                type="text"
                v-model="newItem.name"
                placeholder="Name"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="Beds">Beds</label>
              <input
                type="text"
                v-model="newItem.beds"
                placeholder="Beds"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="Size">Size</label>
              <input
                type="text"
                v-model="newItem.size"
                placeholder="Size"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group group-2">
            <div class="input-group">
              <label for="Breakfast Included">Breakfast Included</label>
              <select
                name=""
                id=""
                v-model="newItem.breakfast"
                class="form-control"
              >
                <option :value="1">Yes</option>
                <option :value="0">No</option>
              </select>
            </div>
            <div class="input-group">
              <label for="Payment Policy">Payment Policy</label>
              <div class="control-group price flex">
                <input
                  type="number"
                  v-model="newItem.payment_mode"
                  placeholder="%"
                  class="form-control"
                />
                <select name="" id="" class="form-control">
                  <option>Percent Pre-payment</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group group-2">
            <div class="input-group">
              <label for="price">Price</label>
              <div class="control-group price flex">
                <select
                  name=""
                  id=""
                  v-model="newItem.currency"
                  class="form-control"
                >
                  <option value="USD">USD</option>
                  <option value="RWF">RWF</option>
                </select>
                <input
                  type="number"
                  v-model="newItem.price"
                  placeholder="Price"
                  class="form-control"
                />
              </div>
            </div>
            <div class="input-group">
              <label for="Cancellation">Cancellation</label>
              <input
                type="text"
                v-model="newItem.policy"
                placeholder="Cancellation"
                class="form-control"
              />
            </div>
          </div>
          <!-- facilities -->
          <div class="form-group">
            <div class="input-group">
              <label for="facilities">Facilities</label>
              <v-combobox
                v-model="newItem.facilities"
                label="Facilities"
                variant="solo"
                chips
                clearable
                multiple
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :model-value="selected"
                    closable
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>{{ item }}</strong
                    >&nbsp;
                    <span>(interest)</span>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </div>
          <!-- amenities -->
          <div class="form-group">
            <div class="input-group">
              <label for="amenities">Amenities</label>
              <v-combobox
                v-model="newItem.amenities"
                label="Amenities"
                variant="solo"
                chips
                clearable
                multiple
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :model-value="selected"
                    closable
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>{{ item }}</strong
                    >&nbsp;
                    <span>(interest)</span>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <textarea
              v-model="newItem.description"
              placeholder="Description"
              class="form-control"
            ></textarea>
          </div>
          <div class="form-group">
            <label for="image">Main Image</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  newItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="newItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="form-group">
            <label for="images">Other Images</label>
            <o-upload
              :multiple="true"
              @imagesUploaded="
                (data) => {
                  newItem.images = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="newItem.images"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="form-group">
            <label for="Other info">Other info</label>
            <textarea
              v-model="newItem.otherInfo"
              placeholder="Other information"
              class="form-control"
            ></textarea>
          </div>
          <div class="btns flex">
            <button @click="startAdd = false" class="my-btn cw wd cancel">
              Cancel
            </button>
            <button @click="createItem" class="btn cw glow wd">Add Room</button>
          </div>
        </div>
      </Model>
      <Model
        v-if="startEdit"
        @closeModel="startEdit = false"
        :modelTitle="'Edit Room'"
        :size="'md'"
      >
        <div class="model-body">
          <div class="form-group group-3">
            <div class="input-group">
              <label for="Name">Name</label>
              <input
                type="text"
                v-model="selectedItem.name"
                placeholder="Name"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="Beds">Beds</label>
              <input
                type="text"
                v-model="selectedItem.beds"
                placeholder="Beds"
                class="form-control"
              />
            </div>
            <div class="input-group">
              <label for="Size">Size</label>
              <input
                type="text"
                v-model="selectedItem.size"
                placeholder="Size"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group group-2">
            <div class="input-group">
              <label for="Breakfast Included">Breakfast Included</label>
              <select
                name=""
                id=""
                v-model="selectedItem.breakfast"
                class="form-control"
              >
                <option :value="1">Yes</option>
                <option :value="0">No</option>
              </select>
            </div>
            <div class="input-group">
              <label for="Payment Policy">Payment Policy</label>
              <div class="control-group price flex">
                <input
                  type="number"
                  v-model="selectedItem.payment_mode"
                  placeholder="%"
                  class="form-control"
                />
                <select name="" id="" class="form-control">
                  <option>Percent Pre-payment</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group group-2">
            <div class="input-group">
              <label for="price">Price</label>
              <div class="control-group price flex">
                <select
                  name=""
                  id=""
                  v-model="selectedItem.currency"
                  class="form-control"
                >
                  <option value="USD">USD</option>
                  <option value="RWF">RWF</option>
                </select>
                <input
                  type="number"
                  v-model="selectedItem.price"
                  placeholder="Price"
                  class="form-control"
                />
              </div>
            </div>
            <div class="input-group">
              <label for="Cancellation">Cancellation</label>
              <input
                type="text"
                v-model="selectedItem.policy"
                placeholder="Cancellation"
                class="form-control"
              />
            </div>
          </div>
          <!-- facilities -->
          <div class="form-group">
            <div class="input-group">
              <label for="facilities">Facilities</label>
              <v-combobox
                v-model="selectedItem.facilities"
                label="Facilities"
                variant="solo"
                chips
                clearable
                multiple
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :model-value="selected"
                    closable
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>{{ item }}</strong
                    >&nbsp;
                    <span>(interest)</span>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </div>
          <!-- amenities -->
          <div class="form-group">
            <div class="input-group">
              <label for="amenities">Amenities</label>
              <v-combobox
                v-model="selectedItem.amenities"
                label="Amenities"
                variant="solo"
                chips
                clearable
                multiple
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :model-value="selected"
                    closable
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>{{ item }}</strong
                    >&nbsp;
                    <span>(interest)</span>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <textarea
              v-model="selectedItem.description"
              placeholder="Description"
              class="form-control"
            ></textarea>
          </div>
          <div class="form-group">
            <label for="image">Main Image</label>
            <o-upload
              :multiple="false"
              @imagesUploaded="
                (data) => {
                  selectedItem.image = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="selectedItem.image"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="form-group">
            <label for="images">Other Images</label>
            <o-upload
              :multiple="true"
              @imagesUploaded="
                (data) => {
                  selectedItem.images = data;
                }
              "
              @imagesUploadedFailed="
                (data) => {
                  console.log('failed');
                }
              "
              :value="selectedItem.images"
              @startLoader="() => $startLoader()"
              @stopLoader="() => $stopLoader()"
            />
          </div>
          <div class="form-group">
            <label for="Other info">Other info</label>
            <textarea
              v-model="selectedItem.otherInfo"
              placeholder="Other information"
              class="form-control"
            ></textarea>
          </div>
          <div class="btns flex">
            <button @click="startEdit = false" class="my-btn cw wd cancel">
              Cancel
            </button>
            <button @click="updateItem" class="btn cw glow wd">
              Update Room
            </button>
          </div>
        </div>
      </Model>
      <confirmModel
        v-if="startDelete"
        @closeModel="startDelete = false"
        :modelTitle="'Delete Room'"
        :size="'sm'"
        :displayText="'Are you sure you want to delete this Room?'"
        @confirmModel="deleteItem"
      />
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      locations: [],
      newItem: {
        // name	image	images	price	currency	beds	facilities	amenities	description	otherInfo	payment_mode	size	policy	breakfast
        accomodation_id: null,
        name: "",
        image: "",
        images: [],
        price: 0,
        currency: "USD",
        beds: "1 Single bed",
        facilities: [],
        amenities: [],
        description: "",
        otherInfo: "",
        payment_mode: 50,
        size: "",
        policy: "Free cancellation before 3 days before arrival date.",
        breakfast: 0,
      },
      selectedItem: null,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      allItemsCount: 0,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      Rooms: [],
      categories: [],
      featuresItems: [
        "Free toiletries",
        "Shower",
        "Bathrobe",
        "Safety deposit box",
        "Toilet",
        "Sofa",
        "Hardwood or parquet floors",
        "Towels",
        "Socket near the bed",
        "Tile/marble floor",
        "Desk",
        "Seating Area",
        "TV",
        "Slippers",
        "Refrigerator",
        "Telephone",
        "Ironing facilities",
        "Satellite channels",
      ],
      accomodation: null,
    };
  },
  methods: {
    getLocations() {
      this.$store
        .dispatch("GET_LOCATIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.locations = res.data.locations.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getCategories() {
      this.$store
        .dispatch("GET_CATEGORIES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 100,
            page: 1,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.categories = res.data.categories.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getAccomdation() {
      this.$store
        .dispatch("GET_ACCOMODATION", {
          token: this.$getAdminToken(),
          data: {
            accomodation_id: this.$route.params.id,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.accomodation = res.data.accomodation;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ROOMS_BY_ACCOMODATION", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            accomodation_id: this.$route.params.id,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              if (res.data.rooms) {
                this.Rooms = res.data.rooms.data;
                this.allItemsCount = res.data.rooms.total;
                this.pagesNumber = Math.ceil(
                  this.allItemsCount / this.itemsPerpage
                );
              }
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    createItem() {
      if (this.newItem.name) {
        this.$startLoader();
        this.$store
          .dispatch("CREATE_ROOM", {
            data: this.newItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateItem() {
      if (this.selectedItem.name) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_ROOM", {
            data: this.selectedItem,
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startEdit = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch(() => {
            toast.error("Something went wrong, please try again later.");
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_ROOM", {
          data: this.selectedItem,
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
    this.getLocations();
    this.getCategories();
    this.newItem.accomodation_id = this.$route.params.id;
    this.getAccomdation();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.dashboard {
  padding-bottom: 5rem;
  .page-title {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 1.5rem;
      font-weight: 600;
      b {
        color: $primary_color;
        font-weight: 600;
      }
    }
    button {
      padding: 0.5rem 1rem;
      background-color: $primary_color;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      &:hover {
        background-color: $secondary_color;
      }
    }
  }
}

.form-group {
  column-gap: 10px;
}
.control-group {
  display: grid;
  grid-template-columns: 1fr 3fr;
  select {
    text-align: center;
    background: #eee;
    border-radius: 0px;
    &:nth-child(1) {
      border-radius: 5px 0px 0px 5px !important;
    }
    &:nth-child(2) {
      border-radius: 0px 5px 5px 0px !important;
    }
  }
  input {
    border-radius: 0px;
    &:nth-child(1) {
      border-radius: 5px 0px 0px 5px !important;
    }
    &:nth-child(2) {
      border-radius: 0px 5px 5px 0px !important;
    }
  }
}
</style>