<template>
  <div class="home">
    <NavBar />
    <div class="container">
      <!-- .blog posts -->
      <div class="blog-posts">
        <h2 class="header">Our Blog Posts</h2>
        <p class="sub-header">
          Get the latest news and updates from Cumbinn.com
        </p>
        <div class="posts">
          <router-link
            :to="{
              name: 'ViewBlog',
              params: { id: post.blog_id, name: $url('post.title') },
            }"
            class="post"
            v-for="post in allItems"
            :key="post.blog_id"
          >
            <div class="image">
              <img :src="$upload(post.image)" :alt="post.title" />
            </div>
            <div class="details">
              <h3>
                <router-link
                  :to="{
                    name: 'ViewBlog',
                    params: { id: post.blog_id, name: $url(post.title) },
                  }"
                  >{{ post.title }}</router-link
                >
              </h3>
              <p>{{ $date(post.created_at) }}</p>
              <p v-html="post.description" class="content"></p>
              <router-link to="/blog" class="btn">Read More</router-link>
            </div>
          </router-link>
        </div>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>Previous</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>Next</label>
          </button>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      allItems: [],
      currentPage: 1,
      pagesNumber: 1,
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_BLOGS", {
          data: {
            paginate: 12,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allItems = res.data.blogs.data;
              this.pagesNumber = res.data.blogs.last_page;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.home {
  overflow-x: hidden;
  width: 100%;
  .container {
    padding: 0 10rem;
    @media (max-width: 1500px) {
      padding: 0 5rem;
    }
    @media (max-width: 1200px) {
      padding: 0 4rem;
    }
    @media (max-width: 1000px) {
      padding: 0 3rem;
    }
    @media (max-width: 750px) {
      padding: 0 2rem;
    }
    @media (max-width: 500px) {
      padding: 0 1rem;
    }

    .blog-posts {
      margin: 50px 0;
      margin-top: 150px;
      .header {
        font-size: 2rem;
        margin-bottom: 20px;
        @media (max-width: 750px) {
          font-size: 1.5rem;
        }
      }
      .sub-header {
        font-size: 1.25rem;
        margin-bottom: 20px;
        @media (max-width: 750px) {
          font-size: 1.15rem;
        }
      }
      .posts {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 20px;
        @media (max-width: 750px) {
          grid-template-columns: 1fr;
        }
        .post {
          border: 1px solid #e0e0e070;
          border-radius: 5px;
          overflow: hidden;
          box-shadow: 0px 0px 10px 0px #00000013;
          color: #333;
          .image {
            img {
              width: 100%;
              height: 200px;
              object-fit: cover;
            }
          }
          .details {
            padding: 20px;
            h3 {
              font-size: 1.25rem;
              margin-bottom: 10px;
              a {
                color: #333;
              }
              @media (max-width: 750px) {
                font-size: 1.15rem;
              }
            }
            p.content {
              font-size: 0.8rem;
              margin-bottom: 10px;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              color: #747474;
              @media (max-width: 750px) {
                font-size: 0.8rem;
              }
              :deep(p > h2),
              :deep(p > h1),
              :deep(p > h3),
              :deep(p > h4),
              :deep(p > h5),
              :deep(p > h6) {
                font-size: 0.9rem;
              }
              :deep(p > img) {
                display: none;
              }
            }
            .btn {
              padding: 10px 20px;
              border: 1px solid $primary_color;
              color: #fff;
              text-decoration: none;
              border-radius: 5px;
              transition: all 0.3s;
              font-size: 1rem;
              font-weight: 500;
              margin-top: 5px;
              display: inline-block;
              &:hover {
                background-color: $primary_color;
                color: #fff;
              }

              @media (max-width: 750px) {
                padding: 4px 8px;
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
