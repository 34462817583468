<template>
  <div class="contact">
    <NavBar />
    <div class="contact-section">
      <div class="left">
        <i class="fa-solid fa-headset"></i>
        <div class="title">
          <h1>Register to Cumbinn</h1>
          <p>Fill the form to register</p>
        </div>
      </div>
      <div class="right">
        <form action="" @submit.prevent="register">
          <div class="input-group group-3">
            <div class="form-group">
              <label for="name">Names</label>
              <input
                type="text"
                id="name"
                placeholder="Your names"
                v-model="userdata.name"
              />
            </div>
            <div class="form-group">
              <label for="name">Email</label>
              <input
                type="email"
                id="name"
                placeholder="Your email"
                v-model="userdata.email"
              />
            </div>
            <div class="form-group">
              <label for="name">Phone</label>
              <input
                type="text"
                id="name"
                placeholder="Your phone"
                v-model="userdata.phone"
              />
            </div>
          </div>
          <div class="input-group group-2">
            <div class="form-group">
              <label for="name">Password</label>
              <input
                type="password"
                id="name"
                placeholder="Your password"
                v-model="userdata.password"
              />
            </div>
            <div class="form-group">
              <label for="name">Repeat Password</label>
              <input
                type="password"
                id="name"
                placeholder="Confirm password"
                v-model="userdata.repeat_password"
              />
            </div>
          </div>
          <div class="input-group">
            <button type="submit" class="btn bp cw">Register</button>
          </div>
          <div class="donts">
            <p>
              Already have account?
              <router-link to="/login">Login</router-link>
            </p>
          </div>
        </form>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  components: {},
  data() {
    return {
      userdata: {
        name: "",
        email: "",
        phone: "",
        password: "",
        repeat_password: "",
      },
    };
  },
  methods: {
    register() {
      this.$startLoader();
      this.$store
        .dispatch("REGISTER_USER", { data: this.userdata })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.$UserLogin(res.data.user, res.data.token);
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.contact {
  .contact-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem 20rem;
    background: #fff;
    border-radius: 10px;
    position: relative;
    @media (max-width: 1700px) {
      margin: 3rem 10rem;
    }
    @media (max-width: 1300px) {
      margin: 2rem 5rem;
    }
    @media (max-width: 900px) {
      margin: 1.5rem 2rem;
    }
    @media (max-width: 600px) {
      margin: 1rem 1rem;
    }
    .left {
      margin-top: 8rem;
      display: block;
      width: 70%;
      background: rgb(8, 48, 121);
      background: linear-gradient(
        142deg,
        rgba(8, 48, 121, 1) 0%,
        rgba(15, 39, 75, 1) 100%
      );
      padding: 1rem 3rem;
      border-radius: 10px;
      padding-top: 2rem;
      @media (max-width: 900px) {
        width: 100%;
        padding: 1rem 1rem;
      }
      .fa-headset {
        font-size: 25rem;
        position: absolute;
        bottom: 0rem;
        left: 0rem;
        color: #ffffff0c;
      }
      .title {
        margin-bottom: 2rem;
        h1 {
          font-size: 2rem;
          margin-bottom: 1rem;
          color: #fff;
          line-height: 1.15;
          @media (max-width: 1700px) {
            font-size: 1.5rem;
          }
          @media (max-width: 1300px) {
            font-size: 1.25rem;
          }
          @media (max-width: 900px) {
            font-size: 1.15rem;
          }
          @media (max-width: 600px) {
            font-size: 1rem;
          }
        }
        p {
          font-size: 0.9rem;
          color: #ffffffb6;
          @media (max-width: 1700px) {
            font-size: 0.8rem;
          }
          @media (max-width: 1300px) {
            font-size: 0.7rem;
          }
        }
      }
      .contact-item {
        margin-bottom: 2rem;
        h3 {
          font-size: 1rem;
          margin-bottom: 1rem;
          color: #ffffff59;
          @media (max-width: 1700px) {
            font-size: 0.8rem;
          }
          @media (max-width: 1300px) {
            font-size: 0.7rem;
          }
          @media (max-width: 900px) {
            font-size: 0.7rem;
          }
          @media (max-width: 600px) {
            font-size: 0.65rem;
          }
        }
        .text {
          display: flex;
          align-items: center;
          .icon {
            font-size: 1.5rem;
            margin-right: 1rem;
            color: #fff;
            @media (max-width: 1700px) {
              margin-right: 0.5rem;
              font-size: 0.8rem;
            }
            @media (max-width: 1300px) {
              font-size: 0.7rem;
            }
            @media (max-width: 900px) {
              font-size: 0.7rem;
            }
            @media (max-width: 600px) {
              font-size: 0.65rem;
            }
          }
          p {
            font-size: 1.2rem;
            color: #fff;
            margin-bottom: 0;
            @media (max-width: 1700px) {
              font-size: 0.8rem;
            }
            @media (max-width: 1300px) {
              font-size: 0.7rem;
            }
            @media (max-width: 900px) {
              font-size: 0.7rem;
            }
            @media (max-width: 600px) {
              font-size: 0.65rem;
            }
          }
        }
      }
      .socials {
        margin-top: 3rem;
        h3 {
          font-size: 1rem;
          margin-bottom: 1rem;
          color: #ffffff59;
        }
        .icons {
          i {
            font-size: 1.5rem;
            margin-right: 1rem;
            color: #fff;
            cursor: pointer;
            transition: all 0.3s ease;
            @media (max-width: 1700px) {
              font-size: 1rem;
            }
            @media (max-width: 1300px) {
              font-size: 0.9rem;
            }
            @media (max-width: 900px) {
              font-size: 0.85rem;
            }
            @media (max-width: 600px) {
            }
            &:hover {
              color: $primary_color;
            }
          }
        }
      }
    }
    .right {
      width: 70%;
      padding: 2rem 2rem;
      border: 1px solid #eee;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      @media (max-width: 900px) {
        width: 100%;
        padding: 1rem 1rem;
      }
      form {
        .input-group {
          margin-bottom: 2rem;
          .form-group {
            margin-bottom: 1rem;
            @media (max-width: 1700px) {
              margin-bottom: 0rem;
            }
            label {
              font-size: 1rem;
              margin-bottom: 0.5rem;
              display: block;
              @media (max-width: 1700px) {
                font-size: 0.8rem;
              }
              @media (max-width: 1300px) {
                font-size: 0.7rem;
              }
              @media (max-width: 900px) {
                font-size: 0.7rem;
              }
              @media (max-width: 600px) {
                font-size: 0.65rem;
              }
            }
            input,
            select,
            textarea {
              width: 100%;
              padding: 0.6rem 1rem;
              border: 1px solid #ddd;
              border-radius: 5px;
              outline: none;
              font-size: 0.95rem;
              @media (max-width: 1700px) {
                font-size: 0.8rem;
                padding: 0.35rem 0.8rem;
              }
              @media (max-width: 1300px) {
                font-size: 0.7rem;
              }
              @media (max-width: 900px) {
                font-size: 0.7rem;
              }
              @media (max-width: 600px) {
                font-size: 0.65rem;
              }
            }
            input[type="checkbox"] {
              margin-right: 1rem;
            }
            &.checkbox {
              display: flex;
              align-items: flex-start;
              input {
                width: auto;
              }
              label {
                font-size: 0.9rem;
                margin-top: -4px;
                @media (max-width: 1700px) {
                  font-size: 0.8rem;
                }
                @media (max-width: 1300px) {
                  font-size: 0.7rem;
                }
                @media (max-width: 900px) {
                  font-size: 0.7rem;
                }
                @media (max-width: 600px) {
                  font-size: 0.65rem;
                }
              }
            }
          }
          button {
            padding: 0.5rem 2rem;
            background-color: $primary_color;
            color: #fff;
            border: none;
            border-radius: 5px;
            font-size: 1.2rem;
            cursor: pointer;
            transition: all 0.5s ease;
            &:hover {
              background-color: $secondary_color;
            }
            @media (max-width: 1700px) {
              padding: 0.4rem 2rem;
              font-size: 0.8rem;
            }
            @media (max-width: 1300px) {
              font-size: 0.7rem;
            }
            @media (max-width: 900px) {
              font-size: 0.7rem;
            }
            @media (max-width: 600px) {
              font-size: 0.65rem;
            }
          }
        }
      }
    }
  }
  .map {
    width: 1005;
    iframe {
      width: 100%;
      border: 0;
    }
    margin-bottom: 50px;
  }
}
</style>
