<template>
  <div class="Properties">
    <NavBar />
    <div class="container" v-if="selectedItem">
      <div class="item-view">
        <div class="reserve-success" v-if="reserved">
          <div class="success">
            <div class="icon">
              <i class="fa fa-check"></i>
            </div>
            <h2>Room Reserved Successfully</h2>
            <p>
              Your room has been reserved successfully. You will receive a
              confirmation email shortly. Thank you for choosing Cumbinn.
            </p>
            <a href="#" class="my-btn cw bp" @click.prevent="reserved = false"
              >Close</a
            >
          </div>
        </div>
        <div class="item-header">
          <div class="left">
            <h2>{{ selectedItem.title }}</h2>
            <p>
              <i class="fa-solid fa-location-dot"></i>
              <span>{{ selectedItem.address }}</span>
            </p>
          </div>
          <div class="right">
            <a href="#" class="my-btn cw bp" @click.prevent="startBook = true"
              >Reserve Now</a
            >
          </div>
        </div>
        <div class="images">
          <div class="main-image image-left">
            <img :src="$upload(selectedItem.image)" alt="main-image" />
            <!-- <div class="bottom-left">
              <button class="my-btn wd cw bp">View all photos</button>
            </div> -->
          </div>
          <div class="image-right">
            <carousel
              :items-to-show="2"
              :items-to-scroll="1"
              :wrapAround="true"
              snapAlign="center"
              :breakpoints="{
                3000: {
                  itemsToShow: 15,
                  itemsToScroll: 1,
                },
                2000: {
                  itemsToShow: 12,
                  itemsToScroll: 1,
                },
                1700: {
                  itemsToShow: 10,
                  itemsToScroll: 1,
                },
                1500: {
                  itemsToShow: 8,
                  itemsToScroll: 1,
                },
                1000: {
                  itemsToShow: 6,
                  itemsToScroll: 1,
                },
                768: {
                  itemsToShow: 4,
                  itemsToScroll: 1,
                },
                500: {
                  itemsToShow: 2,
                  itemsToScroll: 1,
                },
              }"
              class="sub-images"
            >
              <slide v-for="image in selectedItem.images" :key="image">
                <img
                  :src="$upload(image)"
                  alt="sub-image"
                  @click="selectedItem.image = image"
                />
              </slide>

              <template #addons>
                <navigation />
                <pagination />
              </template>
            </carousel>
          </div>
        </div>
        <div class="item-details">
          <div class="left-details">
            <div class="Amenities">
              <h3>About {{ selectedItem.title }}</h3>
              <div class="ratings">
                <ratings :count="$int(selectedItem.rating)"></ratings>
              </div>
              <div class="amenities-lists">
                <div>
                  <div class="title">Amenities</div>
                  <ul>
                    <li
                      v-for="amenity in selectedItem.amenities"
                      :key="amenity"
                    >
                      {{ amenity }}
                    </li>
                  </ul>
                </div>
                <div>
                  <div class="title">Facilities</div>
                  <ul>
                    <li
                      v-for="facility in selectedItem.facilities"
                      :key="facility"
                    >
                      {{ facility }}
                    </li>
                  </ul>
                </div>
                <div>
                  <div class="title">Features</div>
                  <ul>
                    <span
                      v-for="feature in selectedItem.features"
                      :key="feature"
                      ><i class="fa fa-check"></i>{{ feature }}</span
                    >
                  </ul>
                </div>
              </div>
            </div>
            <div class="description">
              <h3>Description</h3>
              <div>
                <p>
                  {{ selectedItem.description }}
                </p>
              </div>
            </div>
            <div class="map">
              <h3>Map</h3>
              <div>
                <iframe
                  :src="
                    'https://www.google.com/maps?q=' +
                    selectedItem.address +
                    '&output=embed'
                  "
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
          <div class="right-details">
            <div class="features">
              <div
                class="feature-item"
                v-for="room in selectedItem.rooms"
                :key="room.room_id"
              >
                <div class="top-section">
                  <div class="left">
                    <h3>
                      {{ room.name }}
                    </h3>
                    <p>
                      {{ room.price }} {{ room.currency }}
                      <span>/Night</span>
                    </p>
                    <p
                      class="subs"
                      v-if="room.payment_mode != 0 && room.payment_mode != 100"
                    >
                      {{ room.payment_mode }} % pre-payment needed
                    </p>
                    <p class="subs" v-if="room.payment_mode == 0">
                      No pre-payment needed
                    </p>
                    <p class="subs" v-if="room.payment_mode == 100">
                      Full pre-payment needed
                    </p>
                  </div>
                  <div class="right">
                    <p>
                      <b>Each unit has:</b>
                      <span>{{ room.beds }}</span>
                    </p>
                    <p>
                      <b>Room size:</b>
                      <span>{{ room.size }}</span>
                    </p>
                    <p>
                      <b>Cancellation:</b>
                      <span>{{ room.policy }}</span>
                    </p>
                    <p>
                      <b>Breakfast:</b>
                      <span v-if="room.breakfast">Included</span>
                      <span v-else>Not Included</span>
                    </p>
                  </div>
                  <div class="right"></div>
                </div>
                <a
                  @click.prevent="
                    activeRoom = room.room_id;
                    startBook = true;
                  "
                  class="my-btn cw bp"
                  >Reserve Room</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filtered-area">
        <div class="category-list">
          <div class="tab-header">
            <h2>Related Stays</h2>
          </div>
        </div>
        <div class="items-listing" v-if="filteredItems.length">
          <AccomodationCard :filteredItems="filteredItems" />
        </div>
      </div>
      <BookRoom
        :accomodation="selectedItem"
        @close="startBook = false"
        v-if="startBook"
        :activeRoom="activeRoom"
        @reserved="
          startBook = false;
          reserved = true;
        "
      />
    </div>
    <Footer />
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { toast } from "vue3-toastify";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      allItems: [],
      filteredItems: [],
      selectedId: null,
      selectedItem: null,
      payload: {
        days: 1,
      },
      startBook: false,
      activeRoom: null,
      reserved: false,
    };
  },
  methods: {
    filterItems(category) {
      this.selectedCategory = category;
      if (category === "all") {
        this.filteredItems = this.allItems;
      } else {
        this.filteredItems = this.allItems.filter(
          (item) => item.category === category
        );
      }
      this.selectedCategory = category;
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ACCOMODATION", {
          data: {
            accomodation_id: this.$route.params.id,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.selectedItem = res.data.accomodation;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();

    this.selectedId = this.$route.params.id;
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.Properties {
  width: 100vw;
  overflow-x: hidden;
  .filtered-area {
    margin-bottom: 3rem;
  }
  .item-view {
    margin-top: 150px;
    @media screen and (max-width: 1700px) {
      margin-top: 90px;
    }
    @media screen and (max-width: 1300px) {
      margin-top: 100px;
    }
    @media screen and (max-width: 900px) {
    }
    @media screen and (max-width: 600px) {
    }
    .reserve-success {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 9999;
      .success {
        width: 50%;
        background: #fff;
        border-radius: 10px;
        padding: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 1700px) {
          width: 60%;
        }
        @media screen and (max-width: 1300px) {
          width: 70%;
        }
        @media screen and (max-width: 900px) {
          width: 80%;
        }
        @media screen and (max-width: 600px) {
          width: 90%;
        }
        .icon {
          font-size: 3rem;
          color: white;
          background: $primary_color;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        h2 {
          font-size: 2.5rem;
          color: $primary_color;
          margin-top: 1rem;
        }
        p {
          font-size: 1.25rem;
          color: #3d3d3d;
          margin-top: 1rem;
        }
        a {
          padding: 15px 40px;
          background: $primary_color;
          color: #fff;
          border: none;
          border-radius: 5px;
          text-decoration: none;
          font-size: 1.2rem;
          margin-top: 2rem;
          display: block;
          &:hover {
            background: darken($primary_color, 10%);
          }
        }
      }
    }
    .item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 1700px) {
        gap: 1rem;
      }
      @media (max-width: 1200px) {
        flex-flow: column;
        gap: 1rem;
        align-items: flex-start;
        margin-bottom: 2rem;
      }
      .left {
        h2 {
          font-size: 2rem;
          color: $primary_color;
          @media screen and (max-width: 1700px) {
            font-size: 1.5rem;
            line-height: 1.5;
          }
          @media screen and (max-width: 1300px) {
            font-size: 1.3rem;
          }
          @media screen and (max-width: 900px) {
            font-size: 1.2rem;
          }
          @media screen and (max-width: 600px) {
            font-size: 1.1rem;
          }
        }
        p {
          margin-bottom: 1rem;
          font-size: 1.35rem;
          color: #5a5a5a;
          font-weight: 400;
          @media screen and (max-width: 1700px) {
            font-size: 1rem;
          }
          @media screen and (max-width: 1300px) {
            font-size: 0.9rem;
          }
          @media screen and (max-width: 900px) {
            font-size: 0.8rem;
          }
          @media screen and (max-width: 600px) {
            font-size: 0.7rem;
          }
          i {
            color: #5a5a5a;
            margin-right: 0.5rem;
          }
        }
      }
      .right {
        @media (max-width: 500px) {
          width: 100%;
        }
        a {
          padding: 15px 40px;
          background: $primary_color;
          color: #fff;
          border: none;
          border-radius: 5px;
          text-decoration: none;
          font-size: 1.2rem;
          &:hover {
            background: darken($primary_color, 10%);
          }
          @media screen and (max-width: 1700px) {
            font-size: 1rem;
            padding: 0.5rem 1rem;
          }
          @media (max-width: 500px) {
            font-size: 0.8rem;
            padding: 0.5rem 1rem;
            width: 100%;
            display: block;
          }
        }
      }
    }
    .images {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 0.51rem;
      .main-image {
        width: 100%;
        position: relative;
        height: 600px;
        @media screen and (max-width: 1700px) {
          height: 500px;
        }
        @media (max-width: 768px) {
          height: 400px;
        }
        @media (max-width: 576px) {
          height: 50vh;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
          cursor: pointer;
        }
        .bottom-left {
          position: absolute;
          bottom: 2rem;
          left: 2rem;
          button {
            padding: 10px 40px;
          }
        }
      }
      .sub-images {
        margin-top: 1rem;
        width: 100%;
        img {
          object-fit: cover;
          border-radius: 10px;
          cursor: pointer;
          height: 100px;
          padding: 0 5px;
          width: 100%;
          @media screen and (max-width: 1700px) {
            height: 80px;
          }
          @media (max-width: 1300px) {
            height: 70px;
          }
          @media (max-width: 900px) {
            height: 60px;
          }
        }
      }
    }
    .item-details {
      display: flex;
      column-gap: 1rem;
      margin-top: 2rem;
      @media (max-width: 1200px) {
        flex-flow: column-reverse;
      }
      .left-details {
        width: 60%;
        @media (max-width: 1200px) {
          width: 100%;
        }
        .Amenities {
          h3 {
            font-size: 1.8rem;
            color: #000;
            font-weight: 700;
            @media screen and (max-width: 1700px) {
              font-size: 1.5rem;
            }
            @media screen and (max-width: 1300px) {
              font-size: 1.3rem;
            }
            @media screen and (max-width: 900px) {
              font-size: 1.2rem;
            }
            @media screen and (max-width: 600px) {
              font-size: 1.1rem;
            }
          }
          .ratings {
            margin-bottom: 1.5rem;
            @media screen and (max-width: 1700px) {
              font-size: 0.9rem;
            }
            @media screen and (max-width: 1300px) {
              font-size: 0.8rem;
            }
            @media screen and (max-width: 900px) {
              font-size: 0.7rem;
            }
            @media screen and (max-width: 600px) {
              font-size: 0.6rem;
            }
          }
          .amenities-lists {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1rem;
            @media (max-width: 1200px) {
              grid-template-columns: 1fr 1fr 1fr;
            }
            @media (max-width: 768px) {
              grid-template-columns: 1fr 1fr;
            }
            @media (max-width: 576px) {
              grid-template-columns: 1fr;
            }
            div {
              margin-bottom: 2rem;
              .title {
                font-size: 1.25rem;
                color: $primary_color;
                font-weight: 700;
                margin-bottom: 1rem;
                @media screen and (max-width: 1700px) {
                  font-size: 1rem;
                }
              }
              ul {
                li {
                  font-size: 1rem;
                  color: #3d3d3d;
                  font-weight: 400;
                  margin-bottom: 0.5rem;
                  margin-left: 15px;
                  @media screen and (max-width: 1700px) {
                    font-size: 0.7rem;
                  }
                  @media screen and (max-width: 1300px) {
                    font-size: 0.65rem;
                  }
                  @media screen and (max-width: 900px) {
                    font-size: 0.6rem;
                  }
                  @media screen and (max-width: 600px) {
                    font-size: 0.5rem;
                  }
                }
                span {
                  font-size: 1rem;
                  color: #3d3d3d;
                  font-weight: 400;
                  margin-bottom: 0.5rem;
                  margin-left: 15px;
                  display: inline-block;
                  @media screen and (max-width: 1700px) {
                    font-size: 0.7rem;
                    line-height: 1;
                    margin-bottom: 0rem;
                  }
                  i {
                    color: $primary_color;
                    margin-right: 0.3rem;
                  }
                }
              }
            }
          }
        }
        .description {
          h3 {
            font-size: 2rem;
            color: #000;
            font-weight: 700;
            margin-bottom: 1.5rem;
            @media screen and (max-width: 1700px) {
              font-size: 1.5rem;
            }
            @media screen and (max-width: 1300px) {
              font-size: 1.3rem;
            }
            @media screen and (max-width: 900px) {
              font-size: 1.2rem;
            }
            @media screen and (max-width: 600px) {
              font-size: 1.1rem;
            }
          }
          p {
            font-size: 1rem;
            color: #3d3d3d;
            margin-bottom: 2rem;
            @media screen and (max-width: 1700px) {
              font-size: 0.8rem;
            }
            @media screen and (max-width: 1300px) {
              font-size: 0.7rem;
            }
            @media screen and (max-width: 900px) {
              font-size: 0.6rem;
            }
            @media screen and (max-width: 600px) {
              font-size: 0.5rem;
            }
          }
        }
        .map {
          margin-top: 2rem;
          h3 {
            font-size: 2rem;
            margin-bottom: 1.5rem;
            color: #000;
            font-weight: 700;
            @media screen and (max-width: 1700px) {
              font-size: 1.5rem;
            }
            @media screen and (max-width: 1300px) {
              font-size: 1.3rem;
            }
            @media screen and (max-width: 900px) {
              font-size: 1.2rem;
            }
            @media screen and (max-width: 600px) {
              font-size: 1.1rem;
            }
          }
          iframe {
            width: 100%;
            height: 450px;
            border: 0;
            border-radius: 10px;
            @media screen and (max-width: 1700px) {
              height: 300px;
            }
            @media screen and (max-width: 1300px) {
              height: 300px;
            }
            @media screen and (max-width: 900px) {
              height: 300px;
            }
            @media screen and (max-width: 600px) {
              height: 250px;
            }
          }
        }
      }
      .right-details {
        width: 40%;
        @media (max-width: 1200px) {
          width: 100%;
        }
        .features {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 1rem;
          width: 100%;
          @media (max-width: 1200px) {
            grid-template-columns: 1fr 1fr 1fr;
          }
          @media (max-width: 768px) {
            grid-template-columns: 1fr 1fr;
          }
          @media (max-width: 576px) {
            grid-template-columns: 1fr;
          }
          @media (max-width: 400px) {
            grid-template-columns: 1fr;
          }
          .feature-item {
            width: 100%;
            border: 1px solid #ccc;
            border-radius: 10px;
            padding: 1rem;
            .top-section {
              .left {
                h3 {
                  font-size: 1.2rem;
                  color: #3d3d3d;
                  font-weight: 500;
                  margin-bottom: 0.75rem;
                  white-space: nowrap;
                  @media screen and (max-width: 1700px) {
                    font-size: 0.9rem;
                  }
                  @media screen and (max-width: 1300px) {
                    font-size: 0.8rem;
                  }
                  @media screen and (max-width: 900px) {
                    font-size: 0.7rem;
                  }
                  @media screen and (max-width: 600px) {
                    font-size: 0.6rem;
                  }
                }
                p {
                  font-size: 1.5rem;
                  color: $secondary_color;
                  font-weight: 700;
                  @media screen and (max-width: 1700px) {
                    font-size: 1.2rem;
                    margin-bottom: 0rem;
                  }
                  @media screen and (max-width: 1300px) {
                    font-size: 1rem;
                  }
                  span {
                    color: #333;
                    margin-right: 1rem;
                    font-size: 1.1rem;
                    font-weight: 400;
                    @media screen and (max-width: 1700px) {
                      font-size: 0.7rem;
                    }
                    @media screen and (max-width: 1300px) {
                      font-size: 0.6rem;
                    }
                  }
                  &.subs {
                    font-size: 0.8rem;
                    color: #24c52e;
                    font-weight: 600;
                    margin-bottom: 0.5rem;
                    @media screen and (max-width: 1700px) {
                      font-size: 0.7rem;
                    }
                  }
                }
              }
              .right {
                p {
                  font-size: 0.9rem;
                  color: #3d3d3d;
                  font-weight: 400;
                  margin-bottom: 0.5rem;
                  @media screen and (max-width: 1700px) {
                    font-size: 0.7rem;
                  }
                  b {
                    font-weight: 700;
                    margin-right: 0.5rem;
                  }
                  span {
                    font-weight: 400;
                  }
                }
              }
            }
            a {
              margin-top: 1rem;
              padding: 10px 40px;
              width: 100%;
              display: block;
              border-radius: 5px;
              text-decoration: none;
              text-align: center;
              @media screen and (max-width: 1700px) {
                padding: 0.45rem 1rem;
                font-size: 0.7rem;
              }
              &:hover {
                background: darken($primary_color, 10%);
              }
            }
          }
        }
        .top-section {
          label {
            font-size: 1rem;
            color: #3d3d3d;
            font-weight: 500;
          }
          h2 {
            font-size: 2rem;
            color: #000;
            font-weight: 700;
            span {
              font-size: 0.9rem;
              color: #333;
              font-weight: 400;
            }
          }
          button {
            margin-top: 1rem;
            padding: 10px 40px;
            width: 100%;
          }
        }
        .bottom-section {
          margin-top: 2rem;
          h3 {
            font-size: 1.2rem;
            color: #333;
            font-weight: 700;
            margin-bottom: 1rem;
          }
          form {
            .form-group {
              margin-bottom: 1rem;
              label {
                font-size: 1rem;
                color: #333;
                font-weight: 400;
              }
              input,
              textarea {
                width: 100%;
                padding: 10px;
                border: 1px solid #ccc;
                border-radius: 5px;
                font-size: 0.9rem;
                color: #333;
                margin-top: 0.5rem;
                outline: none;
              }
              textarea {
                height: 100px;
              }
            }
            .number-range {
              margin-top: 0.5rem;
            }
            button {
              padding: 10px 40px;
              background: $primary_color;
              width: 100%;
              border: none;
              border-radius: 5px;
              &:hover {
                background: darken($primary_color, 10%);
              }
            }
          }
        }
      }
    }
  }
}
</style>
