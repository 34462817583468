<template>
  <div class="home">
    <NavBar />
    <div class="container with-b">
      <!-- Browse by Destination  -->
      <div class="location-area">
        <div class="title">
          <h2>Desired Destinations</h2>
          <form
            action=""
            class="header-search"
            @submit.prevent="searchLocation"
          >
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Search for a destination"
              v-model="searchQuery"
              @input="searchLocation"
            />
          </form>
        </div>
        <ul>
          <li
            v-for="destination in destinations"
            :key="destination.location_id"
          >
            <router-link
              :to="{
                name: 'Stays',
                query: { location: destination.location_id },
              }"
              class="img"
            >
              <img :src="$upload(destination.image)" :alt="destination.name" />
            </router-link>
            <div class="details">
              <h3>{{ destination.name }}</h3>
              <p>{{ destination.accomodations }} Stays</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      statuses: ["For Sale", "For Rent"],
      selectedCategory: "all",
      destinations: [],
      alldestinations: [],
      categories: null,
      searchQuery: "",
    };
  },
  methods: {
    getLocations() {
      this.$startLoader();
      this.$store
        .dispatch("GET_LOCATIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 500,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.destinations = res.data.locations.data;
              this.alldestinations = res.data.locations.data;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    searchLocation() {
      if (this.searchQuery) {
        this.destinations = this.alldestinations.filter((destination) =>
          destination.name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        );
      } else {
        this.destinations = this.alldestinations;
      }
    },
  },
  mounted() {
    this.getLocations();
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.home {
  overflow-x: hidden;
  .container {
    padding: 0 10rem;
    background: #fff;
    @media (max-width: 1500px) {
      padding: 0 5rem;
    }
    @media (max-width: 1200px) {
      padding: 0 4rem;
    }
    @media (max-width: 1000px) {
      padding: 0 3rem;
    }
    @media (max-width: 750px) {
      padding: 0 2rem;
    }
    @media (max-width: 500px) {
      padding: 0 1rem;
    }
    .location-area {
      margin-top: 80px;
      margin-bottom: 50px;
      @media (max-width: 1000px) {
        margin-top: 50px;
      }
      .title {
        display: flex;
        justify-content: space-between;
        @media (max-width: 1500px) {
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
          flex-direction: column;
        }
        @media (max-width: 500px) {
        }
        h2 {
          font-size: 2rem;
          margin-bottom: 20px;
          color: #000;
          @media (max-width: 1500px) {
          }
          @media (max-width: 1200px) {
            font-size: 1.5rem;
          }
          @media (max-width: 1000px) {
            font-size: 1.25rem;
          }
          @media (max-width: 750px) {
            font-size: 1.5rem;
          }
          @media (max-width: 500px) {
            font-size: 1.25rem;
          }
        }
        .header-search {
          input {
            width: 300px;
            padding: 10px 20px;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            font-size: 1rem;
            font-weight: 500;
            color: #000;
            outline: none;
            transition: all 0.3s;
            &:focus {
              border-color: #000;
            }
            @media (max-width: 1500px) {
            }
            @media (max-width: 1200px) {
            }
            @media (max-width: 1000px) {
            }
            @media (max-width: 750px) {
              width: 100%;
              margin-bottom: 20px;
            }
            @media (max-width: 500px) {
            }
          }
        }
      }
      ul {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        flex-wrap: wrap;
        gap: 20px;
        @media (max-width: 1500px) {
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 1000px) {
        }
        @media (max-width: 750px) {
        }
        @media (max-width: 500px) {
          grid-template-columns: 1fr;
        }
        li {
          border: 1px solid #e0e0e0;
          border-radius: 5px;
          overflow: hidden;
          height: 300px;
          position: relative;
          cursor: pointer;
          width: 100%;
          @media (max-width: 1500px) {
          }
          @media (max-width: 1200px) {
          }
          @media (max-width: 1000px) {
          }
          @media (max-width: 750px) {
          }
          @media (max-width: 500px) {
            height: 200px;
          }
          .img {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .details {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.603) 0%,
              rgba(0, 0, 0, 0.699) 50%,
              rgba(0, 0, 0, 0) 100%
            );
            padding: 20px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 750px) {
              padding: 5px 10px;
            }
            h3 {
              font-size: 1.25rem;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #fff;
              @media (max-width: 1500px) {
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 1000px) {
              }
              @media (max-width: 750px) {
                font-size: 1rem;
              }
              @media (max-width: 500px) {
              }
            }
            p {
              font-size: 1.25rem;
              color: #fff;
              @media (max-width: 1500px) {
              }
              @media (max-width: 1200px) {
              }
              @media (max-width: 1000px) {
              }
              @media (max-width: 750px) {
                font-size: 0.9rem;
              }
              @media (max-width: 500px) {
              }
            }
          }
        }
      }
      .view-more {
        margin-top: 20px;
        text-align: right;
        a {
          padding: 10px 20px;
          text-decoration: none;
          border-radius: 5px;
          transition: all 0.3s;
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
    &.with-b {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }
}
</style>
