<template>
  <router-link
    :to="{
      name: 'ViewSpace',
      params: { id: item.space_id, name: $url(item.name) },
    }"
    class="listing-item"
    v-for="item in filteredItems"
    :key="item.id"
  >
    <div class="image">
      <img :src="$upload(item.image)" :alt="item.name" />
      <p class="price">
        {{ $comma(item.price) }} {{ item.currency }}
        {{ item.payment_frequency }}
      </p>
    </div>
    <router-link
      :to="{
        name: 'ViewSpace',
        params: { id: item.space_id, name: $url(item.name) },
      }"
      class="details"
    >
      <h3>{{ item.name }}</h3>
      <p>
        <i class="fa-solid fa-location-dot"></i>
        <span>{{ item.location.name }}</span>
      </p>
      <p>
        <i class="fa-solid fa-phone"></i>
        <span>{{ item.support_number }}</span>
      </p>
      <p>
        <i class="fa-solid fa-envelope"></i>
        <span>{{ item.support_email }}</span>
      </p>
    </router-link>
    <div class="like">
      <i class="fa fa-heart"></i>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    filteredItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rating: 4,
    };
  },
};
</script>

<style>
</style>