import { createStore } from 'vuex';
import locations from './modules/locations.js';
import files from './modules/files.js';
import accomodations from './modules/accomodations.js';
import admin from './modules/admin.js';
import settings from './modules/settings.js';
import categories from './modules/categories.js';
import rooms from './modules/rooms.js';
import reservations from './modules/reservations.js';
import blogs from './modules/blogs.js';
import users from './modules/users.js';
import spaces from './modules/spaces.js';
import spaceReservation from './modules/spaceReservation.js';
import { UPLOAD_URL } from './variables.js';

export default createStore({
  state: {
    startLoader: false,
    showSANav: true,
    activePage: null,
    apiUploadUrl: UPLOAD_URL,
    siteSettings: null,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    locations,
    files,
    accomodations,
    admin,
    settings,
    categories,
    rooms,
    reservations,
    blogs,
    users,
    spaces, spaceReservation
  }
})
