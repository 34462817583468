<template>
  <router-link
    :to="{
      name: 'ViewEvent',
      params: { id: item.space_id, name: $url(item.name) },
    }"
    class="listing-item"
    v-for="item in filteredItems"
    :key="item.id"
  >
    <div class="image">
      <img :src="$upload(item.image)" :alt="item.name" />
    </div>
    <div class="details">
      <h3>{{ item.name }}</h3>
      <p>
        <i class="fa-solid fa-location-dot"></i>
        <span>{{ item.location.name }}</span>
      </p>
      <p>
        <i class="fa-solid fa-layer-group"></i>
        <span>{{ item.attraction }}</span>
      </p>
      <p>
        <i class="fa-solid fa-calendar-days"></i>
        <span>{{ item.date }} {{ $ampm(item.time) }}</span>
      </p>
      <label for="price" class="price">
        <small>From </small>{{ $comma(item.price) }} {{ item.currency }}
      </label>
    </div>
    <div class="like">
      <i class="fa fa-heart"></i>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    filteredItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rating: 4,
    };
  },
};
</script>

<style lang="scss" scoped>
.listing-item {
  $primary_color: #0c3b7c;
  $secondary_color: #00bafc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem;
  border-bottom: 1px solid #f0f0f0;
  transition: all 0.3s;
  &:hover {
    background: #f0f0f0;
  }
  .image {
    width: 50%;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .details {
    width: 50%;
    h3 {
      font-size: 1.5rem;
      margin: 0.5rem 0;
    }
    p {
      display: flex;
      align-items: center;
      i {
        margin-right: 0.5rem;
      }
    }
    .price {
      font-size: 1.25rem;
      margin: 0.5rem 0;
      color: $primary_color;
    }
  }
  .like {
    width: 10%;
    text-align: center;
    i {
      font-size: 1.5rem;
      color: #1d2088;
    }
  }
}
</style>