<template>
  <div class="home">
    <NavBar />
    <div class="container">
      <div class="category-list">
        <h2><span>Ultimate</span> Stays</h2>
      </div>
      <div class="dflex accomodationsContent">
        <div v-if="isLoading">
          <a-skeleton active />
          <a-skeleton active />
          <a-skeleton active />
        </div>
        <div class="left-section" v-else>
          <div class="filtered-area">
            <div class="items-listing" v-if="filteredItems.length">
              <AccomodationCard :filteredItems="filteredItems" />
            </div>
          </div>
          <div class="pagination">
            <button
              :class="{ disabled: currentPage === 1 }"
              @click="previousPage"
            >
              <label>Previous</label>
            </button>
            <p v-if="currentPage > 3">...</p>
            <button class="numbers" v-for="number in pagesNumber" :key="number">
              <label
                :class="{ active: currentPage === number }"
                for=""
                v-if="
                  (number == 1 && currentPage <= 2) ||
                  (currentPage + 2 >= pagesNumber &&
                    number >= pagesNumber - 2) ||
                  (number >= currentPage - 2 && number <= currentPage + 2)
                "
                @click="$changePage(number)"
              >
                {{ number }}
              </label>
            </button>
            <p v-if="currentPage <= pagesNumber - 3">...</p>
            <button
              :class="{ disabled: currentPage === pagesNumber.length }"
              @click="nextPage"
            >
              <label>Next</label>
            </button>
          </div>
        </div>
        <div class="right-section">
          <div class="filter-forms">
            <div class="filter-form">
              <div class="input-group">
                <label for="">Search</label>
                <a-input
                  v-model:value="filterQuery.searchQuery"
                  placeholder="Search here"
                  @input="filterItems"
                />
              </div>
            </div>
            <div class="filter-form">
              <div class="input-group">
                <label for="">category</label>
                <div class="checkboxes">
                  <a-checkbox
                    v-for="category in categories"
                    :key="category.id"
                    @change="addCategoryId(category.category_id)"
                    :checked="
                      filterQuery.categoryIds.includes(category.category_id)
                    "
                    >{{ category.name }}</a-checkbox
                  >
                </div>
              </div>
            </div>
            <div class="filter-form">
              <div class="input-group">
                <label for="">Location</label>
                <div class="checkboxes">
                  <a-checkbox
                    v-for="location in locations"
                    :key="location"
                    @change="addLocationId(location.location_id)"
                    :checked="
                      filterQuery.locationsIds.includes(location.location_id)
                    "
                    >{{ location.name }}</a-checkbox
                  >
                </div>
              </div>
            </div>
            <div class="filter-form">
              <div class="input-group">
                <label for="">Favourable for</label>
                <div class="checkboxes">
                  <a-checkbox
                    @change="addfavor('Family Tour')"
                    :checked="filterQuery.favour.includes('Family Tour')"
                    >Family Tour</a-checkbox
                  >
                  <a-checkbox
                    @change="addfavor('Honeymoon Tour')"
                    :checked="filterQuery.favour.includes('Honeymoon Tour')"
                    >Honeymoon Tour</a-checkbox
                  >
                  <a-checkbox
                    @change="addfavor('Group Tour')"
                    :checked="filterQuery.favour.includes('Group Tour')"
                    >Group</a-checkbox
                  >
                  <!-- Adventure Tour, Solo Tour -->
                  <a-checkbox
                    @change="addfavor('Adventure Tour')"
                    :checked="filterQuery.favour.includes('Adventure Tour')"
                    >Adventure Tour</a-checkbox
                  >
                  <a-checkbox
                    @change="addfavor('Solo Tour')"
                    :checked="filterQuery.favour.includes('Solo Tour')"
                    >Solo Tour</a-checkbox
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import { toast } from "vue3-toastify";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      locations: [],
      statuses: ["For Sale", "For Rent"],
      categories: [],
      allItems: [],
      filteredItems: [],
      selectedCategory: "all",
      allItemsCount: 0,
      itemsPerpage: 9,
      pagesNumber: 1,
      currentPage: 1,
      filterQuery: {
        prices: [],
        categoryIds: [],
        locationsIds: [],
        searchQuery: "",
        favour: [],
      },
      isLoading: false,
    };
  },
  methods: {
    addCategoryId(id) {
      if (this.filterQuery.categoryIds.includes(id)) {
        this.filterQuery.categoryIds = this.filterQuery.categoryIds.filter(
          (categoryId) => categoryId !== id
        );
      } else {
        this.filterQuery.categoryIds.push(id);
      }
      this.filterItems();
    },
    addLocationId(id) {
      if (this.filterQuery.locationsIds.includes(id)) {
        this.filterQuery.locationsIds = this.filterQuery.locationsIds.filter(
          (locationId) => locationId !== id
        );
      } else {
        this.filterQuery.locationsIds.push(id);
      }
      this.filterItems();
    },
    addfavor(favour) {
      if (this.filterQuery.favour.includes(favour)) {
        this.filterQuery.favour = this.filterQuery.favour.filter(
          (favourItem) => favourItem !== favour
        );
      } else {
        this.filterQuery.favour.push(favour);
      }
      this.filterItems();
    },
    filterItems() {
      this.isLoading = true;
      this.$store
        .dispatch("GET_FILTERED_ACCOMODATIONS", {
          data: {
            paginate: 12,
            page: this.currentPage,
            ...this.filterQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              if (res.data.accomodations) {
                this.allItems = res.data.accomodations.data;
                this.allItemsCount = res.data.accomodations.total;
                this.filteredItems = res.data.accomodations.data;
                this.pagesNumber = Math.ceil(
                  res.data.accomodations.total / this.itemsPerpage
                );
              }
              this.isLoading = false;
            } else {
              toast.error(res.data.message);
              this.isLoading = false;
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.isLoading = false;
        });
    },
    changePage(page) {
      this.currentPage = page;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.pagesNumber) {
        this.currentPage++;
      }
    },
    getItems() {
      this.filterItems();
    },
    getLocations() {
      this.$store
        .dispatch("GET_LOCATIONS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 5,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.locations = res.data.locations.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
        });
    },
    getCategories() {
      this.$store
        .dispatch("GET_CATEGORIES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 5,
            page: this.currentPage,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.categories = res.data.categories.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
        });
    },
  },
  mounted() {
    // this.getItems();
    this.getCategories();
    this.getLocations();
    if (this.$route.query.category) {
      this.filterQuery.categoryIds.push(this.$route.query.category);
    }
    if (this.$route.query.location) {
      this.filterQuery.locationsIds.push(this.$route.query.location);
    }
    if (this.$route.query.search) {
      this.filterQuery.searchQuery = this.$route.query.search;
    }
    if (this.$route.query.favour) {
      this.filterQuery.favour.push(this.$route.query.favour);
    }
    this.filterItems();
  },
  computed: {},
  watch: {
    "$route.query": {
      handler: function () {
        if (this.$route.query.category) {
          this.filterQuery.categoryIds = [];
          this.filterQuery.categoryIds.push(this.$route.query.category);
        }
        if (this.$route.query.location) {
          this.filterQuery.locationsIds = [];
          this.filterQuery.locationsIds.push(this.$route.query.location);
        }
        this.filterItems();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.home {
  overflow-x: hidden;
  width: 100%;
  .container {
    padding: 0 4rem;
    @media (max-width: 1500px) {
      padding: 0 3rem;
    }
    @media (max-width: 1200px) {
      padding: 0 2rem;
    }
    @media (max-width: 1000px) {
      padding: 0 1rem;
    }
    @media (max-width: 750px) {
      padding: 0 1rem;
    }
    @media (max-width: 500px) {
      padding: 0 1rem;
    }
    .search-area {
      margin-top: 50px;
      border: 1px solid #e0e0e0;
      padding: 20px;
      border-radius: 5px;
      margin-top: 120px;
      @media (max-width: 1700px) {
        margin-top: 0px;
      }
      .search {
        h2 {
          font-size: 2rem;
          margin-bottom: 20px;
        }
        form {
          display: flex;
          flex-direction: column;
          .top {
            display: flex;
            column-gap: 20px;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            fieldset {
              width: 23%;
              border-radius: 5px;
              border: 1px solid #e0e0e0;
              legend {
                padding: 0 1rem;
                font-size: 0.8rem;
              }
            }
            input,
            select {
              width: 100%;
              border: none;
              padding: 15px 20px;
              font-size: 1rem;
              font-weight: 400;
              outline: none;
            }
          }
          .bottom {
            width: 100%;
            .title {
              margin-top: 2rem;
              margin-bottom: 2rem;
            }
            .price-range {
              width: 100%;
              display: flex;
              justify-content: center;
              padding: 0 0rem;
              .custom-slider .v-slider-thumb__surface {
                width: 25px;
                height: 25px;
                &::before {
                  content: "";
                  background-color: #fff;
                  border-radius: 50%;
                  width: 15px;
                  height: 15px;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  position: absolute;
                }
              }
              .custom-slider .v-slider-thumb__label {
                margin-left: 3px;
                background: $primary_color;
                border: none;
              }
              .custom-slider .v-slider-thumb__label::before {
                color: $primary_color;
              }
            }
          }
          button {
            width: 100%;
            padding: 15px 0;
            background-color: $primary_color;
            color: #fff;
            border: none;
            border-radius: 5px;
            transition: all 0.3s;
            font-size: 1.2rem;
            font-weight: 500;
            display: flex;
            column-gap: 5px;
            align-items: center;
            justify-content: center;
            &:hover {
              background-color: darken($color: $primary_color, $amount: 10%);
            }
          }
        }
      }
    }
    .accomodationsContent {
      display: grid;
      grid-template-columns: calc(100% - 300px) 300px;
      gap: 1rem;
      @media (max-width: 1000px) {
        grid-template-columns: 1fr;
      }
      .left-section {
        display: flex;
        flex-direction: column;
        .items-listing {
          margin-top: 0;
        }
      }
      .right-section {
        margin-bottom: 2rem;
        .filter-forms {
          background: #fff;
          padding: 20px;
          border-radius: 5px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          .filter-form {
            border-bottom: 1px solid #e0e0e0;
            padding: 1rem 0;
            &:nth-child(1) {
              padding: 0rem;
              padding-bottom: 1rem;
            }
            &:last-child() {
              border-bottom: none;
            }
            .input-group {
              display: flex;
              flex-direction: column;
              label {
                font-size: 0.9rem;
                font-weight: 500;
                margin-left: 0;
              }
              select,
              input {
                padding: 7px 15px;
                border: 1px solid #e0e0e0;
                border-radius: 5px;
                outline: none;
                font-size: 0.9rem;
              }
              .checkboxes {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
              }
            }
          }
        }
      }
    }
    .category-list {
      margin-top: 150px;
      @media (max-width: 1700px) {
        margin-top: 100px;
      }
      h2 {
        font-size: 2rem;
        margin-bottom: 20px;
        span {
          font-size: 2rem;
          font-weight: 900;
          color: $primary_color;
        }
      }
      ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        column-gap: 3rem;
        li {
          display: flex;
          flex-direction: column;
          justify-content: center;
          opacity: 0.4;
          align-items: center;
          cursor: pointer;
          border-bottom: 2px solid transparent;
          transition: all 0.3s;
          .top {
            img {
              width: 30px;
              height: 30px;
            }
          }
          .bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            span {
              font-size: 0.8rem;
              font-weight: 500;
            }
          }
          &.active,
          &:hover {
            opacity: 1;
            border-bottom: 2px solid $primary_color;
          }
        }
      }
    }
    .filtered-area {
      margin-top: 0px;
    }
    .location-area {
      margin-top: 50px;
      margin-bottom: 50px;
      .title {
        h2 {
          font-size: 2rem;
          margin-bottom: 20px;
        }
      }
      ul {
        list-style: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 20px;
        li {
          border: 1px solid #e0e0e0;
          border-radius: 5px;
          overflow: hidden;
          height: 300px;
          position: relative;
          cursor: pointer;
          .img {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .details {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.603) 0%,
              rgba(0, 0, 0, 0.699) 50%,
              rgba(0, 0, 0, 0) 100%
            );
            padding: 20px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3 {
              font-size: 1.25rem;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #fff;
            }
            p {
              font-size: 1.25rem;
              color: #fff;
            }
          }
        }
        :nth-child(1) {
          width: 100%;
        }
        :nth-child(2),
        :nth-child(3),
        :nth-child(4) {
          width: 32.5%;
        }
        :nth-child(5) {
          width: 100%;
        }
      }
      .view-more {
        margin-top: 20px;
        text-align: right;
        a {
          padding: 10px 20px;
          text-decoration: none;
          border-radius: 5px;
          transition: all 0.3s;
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
  }
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
  button {
    label {
      padding: 5px 15px;
      border: 1px solid #eeeeee;
      border-radius: 5px;
      margin: 0 10px;
      transition: all 0.3s;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        background-color: $primary_color;
        color: #fff;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
      &.active {
        background-color: $primary_color;
        color: #fff;
      }
    }
  }
}
</style>