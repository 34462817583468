import { BACKEND } from '../variables.js';
import axios from 'axios'
const GET_CATEGORIES = BACKEND + '/category/getCategories';
const CREATE_CATEGORY = BACKEND + '/category/createCategory';
const UPDATE_CATEGORY = BACKEND + '/category/updateCategory';
const DELETE_CATEGORY = BACKEND + '/category/deleteCategory';

export default {
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        ['GET_CATEGORIES'](state, payload) {
            return axios({
                method: 'POST',
                url: GET_CATEGORIES + `?page=${payload.data.page}`,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['CREATE_CATEGORY'](state, payload) {
            return axios({
                method: 'POST',
                url: CREATE_CATEGORY,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['UPDATE_CATEGORY'](state, payload) {
            return axios({
                method: 'POST',
                url: UPDATE_CATEGORY,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        },
        ['DELETE_CATEGORY'](state, payload) {
            return axios({
                method: 'POST',
                url: DELETE_CATEGORY,
                data: payload.data,
                headers: {
                    Authorization: payload.token
                }
            })
        }

    },
};
